import React from 'react'
import Navbar from '../About/Navbar'

function Pricing() {
    return (
        <div>
        <Navbar />
             {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-3">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 v-center">
                  <div className="bread-inner">   
                    <div className="bread-title">
                      <h2>Pricing Plan</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Breadcrumb Area*/}
        {/*Start Pricing*/}
        <section className="block-a1 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading ptag">
                  <span>Pricing</span>
                  <h2>Pricing Table</h2>
                  <p className="mb0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="pricing-table mt60">
                  <div className="inner-table">
                    <img src="assets/images/icons/plan-1.svg" alt="Personal" />
                    <span className="title">Personal</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                    <div className="details">
                      <ul>
                        <li>Social Media Marketing</li>
                        <li>2.100 Keywords</li>
                        <li>One Way Link Building</li>
                        <li>5 Free Optimization</li>
                        <li>3 Press Releases</li>
                      </ul>
                    </div>
                  </div>
                  <a href="#" className="btn-main bg-btn lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-table best-plan mt60 bg-gradient4">
                  <div className="inner-table">
                    <img src="assets/images/icons/plan-2.svg" alt="Advance" />
                    <span className="title">Advance</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                    <div className="details">
                      <ul>
                        <li>Social Media Marketing</li>
                        <li>2.100 Keywords</li>
                        <li>One Way Link Building</li>
                        <li>5 Free Optimization</li>
                        <li>3 Press Releases</li>
                      </ul>
                    </div>
                  </div>
                  <a href="#" className="btn-main bg-btn3 lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-table mt60">
                  <div className="inner-table">
                    <img src="assets/images/icons/plan-3.svg" alt="Ultimate" />
                    <span className="title">Ultimate</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                    <div className="details">
                      <ul>
                        <li>Social Media Marketing</li>
                        <li>2.100 Keywords</li>
                        <li>One Way Link Building</li>
                        <li>5 Free Optimization</li>
                        <li>3 Press Releases</li>
                      </ul>
                    </div>
                  </div>
                  <a href="#" className="btn-main bg-btn lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Pricing*/}
        {/*Start Pricing*/}
        <section className="block-a1 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading ptag">
                  <span>Pricing</span>
                  <h2>Pricing Table - Simple</h2>
                  <p className="mb0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="pricing-table mt60">
                  <div className="inner-table mb40">
                    <img src="assets/images/icons/plan-1.svg" alt="Personal" />
                    <span className="title">Personal</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                  </div>
                  <a href="#" className="btn-main bg-btn lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-table best-plan mt60 bg-gradient4">
                  <div className="inner-table mb40">
                    <img src="assets/images/icons/plan-2.svg" alt="Advance" />
                    <span className="title">Advance</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                  </div>
                  <a href="#" className="btn-main bg-btn3 lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-table mt60">
                  <div className="inner-table mb40">
                    <img src="assets/images/icons/plan-3.svg" alt="Ultimate" />
                    <span className="title">Ultimate</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                  </div>
                  <a href="#" className="btn-main bg-btn lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Pricing*/}
        {/*Start Pricing*/}
        <section className="block-a1 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading ptag">
                  <span>Pricing</span>
                  <h2>Pricing Table - 4 Columns</h2>
                  <p className="mb0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6">
                <div className="pricing-table mt60">
                  <div className="inner-table">
                    <img src="assets/images/icons/plan-1.svg" alt="Personal" />
                    <span className="title">Personal</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                    <div className="details">
                      <ul>
                        <li>Social Media Marketing</li>
                        <li>2.100 Keywords</li>
                        <li>One Way Link Building</li>
                        <li>5 Free Optimization</li>
                        <li>3 Press Releases</li>
                      </ul>
                    </div>
                  </div>
                  <a href="#" className="btn-main bg-btn lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="pricing-table best-plan mt60 bg-gradient4">
                  <div className="inner-table">
                    <img src="assets/images/icons/plan-2.svg" alt="Advance" />
                    <span className="title">Advance</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                    <div className="details">
                      <ul>
                        <li>Social Media Marketing</li>
                        <li>2.100 Keywords</li>
                        <li>One Way Link Building</li>
                        <li>5 Free Optimization</li>
                        <li>3 Press Releases</li>
                      </ul>
                    </div>
                  </div>
                  <a href="#" className="btn-main bg-btn3 lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="pricing-table mt60">
                  <div className="inner-table">
                    <img src="assets/images/icons/plan-3.svg" alt="Ultimate" />
                    <span className="title">Ultimate</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                    <div className="details">
                      <ul>
                        <li>Social Media Marketing</li>
                        <li>2.100 Keywords</li>
                        <li>One Way Link Building</li>
                        <li>5 Free Optimization</li>
                        <li>3 Press Releases</li>
                      </ul>
                    </div>
                  </div>
                  <a href="#" className="btn-main bg-btn lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="pricing-table mt60">
                  <div className="inner-table">
                    <img src="assets/images/icons/plan-3.svg" alt="Ultimate" />
                    <span className="title">Ultimate</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                    <div className="details">
                      <ul>
                        <li>Social Media Marketing</li>
                        <li>2.100 Keywords</li>
                        <li>One Way Link Building</li>
                        <li>5 Free Optimization</li>
                        <li>3 Press Releases</li>
                      </ul>
                    </div>
                  </div>
                  <a href="#" className="btn-main bg-btn lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Pricing*/}
        </div>
    )
}

export default Pricing
