import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function DataTechnology() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  Data Technology
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/Data Technology/DT.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>Data Technology</h2>
                <div className="servie-key-points">
                  <h4>Tap into raw data that delivers actionable insights.</h4>
                </div>
                <p>
                  New opportunities are now possible thanks to data, analytics,
                  and AI. However, data that is fragmented or of poor quality,
                  for example, cannot be mobilized. To make sure that data is
                  transparent, reliable, and easy to access, you need to
                  reexamine your current procedures. Then, data can be utilized
                  to get the most out of your technological and AI investments.
                  In order to fully utilize your data, unleashing its true
                  potential we will create an information strategy that will
                  enable individuals and teams to self-serve their own insights
                  through an intuitive user interface, giving them an edge over
                  the raw insights at their fingertips.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "30px !important" }}>
            What we deliver
          </h2>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Data Technology/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>Business Process Operations-Data Driven Leads</h3>
                <p>
                  In order to build a foundation for high-impact online and
                  offline marketing strategies, firms have to focus on the right
                  technology that yields accurate data and a way to make use of
                  that data efficiently. For business executives, the advent of
                  digital technology has created new opportunities. Through
                  clever operations, business process services can redefine
                  business performance in a world of constant change. However,
                  if these operations are not carried out properly using client
                  insights, they will undoubtedly fail. By offering the best
                  strategy to generate more leads based on real-time data-driven
                  insights, we help businesses achieve their objectives. In
                  addition to helping you increase sales, these data-driven
                  strategies also enable your business to flourish by cutting
                  costs using critical insights. In order to build a foundation
                  for high-impact online and offline marketing strategies, firms
                  have to focus on the right technology that yields accurate
                  data
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>Data Fabric: A Smart Way to Access Data with Ease.</h3>
                <p>
                  The phrase "data fabric" describes a collection of
                  architectural strategies used to streamline data access within
                  an organization and enable self-service data consumption. This
                  architecture integrates end-to-end data management
                  functionality despite being independent of data environments,
                  processes, utility, and geography. Utilizing data via a data
                  fabric, which automates data discovery, governance, and
                  consumption, can maximize an enterprise's value chain. With a
                  data fabric, businesses can make their data more valuable by
                  delivering the appropriate data at the appropriate time,
                  wherever it may be. A data fabric consolidates disparate
                  systems and structures, allowing employees to obtain data in a
                  common format and at the appropriate time, allowing them to
                  make use of it in the best manner possible.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Data Technology/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Data Technology/3.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>Modernize Data</h3>
                <p>
                  Every organization has the capacity to gather and store
                  enormous amounts of data, ranging from a few hundred megabytes
                  to several petabytes or even exabytes, or roughly 2.5
                  quintillion bytes per day, but the majority of this data is
                  siloed, unstructured, and useless. Data's value is influenced
                  by how easily it can be accessed and used. Data's size,
                  agility, and ability to spark innovation in the cloud, which
                  enables businesses to soar, will only continue to increase.
                  The first step is to migrate. The data foundation of
                  businesses should be upgraded so that employees can work with
                  data and cloud innovation at their fingertips. The transition
                  from on-premises to cloud-based data storage is essential for
                  effective data use by reducing costs, increasing
                  accessibility, and improving security.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Process*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default DataTechnology;
