import React from "react";
import SwiperCarousel from "./SwiperCarousel";
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import { Link , withRouter } from 'react-router-dom'; 
import ReactGa from 'react-ga';
import { useMediaQuery } from "@mui/material";

function Hero() {

  const is425pxBelow = useMediaQuery("(max-width:425px)");

  const ClickHandler = ()=> {
    ReactGa.event({
      category: 'User',
      action: 'Sent message'
    });
  
   

  
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: false,
    prevArrow: false,
  };
  return (
    <>
      {/*Start Hero*/}
      {/* <section className="hero-card-web mobile-app-">
          <div className="hero-main-rp container-fluid">
            <div className="row">
              <div className="col-lg-5 v-center">
                <div className="hero-heading-sec2 niwax" data-rellax-speed={3}>
                  <h1 className="wow fadeIn bg-light" data-wow-delay="0.2s">Mercy IT Corp provides end-to-end IT services and solutions</h1>
                  <div className="content-sec  d-flex mt30 v-center text-w wow fadeIn" data-wow-delay="0.6s">
                  </div>
                  
                  <div className="awrd-sec text-w mt40 wow fadeIn" data-wow-delay="0.8s">
                    <h4>Clients Testimonials</h4>
                    <div className="mt10 d-flex" >

                    <SwiperCarousel />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 v-center">
                <div className="app-hero-bnr w-75 mx-auto wow fadeIn" data-wow-delay="0.4s"> <img src="assets/images/hero/digital-four.gif" alt="Mercy mobile app development company" className="img-fluid" 
                style={{width:"100%", height:"auto"}}  /></div>
              </div>
            </div>
          </div>
        </section> */}
             <Container>
          <div className={"banner"}>
              <h6>Mercy IT Corp provides end-to-end IT services and solutions</h6>
              <button>Request A Quote</button>
            
          </div>
            </Container>
      <Slider {...settings}>
        <div>
          {is425pxBelow ? (
            <img src="assets/images/hero/phone_banner.jpg" width={"100%"} />
          ):( 
          <img src="assets/images/hero/banner2.jpg" width={"100%"} />
            )
          }
         
       
        </div>
        <div>
          {is425pxBelow ? (
            <img src="assets/images/hero/phone_banner.jpg" width={"100%"} />
          ):( 
          <img src="assets/images/hero/banner2.jpg" width={"100%"} />
            )
          }
         
       
        </div>
        
      </Slider>
      {/*End Hero*/}
    </>
  );
}

export default Hero;
