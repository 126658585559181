import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function TechConsulting() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  Product Engineering - Step into Next-Gen
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/Product Engineering/PE.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>Product Engineering - Step into Next-Gen</h2>

                <div className="servie-key-points">
                  <h4>Boost Product development professionally!</h4>
                  <p>
                    For businesses to realign their production to be more local
                    and enable a quicker launch of new products, they need
                    strong networks, a digitally connected workforce, and smart
                    connected items. Major technology advancements like
                    robotics, the Internet of Things, software defined
                    networking, and containerization are required to make this
                    happen. Mercy IT Corp's Engineering Services are well
                    situated to satisfy clients' expectations in real-time and
                    offer extremely competitive technical goods since they place
                    a strong emphasis on continual cost savings.
                  </p>

                  <h4>The Switch you need : Project-Based to Product-Based</h4>
                  <p>
                    Organizations grow and keep their advantage when design and
                    engineering teams speak the same language about business
                    outcomes. Delivery choices are no longer made in a vacuum,
                    creating improved experiences and opening up new
                    possibilities. Software product engineering involves more
                    than just writing code. It emphasizes a broader perspective
                    on amazing user outcomes and experiences and goes beyond
                    features and backlogs. In order to produce software that
                    catches customers' interest, Mercy IT Corp combines the
                    greatest digital engineering, product development, and
                    creative abilities. Consumers will not be satisfied with
                    merely a functional product; they need experiences that
                    allow them to interact and relate with the product, giving
                    them a sense of satisfaction.
                  </p>
                </div>

                {/* <div className="servie-key-points">
                  <h4>Let's talk about tech consulting and why it benefits companies so much. We'll consider:</h4>
                  <ul className="key-points mt20">   
                    <li>Reduce costs</li>
                    <li>Create new revenue streams</li>
                    <li>Streamline their operations</li>
                    <li>Improve customer service</li>
                     
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "30px !important" }}>
            Our Services Includes
          </h2>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Product Engineering/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>Advanced Lab Operations</h3>
                <p>
                  Our Advanced Lab Services, which are well-positioned to help
                  our clients, may offer assistance with the whole lifecycle of
                  an IT infrastructure, including strategy, planning,
                  architecture, design, implementation, and optimization. We use
                  the cutting-edge technology at our disposal to help businesses
                  create the platform or product they desire, providing you with
                  a top-notch service that is considered to be next-gen. Our
                  Advanced Solutions specialize in upgrading, improving, and
                  customizing IT infrastructure for small- to medium sized
                  enterprises.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>Product Engineering & Design</h3>
                <p>
                  Mercy Product Design and Engineering Services is a team of
                  highly skilled experts with experience in product development
                  engineering that provides a wide variety of comprehensive
                  design services.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Product Engineering/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Product Engineering/3.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>Assembly & Testing</h3>
                <p>
                  For semiconductor fabless providers, integrated device makers,
                  and original equipment manufacturers, assembly and test
                  services are designed to offer distinction in high-complexity
                  package solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Process*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default TechConsulting;
