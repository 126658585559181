import React from 'react'
import {useState} from 'react';
import {Modal, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom';



// function MyVerticallyCenteredModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Modal heading
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <h4>Centered Modal</h4>
//         <p>
//           Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
//           dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
//           consectetur ac, vestibulum at eros.
//         </p>
//       </Modal.Body>
//       <Modal.Footer>
//       </Modal.Footer>
//     </Modal>
//   );
// }



function Footer() {

  const [modalShow, setModalShow] = useState(false);

    return (
        <div>
            {/*Start Footer*/}
        <footer>
          <div className="footer-row2">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-3 col-sm-6  ftr-brand-pp">
                  <a className="navbar-brand mt30 mb25" href="#"> <img src="assets/images/logo.png" alt="Logo" width={100} /></a>
                  <p>Aspiring into the world of technology requires immense efforts to establish yourself.</p>
                  <a href="assets/files/mercy-itcorp-brochure.pdf" download="Mercy IT Corp Brochure" target="_blank" className="btn-main bg-btn3 lnk mt20">Company Brochure <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></a>
                </div> 
            
                <div className="col-lg-3 col-sm-6">
                  <h5>Contact Us</h5>
                  <ul className="footer-address-list ftr-details">
                    <li>
                      <span><i className="fas fa-envelope" /></span>
                      <p>Email <span> <a href="mailto:mercyitcorp@gmail.com">info@mercyitcorp.com</a></span></p>
                    </li>
                    {/* <li>
                      <span><i className="fas fa-phone-alt" /></span>
                      <p>Phone <span> <a href="tel:+919176454455">+91 9176 45 4455</a></span></p>
                      
                    </li> */}
                    <li>
                    <span><i className="fas fa-phone-alt" /></span>
                      <p>Telephone <span> <a href="tel:+919176454455">044 4555 0245</a></span></p>
                      
                    </li>
                    
                    <li>
                      <span><i className="fas fa-map-marker-alt" /></span>
                      <p>Address <span>#14, Jambulingam Street (Tank Bund Road view building) Nungambakkam, Chennai 600034.</span></p>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-sm-6">
                  <h5>Quick Links</h5>
                  <ul className="footer-address-list link-hover">
                    <li><a href="javascript:void(0)">Privacy Policy</a></li>
                    <li><a href="javascript:void(0)">Terms and Conditions</a></li>
                    <li><a href="javascript:void(0)">License &amp; Copyright</a></li>
                  </ul>
                </div>
                <div className="col-lg-4 col-sm-6 footer-blog-">
                  <h5>Latest Blogs</h5>
                  <div className="single-blog-">
                    <div className="post-thumb"><a href="#"><img src="assets/images/blog-images/digital.jpg" alt="blog" /></a></div>
                    <div className="content">
                      <p className="post-meta"><span className="post-date"><i className="far fa-clock" />November 11, 2021</span></p>
                      <h4 className="title"><Link to="/blog">STEP UP YOUR BUSINESS BY MAKING IT DIGITALIZED</Link></h4>
                    </div>
                  </div>
                  {/* <div className="single-blog-">
                    <div className="post-thumb"><a href="#"><img src="assets/images/blog/devto.svg" alt="blog" /></a></div>
                    <div className="content">
                      <p className="post-meta"><span className="post-date"><i className="far fa-clock" />August, 2021</span></p>
                      <h4 className="title"><a href="https://dev.to/mayukhdeep/the-future-of-chatbots-ai-102o" target="_blank">The Future Of Chatbots (AI)</a></h4>
                    </div>
                  </div> */}
                  {/* <div className="single-blog-" onClick={() => setModalShow(true)} >
                    <div className="post-thumb"><a href="#"><img src="assets/images/blog/hashnode.jpg" alt="blog" /></a></div>
                    <div className="content">
                      <p className="post-meta"><span className="post-date"><i className="far fa-clock" />November 2021</span></p>
                      <h4 className="title">Step up your business by making it digitalized</h4>
                    
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="footer-row3">
            <div className="copyright">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="footer-social-media-icons text-center">
                      <a href="https://www.facebook.com/mercyitcorp1/" target="_blank"><i className="fab fa-facebook" /></a>
                      <a href="https://twitter.com/mercyitcorp?lang=en" target="_blank"><i className="fab fa-twitter" /></a>
                      <a href="javascript:void(0)"><i className="fab fa-instagram" /></a>
                      <a href="https://www.linkedin.com/in/mercyitcorp/" target="_blank"><i className="fab fa-linkedin" /></a>
                      <a href="https://www.youtube.com/channel/UCOhgSG-D0l6IkZhu7l1nJlg/featured" target="_blank"><i className="fab fa-youtube" /></a>
                    </div>
                    <div className="footer- text-center">
                      <p>Copyright © 2018-21 Mercy IT Corp. All rights reserved.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
        </div>
    )
}

export default Footer
