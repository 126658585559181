import React from 'react'
import Navbar from '../About/Navbar'

function ServiceMarketing() {
    return (
        <div>
            <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/9.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp" data-wow-delay=".2s">Professional Digital Marketing Services That Drive Results</h1>
                  <p className="mt20 wow fadeInUp" data-wow-delay=".4s">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since. </p>
                  <a href="#" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="statistics-wrap bg-gradient5">
          <div className="container">
            <div className="row small t-ctr mt0">
              <div className="col-lg-3 col-sm-6">
                <div className="statistics">
                  <div className="statistics-img">
                    <img src="assets/images/icons/deal.svg" alt="happy" className="img-fluid" />
                  </div>
                  <div className="statnumb">
                    <span className="counter">450</span>
                    <p>Happy Clients</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="statistics">
                  <div className="statistics-img">
                    <img src="assets/images/icons/computers.svg" alt="project" className="img-fluid" />
                  </div>
                  <div className="statnumb counter-number">
                    <span className="counter">48</span><span>k</span>
                    <p>Projects Done</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="statistics">
                  <div className="statistics-img">
                    <img src="assets/images/icons/worker.svg" alt="work" className="img-fluid" />
                  </div>
                  <div className="statnumb">
                    <span className="counter">95</span><span>k</span>
                    <p>Hours Worked</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="statistics mb0">
                  <div className="statistics-img">
                    <img src="assets/images/icons/customer-service.svg" alt="support" className="img-fluid" />
                  </div>
                  <div className="statnumb">
                    <span className="counter">24</span><span>/</span><span className="counter">7</span>
                    <p>Support Available</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Hero*/}
        {/*Start About*/}
        <section className="service pad-tb bg-gradient5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="image-block wow fadeIn">
                  <img src="assets/images/service/digitalmarketing.png" alt="image" className="img-fluid no-shadow" />
                </div>
              </div>
              <div className="col-lg-8 block-1">
                <div className="common-heading text-l pl25">
                  <span>Overview</span>
                  <h2>Digital Marketing Services for Growing Your Company</h2>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum is simply dummy text of the printing and typesetting industry. is simply dummy text of the printing and typesetting industry. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        {/*Start Key points*/}
        <section className="service pad-tb light-dark">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="text-l service-desc- pr25">
                  <h3 className="mb20">Online Marketing Services Proven to Increase Leads, Sales, &amp; Revenue</h3>  
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  <ul className="service-point-2 mt20">
                    <li># 800+ Mobile Delivered</li>
                    <li># 200+ Team Strength</li>
                    <li># User-Friendly Interface</li>
                    <li># 400 Happy Clients</li>
                    <li># 95% Repeat business</li>
                    <li># Quality Service UX</li>
                  </ul>
                  <a href="#" className="btn-main bg-btn2 lnk mt30">Request A Quote  <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></a>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="servie-key-points">
                  <h4>Advantages of Digital Marketing</h4>
                  <ul className="key-points mt20">
                    <li>Strategize with The Valuable Data and Analytics</li>
                    <li>Content Performance and Lead Generation</li>
                    <li>Reduction in cost and raises standards</li>
                    <li>Improved Conversion Rates</li>
                    <li>More Cost Effective Than Traditional Marketing</li>
                    <li>Higher Revenues</li>
                    <li>Higher ROI from Your Campaigns</li>
                    <li>Earn People’s Trust and Build Brand Reputation</li>
                    <li>Know All About Your Competitors</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Key points*/}
        {/*Start Service*/}
        <section className="service-block bg-gradient6 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading ptag">
                  <span>Service</span>
                  <h2>Our Services</h2>
                  <p className="mb30">We think big and have hands in all leading technology platforms to provide you wide array of services.</p>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/seo.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Search Engine Optimization</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/smo.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Social Media Marketing</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/ppc.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Pay per Click</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/emails.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Email Marketing</h4>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  </div></div>
              </div>
            </div>
            <div className="-cta-btn mt70">
              <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.1s">
                <p>Hire a <span>Dedicated Developer</span></p>
                <a href="#" className="btn-main bg-btn2 lnk">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></a>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        {/*Start Process*/}
        {/* <section className="service-block pad-tb light-dark">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading ptag">
                  <span>Process</span>
                  <h2>Our App Development Process</h2>
                  <p>Our design process follows a proven approach. We begin with a deep understanding of your needs and create a planning template.</p>
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-1.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>1</span>
                  <h3>Requirement Gathering</h3>
                  <p>Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris.</p>
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>2</span>
                  <h3>Prototype</h3>
                  <p>Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris.</p>
                </div>
              </div>
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-2.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-3.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>3</span>
                  <h3>Deployment</h3>
                  <p>Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris.</p>
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>4</span>
                  <h3>Support &amp; Maintenance</h3>
                  <p>Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit. Aliquam tristique libero at dui sodales, et placerat orci lobortis. Maecenas ipsum neque, elementum id dignissim et, imperdiet vitae mauris.</p>
                </div>
              </div>
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-4.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/*End Process*/}
        {/*Start Why Choose*/}
        {/* <section className="service-block pad-tb bg-gradient7">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading ptag">
                  <span>We Deliver Our Best</span>
                  <h2>Why Choose Niwax</h2>
                  <p className="mb30">Donec metus lorem, vulputate at sapien sit amet, auctor iaculis lorem. In vel hendrerit nisi. Vestibulum eget risus velit.</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6 mt30  wow fadeIn" data-wow-delay=".2s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon"><img src="assets/images/icons/teama.svg" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content">
                    <h4>Reliable Service. In House Team</h4>
                    <p>In vel hendrerit nisi. Vestibulum eget risus velit.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".5s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon"><img src="assets/images/icons/link.svg" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content">
                    <h4>Trusted by People Like You</h4>
                    <p>In vel hendrerit nisi. Vestibulum eget risus velit.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay=".8s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon"><img src="assets/images/icons/tech.svg" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content">
                    <h4>Complete Technical Competency</h4>
                    <p>In vel hendrerit nisi. Vestibulum eget risus velit.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="1.1s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon"><img src="assets/images/icons/hi.svg" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content">
                    <h4>Friendly &amp; Cordial in Nature</h4>
                    <p>In vel hendrerit nisi. Vestibulum eget risus velit.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="1.4s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon"><img src="assets/images/icons/badge.svg" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content">
                    <h4>Excellent Quality Delivered on Time</h4>
                    <p>In vel hendrerit nisi. Vestibulum eget risus velit.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeIn" data-wow-delay="1.7s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon"><img src="assets/images/icons/tin.svg" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content">
                    <h4>Effective &amp; Continuous Communication</h4>
                    <p>In vel hendrerit nisi. Vestibulum eget risus velit.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="-cta-btn mt70">
              <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.8s">
                <p>Let's Start a <span>New Project</span> Together</p>
                <a href="#" className="btn-main bg-btn2 lnk">Inquire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></a>
              </div>
            </div>
          </div>
        </section> */}
        {/*End Why Choose*/}
        {/*Start Pricing*/}
        <section className="block-a1 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading ptag">
                  <span>Pricing</span>
                  <h2>Pricing Table</h2>
                  <p className="mb0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="pricing-table mt60">
                  <div className="inner-table">
                    <img src="assets/images/icons/plan-1.svg" alt="Personal" />
                    <span className="title">Personal</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                    <div className="details">
                      <ul>
                        <li>Social Media Marketing</li>
                        <li>2.100 Keywords</li>
                        <li>One Way Link Building</li>
                        <li>5 Free Optimization</li>
                        <li>3 Press Releases</li>
                      </ul>
                    </div>
                  </div>
                  <a href="#" className="btn-main bg-btn lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-table best-plan mt60 bg-gradient4">
                  <div className="inner-table">
                    <img src="assets/images/icons/plan-2.svg" alt="Advance" />
                    <span className="title">Advance</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                    <div className="details">
                      <ul>
                        <li>Social Media Marketing</li>
                        <li>2.100 Keywords</li>
                        <li>One Way Link Building</li>
                        <li>5 Free Optimization</li>
                        <li>3 Press Releases</li>
                      </ul>
                    </div>
                  </div>
                  <a href="#" className="btn-main bg-btn3 lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-table mt60">
                  <div className="inner-table">
                    <img src="assets/images/icons/plan-3.svg" alt="Ultimate" />
                    <span className="title">Ultimate</span>
                    <p className="title-sub">Great For Small Business</p>
                    <h2><sup>$</sup> 79.99</h2>
                    <p className="duration">Monthly Package</p>
                    <div className="details">
                      <ul>
                        <li>Social Media Marketing</li>
                        <li>2.100 Keywords</li>
                        <li>One Way Link Building</li>
                        <li>5 Free Optimization</li>
                        <li>3 Press Releases</li>
                      </ul>
                    </div>
                  </div>
                  <a href="#" className="btn-main bg-btn lnk">Get Started <i className="fas fa-chevron-right fa-icon" /> <span className="circle" /></a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Pricing*/}
        {/*Stat Projects*/}
        {/* <section className="featured-project pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading ptag">
                  <span>Our Projects</span>
                  <h2>Some of Our Works</h2>
                  <p className="mb0">We think big and have hands in all leading technology platforms to provide you wide array of services.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 wow fadeInUp" data-wow-delay=".2s">
                <div className="isotope_item hover-scale">
                  <div className="item-image">
                    <a href="#"><img src="assets/images/portfolio/image-1.jpg" alt="portfolio" className="img-fluid" /> </a>
                  </div>
                  <div className="item-info">
                    <h4><a href="#">Creative </a></h4>
                    <p>ios, design</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-delay=".4s">
                <div className="isotope_item hover-scale">
                  <div className="item-image">
                    <a href="#"><img src="assets/images/portfolio/image-2.jpg" alt="portfolio" className="img-fluid" /> </a>
                  </div>
                  <div className="item-info">
                    <h4><a href="#">Brochure Design</a></h4>
                    <p>Graphic, Print</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-delay=".6s">
                <div className="isotope_item hover-scale">
                  <div className="item-image">
                    <a href="#"><img src="assets/images/portfolio/image-3.jpg" alt="portfolio" className="img-fluid" /> </a>
                  </div>
                  <div className="item-info">
                    <h4><a href="#">Ecommerce Development</a></h4>
                    <p>Web application</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-delay=".8s">
                <div className="isotope_item hover-scale">
                  <div className="item-image">
                    <a href="#"><img src="assets/images/portfolio/image-4.jpg" alt="portfolio" className="img-fluid" /> </a>
                  </div>
                  <div className="item-info">
                    <h4><a href="#">Icon Pack</a></h4>
                    <p>Android &amp; iOs, Design</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-delay="1s">
                <div className="isotope_item hover-scale">
                  <div className="item-image">
                    <a href="#"><img src="assets/images/portfolio/image-5.jpg" alt="portfolio" className="img-fluid" /> </a>
                  </div>
                  <div className="item-info">
                    <h4><a href="#">Smart Watch</a></h4>
                    <p>UI/UX Design</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow fadeInUp" data-wow-delay="1.2s">
                <div className="isotope_item hover-scale">
                  <div className="item-image">
                    <a href="#"><img src="assets/images/portfolio/image-6.jpg" alt="portfolio" className="img-fluid" /> </a>
                  </div>
                  <div className="item-info">
                    <h4><a href="#">Brochure Design</a></h4>
                    <p>Graphic, Print</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 maga-btn mt60">
                <a href="javascript:void(0)" className="btn-outline">View More Projects <i className="fas fa-chevron-right fa-icon" /></a>
              </div>
            </div>
          </div>
        </section> */}
        {/*End Projects*/}
        {/*Start reveiws*/}
        <section className="reviews-block bg-gradient5 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading ptag">
                  <span>Service Testimonials</span>
                  <h2>Client Speaks</h2>
                  <p className="mb30">Check our customers success stories.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mt30">
                <div className="reviews-card pr-shadow">
                  <div className="row v-center">
                    <div className="col"> <span className="revbx-lr"><i className="fas fa-quote-left" /></span> </div>
                    <div className="col"> <span className="revbx-rl"><img src="assets/images/client/envato.png" alt="review service logo" /></span> </div>
                  </div>
                  <div className="review-text">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                  <div className="-client-details-">
                    <div className="reviewer-text">
                      <h4>Sue Vaneer</h4>
                      <p>Business Owner, <small>Jaipur</small></p>
                      <div className="star-rate">
                        <ul>
                          <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                          <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                          <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                          <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                          <li> <a href="javascript:void(0)"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt30">
                <div className="reviews-card pr-shadow">
                  <div className="row v-center">
                    <div className="col"> <span className="revbx-lr"><i className="fas fa-quote-left" /></span> </div>
                    <div className="col"> <span className="revbx-rl"><img src="assets/images/client/envato.png" alt="review service logo" /></span> </div>
                  </div>
                  <div className="review-text">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                  <div className="-client-details-">
                    <div className="reviewer-text">
                      <h4>Don Stairs</h4>
                      <p>Business Owner, <small>Jaipur</small></p>
                      <div className="star-rate">
                        <ul>
                          <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                          <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                          <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                          <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                          <li> <a href="javascript:void(0)"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt30">
                <div className="reviews-card pr-shadow">
                  <div className="row v-center">
                    <div className="col"> <span className="revbx-lr"><i className="fas fa-quote-left" /></span> </div>
                    <div className="col"> <span className="revbx-rl"><img src="assets/images/client/envato.png" alt="review service logo" /></span> </div>
                  </div>
                  <div className="review-text">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                  </div>
                  <div className="-client-details-">
                    <div className="reviewer-text">
                      <h4>Russ L. Rogers</h4>
                      <p>Business Owner, <small>Jaipur</small></p>
                      <div className="star-rate">
                        <ul>
                          <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                          <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                          <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                          <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                          <li> <a href="javascript:void(0)"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End reviews*/}
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <a href="javascript:void(0)" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></a>
                  <p className="cta-call">Or call us now <a href="tel:+1234567890"><i className="fas fa-phone-alt" /> (123) 456 7890</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default ServiceMarketing
