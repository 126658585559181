import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function TechConsulting() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  Cyber Security
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/Cybersecurity/Cybersecurity.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>
                  Cyber Security - A platform that is stronger than a fortress.
                </h2>
                <p>
                  Without protection, accidents happen! The popularity of cloud
                  computing is rising, and cyberattacks are developing at an
                  ever-increasing rate, which is widening the digital world's
                  threat perimeters. A proactive approach to risk minimization
                  is what constitutes cyber security nowadays rather than only
                  ensuring compliance. We aim to improve your resilience against
                  cyber attacks as your company environment and value chain
                  grow. We design cybersecurity specifically for your unique
                  company needs. To provide you the peace of mind that comes
                  from knowing your company is safe, we protect against
                  cyberattacks with proactive, focused, industry-relevant threat
                  information.
                </p>

                {/* <div className="servie-key-points">
                  <h4>
                    Let's talk about tech consulting and why it benefits
                    companies so much. We'll consider:
                  </h4>
                  <ul className="key-points mt20">
                    <li>Reduce costs</li>
                    <li>Create new revenue streams</li>
                    <li>Streamline their operations</li>
                    <li>Improve customer service</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
          <h4
            style={{
              textAlign: "center",
              fontWeight: "bolder",
              fontSize: "30px",
              lineHeight: "50px",
              color: "#359C9C",
              textTransform: "capitalize",
            }}
          >
            {" "}
            “Empowering Enterprise cybersecurity with solutions to help them
            thrive in the face of uncertainty”
          </h4>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "30px !important" }}>
            What we do
          </h2>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Cybersecurity/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>
                  Cyber Defense Strategy
                  <p style={{ color: "#EC8F21" }}> - Plan ahead.</p>{" "}
                </h3>
                <p>
                  In order to manage business risk, uphold legal
                  responsibilities, and wisely choose security solutions, we
                  help firms create long-term strategic cyber security roadmaps.
                  By equipping your business to deal with the most skilled cyber
                  attackers, you may lessen your exposure to risks directed at
                  corporate assets, hardware, and software. For a cyber threat
                  operations centre to grow confidently, integrate cutting-edge
                  technologies and pick the appropriate model. Securing the
                  software development lifecycle and the supply chain for the
                  application stack. Discover risks and breaches that might
                  affect business operations, then take action and recover.
                  Support decisions with relevant, actionable threat
                  intelligence to respond to risks as they evolve over time and
                  to increase security maturity.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>
                  Devoted Security
                  <p style={{ color: "#EC8F21" }}>
                    {" "}
                    - Solutions that tackles present and future threats
                  </p>
                </h3>
                <p>
                  In order to enable development while maintaining within
                  predefined risk tolerances, our data protection solutions
                  provide the preventative measures that are both balanced and
                  essential. Our all-encompassing strategy for infrastructure
                  and cloud security protects them from the inside out, unlocks
                  value, and boosts your return. In order to avoid illegal
                  access, we have implemented policies and tools that make it
                  easier to use cloud and business services. We support the
                  consolidation of risk management governance and technologies,
                  including automation and context-sensitive controls, to ensure
                  efficiency and responsibility. We create and deploy hardened
                  environments, enable security testing, and provide security
                  monitoring in order to strengthen the resilience of crucial
                  business services.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Cybersecurity/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Cybersecurity/3.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>
                  Administered Security
                  <p style={{ color: "#EC8F21" }}>
                    {" "}
                    - Manage security assets using cutting edge techs.
                  </p>
                </h3>
                <p>
                  Offering customers the as-a-Service capabilities,
                  cybersecurity services, and cutting-edge technology required
                  to expand security and compliance operations swiftly You may
                  create systems that are safer to use, have more secure
                  applications, and have compliance settings by using
                  industrialized and repeatable operations. Your teams can
                  concentrate on higher priority issues by having a thorough
                  understanding of risks, responding quickly, and having a
                  complete picture of your cloud environment. Implement policies
                  and tools that consolidate and streamline access to
                  business-related services and cloud-based applications.
                  Reducing uncertainty will help you achieve high performance by
                  enabling smarter decisions, lowering risks, and increasing
                  profits. By protecting against the most skilled online
                  attackers, you may lessen your exposure to attacks against
                  software, hardware, and organizational assets. Cloud computing
                  technology provides you with new ways to share and
                  collaborate, improve your agility and data storage, and lower
                  the total cost of IT operations.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>4</span>
                <h3>
                  Industry Grade Solution
                  <p style={{ color: "#EC8F21" }}>
                    {" "}
                    - Modern problems require advanced solutions.
                  </p>
                </h3>
                <p>
                  Secure linked devices and embedded systems are required to
                  enable intelligent, connected, and reliable goods and services
                  for our clients' internal use as well as to provide them to
                  their customers. In order to understand their current
                  surroundings, strategic risks, and plans for closing such
                  gaps, we help our clients by providing them with
                  industry-specific features, including physical security and
                  border control. We offer complete solutions for the whole
                  value chain using our knowledge, globally integrated
                  resources, and cutting-edge technology. And not only do we
                  provide our clients a secure solution but also an edge over
                  the control.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Cybersecurity/4.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Process*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default TechConsulting;
