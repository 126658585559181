import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../About/Navbar'

function WebsiteRevamp() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
        <div>
        <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/6.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">Professional Website Redesign Services</h1>
                  <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">When we redesign your site, our main goal is to improve your website completely</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Start About*/}
        <section className="service pad-b">
          <div className="container">
            <div className="row"> 
              <div className="col-lg-4 px-5 ">
                <div className="image-block upset bg-shape wow fadeIn">
                  <img src="assets/images/about/website-redesign.png" alt="image" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-8 block-1">
                <div className="common-heading text-l pl25">
                  <span>Overview</span>
                  <h2>Improve your business by redesigning your website</h2>
                  <p>According to statistics you only have 7 seconds to grab the attention of a visitor to your site and this has to be kept 
                    in mind while building your website. If you realize that the bounce rate is high then it a good time to think about 
                    redesigning your website. People tend to stay on website for a longer time if it has great visuals with apt content, 
                    easy user interface, less loading time and to the point information. These factors will influence in the conversion 
                    rates through your website. We help you analyze and improve your website design that attracts leads to your business. 
                    Whether it is revamping or 
                    redesigning  we make sure that it is a boost to your business.</p>
                
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        {/*Start Tech*/}
        <div className="techonology-used-">
          <div className="container">
            <ul className="h-scroll tech-icons">
              <li><a href="#"><img src="assets/images/icons/stack-icon1.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon2.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon3.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon4.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon5.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon6.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon7.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon8.png" alt="icon" /></a></li>
            </ul>
          </div>
        </div>
        {/*End Tech*/}
        {/*Start Service*/}
        <section className="service-block bg-gradient6 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading ptag">
                  <span>Service</span>
                  <h2>Website Revamping Services</h2>
                  <p className="mb30">We provide services that well suit your business needs.</p>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
                <div className="s-block">
                  <div className="s-card-icon"><img src="assets/images/icons/logo-and-branding.svg" alt="service" className="img-fluid" /></div>
                  <h4>Custom website redesigns</h4>
                  <p>Mercy IT Corp offers custom website builds from the bottom up. We can create an internet site using HTML, or maybe craft one in your favorite CMS. The choices are endless. We do it in the best way.
</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
                <div className="s-block">
                  <div className="s-card-icon"><img src="assets/images/icons/service2.svg" alt="service" className="img-fluid" /></div>
                  <h4>Website redesign calculator</h4>
                  <p>Curious about the value of our web redesign services? Whether you’re looking to make a responsive website to extend leads or update your site to reflect your brand’s image.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
                <div className="s-block">
                  <div className="s-card-icon"><img src="assets/images/icons/service3.svg" alt="service" className="img-fluid" /></div>
                  <h4>Rapid RainmakerFX website designs</h4>
                  <p>We understand that sometimes, once you need an internet site , you would like it now. That's why we've unrolled RainmakerFX</p>
                </div>
              </div>
            </div>
            <div className="-cta-btn mt70">
              <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.3s">
                <p>Hire a <span>Dedicated Developer</span></p>
                <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        {/*Start Process*/}
        <section className="service-block bg-gradient6 pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading ptag">
                <span>Process</span>
                <h2>Our Website Revamp Process</h2>
                <p>
                Our Website Revamp process follows a proven approach. We begin with a deep understanding of your needs and
                 create a wonderful upgrade of your website.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset link-hover  ">
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock  s-block-height">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Evaluate.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                  <h4>1. Evaluate</h4>
                  <p>
                  To understand why your website needs to revamped, you need to evaluate your website and find out what works and what doesn’t. 
                  We analyze your website and find ways to improve it on the whole.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Set Goals.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>2. Set Goals</h4>
                  <p>
                  We get a clear picture of what the client wants and create a strategy to achieve our end goal.

                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Implement.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>3. Implement</h4>
                  <p>
                  To let the idea to take shape we implement our strategy and carefully curate design and content.
                  </p>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Review.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>4. Review</h4>
                  <p>
                  When we are set to launch the website, there’s an important step, that is to review the website. 
                  To ensure it is error free, functional and works across all devices.
                  </p>
                
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt30 wow fadeInUp" data-wow-delay=".4s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Launch.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>5. Launch</h4>
                  <p>
                  Once we checklist all the above steps, the website is all set to be launched. And that means more web traffic and conversions.
                  </p>
               
                </div>
              </div>
            </div>
          
           
          </div>
        </div>
      </section>
        {/*End Process*/}
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <Link onClick={timer} to="/contact-us" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or call us now <a href="tel:+91 9176454455"><i className="fas fa-phone-alt" />+91 9176454455</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default WebsiteRevamp
