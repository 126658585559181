import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function TechConsulting() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  Cloud Computing
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/Cloud Computing/Cloud Computing.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>
                  Cloud Computing - Safe & Secure way to store your datas and
                  operate your enterprise.
                </h2>
                <p>
                  Conquer the world through clouds! Our cloud computing assists
                  businesses in moving their operations into a more secure and
                  sophisticated environment, going much beyond the countless
                  advantages and protections the cloud provides. We know that
                  cloud computing is more than just technology. Therefore, our
                  cloud solutions address the workforce and cultural
                  transformation required for success in a way that is accurate
                  and long-lasting. Every organization has a distinct narrative
                  that should be heard amid the clouds, and that is what we are
                  giving you the opportunity to do. Cloud computing provides
                  advantages and protections to businesses.
                </p>
                {/* <div className="servie-key-points">
                  <h4>
                    Let's talk about tech consulting and why it benefits
                    companies so much. We'll consider:
                  </h4>
                  <ul className="key-points mt20">
                    <li>Reduce costs</li>
                    <li>Create new revenue streams</li>
                    <li>Streamline their operations</li>
                    <li>Improve customer service</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "30px !important" }}>
            What we do
          </h2>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Cloud Computing/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>
                  Cloud Migration - Switch with ease to the quickest and the
                  safest way to access your confidential data.
                </h3>
                <p>
                  Knowing what cloud migration is, how it may benefit your
                  business, and what is involved can help you decide on the best
                  strategy for a seamless move to the cloud. We help you
                  navigate complexity, such as rules governing data sovereignty
                  and cloud landing zones. We work with you to determine the
                  best cloud strategy, operational model, roadmap, and ecosystem
                  partners. We then work with you to hasten your migration and
                  modernization to the cloud, which we promise is secure,
                  affordable, and quick.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>
                  Expert Analysis - Strategies devised by exceptionally skilled
                  professionals, aiding your enterprise towards the better!
                </h3>
                <p>
                  Studies reveal that a lack of research and a great strategy
                  are the primary causes of both huge corporations and startups
                  failing so quickly. Through comprehensive, in-depth analysis
                  of your business statistics, our professional strategists
                  provide and develop tactics that can be tailored at every
                  step, are failsafe, and have been demonstrated to produce
                  long-lasting traffic, providing you with first-hand knowledge
                  and a road map for your company. If our strategists' plan were
                  ever threatened from the outside, they are fully skilled
                  enough to provide you a fail-safe that would ensure your
                  company would remain afloat among the clouds.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Cloud Computing/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Cloud Computing/3.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>
                  Infrastructure Services - A platform structured using modern
                  ways to drive results far and beyond.
                </h3>
                <p>
                  How successfully your organization succeeds in the modern,
                  digital, cloud-based environment depends on its IT
                  infrastructure. The programmes that run the firm are now more
                  user-friendly. a process for creating outstanding consumer and
                  employee experiences. However, the current infrastructure is
                  unable to keep up with the compressed transition and quick
                  change. The IT infrastructure needs to be modernized. We can
                  assist you in changing from a capital-intensive,
                  hardware-centric infrastructure to a smart, software-defined
                  one that is always prepared for any opportunity.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>4</span>
                <h3>
                  Cloud Security - Equip your enterprise with unbreachable cloud
                  tech to safe keep datas against any threat.
                </h3>
                <p>
                  While the cloud provides new opportunities to update services
                  and restructure operations, the largest obstacle to cloud
                  adoption is security and compliance risk. These issues, when
                  combined with the complexity of hybrid- and multi-cloud
                  infrastructures and a skills scarcity, can be serious
                  impediments to a cloud-first path. All of this puts security
                  executives in the driver's seat when it comes to making a
                  cloud-first journey secure by design in order to offer
                  superior business outcomes.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Cloud Computing/4.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Process*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default TechConsulting;
