
// import Home from './components/header/Home';
import Badges from './Components/Home/Badges';
import Carousel1 from './Components/Home/Carousel1';
import Clients from './Components/Home/Clients';
import CTA from './Components/Home/CTA';
import Footer from './Components/Home/Footer';
import Hero from './Components/Home/Hero';
import Location from './Components/Home/Location';
import Navbar from './Components/Home/Navbar';
import Portfolio from './Components/Home/Portfolio';
import Service from './Components/Home/Service';
import StartAbout from './Components/Home/StartAbout';
import Testimonials from './Components/Home/Testimonials';
import WhyChoose from './Components/Home/WhyChoose';
import WorkCategory from './Components/Home/WorkCategory';
import 'antd/dist/antd.css';



import {BrowserRouter as Router, Route, Switch, HashRouter, BrowserRouter } from 'react-router-dom';
import Blog from './Components/Home/blog'
import Blogfullcontent from './Components/Home/blogfullcontent/blogfullcontent1'
import About from '../src/Components/About/About';
import WhyUs from '../src/Components/About/WhyUs';
import MissionVision from '../src/Components/About/MissionVision';
import OurTeam from '../src/Components/About/OurTeam';
import CaseStudy from '../src/Components/About/CaseStudy';
import CaseStudyDetails from '../src/Components/About/CaseStudyDetails';
import TeamIndividual1 from '../src/Components/About/teamIndividuals/TeamIndividual1';
import TeamIndividual2 from '../src/Components/About/teamIndividuals/TeamIndividual2';
import TeamIndividual3 from '../src/Components/About/teamIndividuals/TeamIndividual3';
import TeamIndividual4 from '../src/Components/About/teamIndividuals/TeamIndividual4';
import TeamIndividual5 from '../src/Components/About/teamIndividuals/TeamIndividual5';
import TeamIndividual6 from '../src/Components/About/teamIndividuals/TeamIndividual6';
import TeamIndividual7 from '../src/Components/About/teamIndividuals/TeamIndividual7';
import TeamIndividual8 from '../src/Components/About/teamIndividuals/TeamIndividual8';
import TeamIndividual9 from '../src/Components/About/teamIndividuals/TeamIndividual9';
import TeamIndividual10 from '../src/Components/About/teamIndividuals/TeamIndividual10';
import TeamIndividual11 from '../src/Components/About/teamIndividuals/TeamIndividual11';
import ContactUs from '../src/Components/About/ContactUs';
import Pricing from './Components/Services/Pricing';
import ServiceApp from './Components/Services/ServiceApp';
import ServiceCard from './Components/Services/ServiceCard';
import ServiceGraphic from './Components/Services/ServiceGraphic';
import ServiceMarketing from './Components/Services/ServiceMarketing';
import ServiceWeb from './Components/Services/ServiceWeb';
import ShopDetails from './Components/Services/ShopDetails';
import Ecommerce from './Components/Services/Web Applications/Ecommerce';
import AppDevelopment from './Components/Services/Mobile App development/AppDevelopment';
import DynamicWebsite from './Components/Services/Websites/DynamicWebsite';
import StaticWebsite from './Components/Services/Websites/StaticWebsite';
import WebsiteRevamp from './Components/Services/Websites/WebsiteRevamp';
import CmsWebsite from './Components/Services/Websites/CmsWebsite';
import SEO from './Components/Services/Digital Marketing/SEO';
import SocialMedia from './Components/Services/Digital Marketing/SocialMedia';
import GoogleAds from './Components/Services/Digital Marketing/GoogleAds';
import EmailMarketing from './Components/Services/Digital Marketing/EmailMarketing';
import UiUxDesign from './Components/Services/Creatives/UiUxDesign';
import GraphicDesign from './Components/Services/Creatives/GraphicDesign';
import Ios from './Components/Services/Mobile App development/Ios';
import CrossApp from './Components/Services/Mobile App development/CrossApp';
import OurServices from './Components/Home/OurServices';
import Blogsmcontent1 from './Components/Home/blogsmallcontent/blogsmcontent1';
import Techconsulting from './Components/Services/TechConsulting';
import DigitalTransformation from './Components/Services/DigitalTransformation';
import DigitalCommerce from './Components/Services/DigitalCommerce';
import CloudComputing from './Components/Services/CloudComputing';
import Automation from './Components/Services/Automation';
import HRStaffing from './Components/Services/HR&Staffing';
import CyberSecurity from './Components/Services/CyberSecurity';
import ProductEngineering from './Components/Services/ProductEngineering';
import BusinessProcess from './Components/Services/BusinessProcess';
import Robotics from './Components/Services/Robotics';
import ARandVR from './Components/Services/ARandVR';
import DataTechnology  from './Components/Services/DataTechnology';
import GraphicalCreations from './Components/Services/GraphicalCreations';
import DigitalMarketing from './Components/Services/DigitalMarketing';
import Comingsoon from './Components/Services/Comingsoon';




import ReactGa from 'react-ga';
import { useEffect } from 'react';




function App() {

  useEffect(() => {

    ReactGa.initialize('UA-154138074-1');

    //To report page view
    ReactGa.pageview(window.location.pathname + window.location.search);
  
  }, [])

//  const [state, setstate] = useState(initialState)

  return (
    <BrowserRouter>
    <div className="App">
    
      <Switch>
        <Route exact path="/">
          <Navbar />
          <Hero />
          <StartAbout />
          <Service />
          <WhyChoose />
          {/* <Portfolio /> */}
          {/* <Clients /> */}
          {/* <Badges /> */}
          <WorkCategory />
          <Testimonials />
          {/* <CTA /> */}
          {/* <Location /> */}
        </Route>

        <Route path="/about-us">
          <About />
        </Route>

        <Route path="/why-us">
          <WhyUs />
        </Route>

        <Route path="/mission-vision">
          <MissionVision />
        </Route>

        <Route path="/our-team" >
          <OurTeam />
        </Route>

        <Route path="/team-individual1">
          <TeamIndividual1 />
        </Route>

        <Route path="/team-individual2">
          <TeamIndividual2 />
        </Route>

        <Route path="/team-individual3">
          <TeamIndividual3 />
        </Route>

        <Route path="/team-individual4">
          <TeamIndividual4 />
        </Route>

        <Route path="/team-individual5">
          <TeamIndividual5 />
        </Route>

        <Route path="/team-individual6">
          <TeamIndividual6 />
        </Route>

        <Route path="/team-individual7">
          <TeamIndividual7 />
        </Route>

        <Route path="/team-individual8">
          <TeamIndividual8 />
        </Route>

        <Route path="/team-individual9">
          <TeamIndividual9 />
        </Route>

        <Route path="/team-individual10">
          <TeamIndividual10 />
        </Route>

        <Route path="/team-individual11">
          <TeamIndividual11 />
        </Route>

        <Route path="/portfolio">
          <CaseStudy />
        </Route>

        <Route path="/case-study-details">
          <CaseStudyDetails />
        </Route>

        <Route path="/contact-us">
          <ContactUs />
        </Route>

        <Route path="/pricing">
          <Pricing />
        </Route>

        <Route path="/service-app">
          <ServiceApp />
        </Route>

        <Route path="/service-card">
          <ServiceCard />
        </Route>
        
        <Route path="/service-graphic">
          <ServiceGraphic />
        </Route>
        
        <Route path="/service-marketing">
          <ServiceMarketing />
        </Route>
        
        <Route path="/service-web">
          <ServiceWeb />
        </Route>
        
        <Route path="/shop-details">
          <ShopDetails />
        </Route>

        <Route path="/dynamic-website">
          <DynamicWebsite /> 
        </Route>
        
        <Route path="/ecommerce">
          <Ecommerce />
        </Route>
        
        <Route path="/app-development">
          <AppDevelopment /> 
        </Route>
        
        <Route path="/static-website">
          <StaticWebsite /> 
        </Route>
        
        <Route path="/techconsulting">
          <Techconsulting/>
        </Route>

        <Route path="/digitaltransformation">
          <DigitalTransformation/>
        </Route>

        <Route path="/digitalcommerce">
          <DigitalCommerce/>
        </Route>

        <Route path="/cloudcomputing">
          <CloudComputing/>
        </Route>

        <Route path="/automation">
          <Automation/>
        </Route>

        <Route path="/hrandstaffing">
          <HRStaffing/>
        </Route>

        <Route path="/cybersecurity">
          <CyberSecurity/>
        </Route>

        <Route path="/productengineering">
          <ProductEngineering/>
        </Route>

        <Route path="/businessprocess">
          <BusinessProcess/>
        </Route>

        <Route path="/robotics">
          <Robotics/>
        </Route>

        <Route path="/arandvr">
          <ARandVR/>
        </Route>

        <Route path="/datatechnology">
          <DataTechnology/>
        </Route>

        <Route path="/graphicalcreations">
          <GraphicalCreations/>
        </Route>

        <Route path="/digitalmarketing">
          <DigitalMarketing/>
        </Route>

        <Route path="/comingsoon">
          <Comingsoon/>
        </Route>

        <Route path="/website-revamp">
          <WebsiteRevamp /> 
        </Route>
        
        <Route path="/cms-website">
          <CmsWebsite /> 
        </Route>
        
        <Route path="/seo-marketing">
          <SEO /> 
        </Route>
        
        <Route path="/social-media">
          <SocialMedia /> 
        </Route>
        
        <Route path="/google-ads">
          <GoogleAds /> 
        </Route>
        
        <Route path="/email-marketing">
          <EmailMarketing /> 
        </Route>
        
        <Route path="/uiux-design">
          <UiUxDesign /> 
        </Route>
        
        <Route path="/graphic-design">
          <GraphicDesign /> 
        </Route>

        <Route path="/ios-app">
          <Ios />
        </Route>

        <Route path="/cross-app">
          <CrossApp />
        </Route>

        <Route path="/our-services">
          <OurServices />
        </Route>

        <Route path="/blogfullcontent1">
          <Blogfullcontent />
        </Route>

        <Route path="/blogsmcontent">

          <Blogsmcontent1/>
        </Route>

        <Route path="/blog">
          <Blog />
        </Route>

     
       
        
      </Switch>
      
      <Footer />
    </div>
    </BrowserRouter>
  );
}

export default App;
