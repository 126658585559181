import React from 'react'
import Navbar from '../../About/Navbar'
import { Link } from 'react-router-dom'; 

function CrossApp() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
      <div>
      <Navbar />
          {/*Breadcrumb Area*/}
      <section className="breadcrumb-areav2" data-background="assets/images/banner/8.jpg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">Top Rated Mobile App Development Company</h1>
                <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Quickly create mobile apps, chatbots, and interactive web apps for any device. Build your own data models, workflows, logical user interfaces, and pixel-perfect interactions, and even add your own custom code.</p>
                <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img src="assets/images/service/app-develops1.png" alt="image" className="img-fluid no-shadow" />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>Custom Mobile App Solutions</h2>
                <p>In 2021, mobile apps are projected to get $693 billion in revenue via app stores and in-app advertising. What’s more, enterprise mobility is estimated to be worth $510.39 billion by 2022.</p>
                <p>While many companies try to require advantage of this trend, many don't have skills to make an app successfully. Success within the competitive landscape of those growth projections can become a reality for your company—but as long as supported by a precisely cultivated mobile app development process.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}
      <section className="service pad-tb">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="text-l service-desc- pr25">
                <h3>Get a complete strategy of mobile app development</h3>
                <h5 className="mt10 mb20">Here Are Just a couple of Reasons Why We’re Rated the #1 Development Platform</h5>
                <p>Quickly create mobile apps, chatbots, and interactive web apps for any device. Build your own data models, workflows, logical user interfaces, and pixel-perfect interactions, and even add your own custom code.</p>
                <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt30">Request A Quote  <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="servie-key-points">
                <h4>Advantages of Mobile App Development</h4>
                <ul className="key-points mt20">   
                  <li>Substitution of Traditional Method of Market</li>
                  <li>An effective way of Branding and engaging the audience</li>
                  <li>Reduction in cost and raises standards</li>
                  <li>Increase in Revenue</li>
                  <li>Gives more value to customers</li>
                  <li>24*7 support facility</li>
                  <li>Feasible and Convenient</li>
                  <li>Secured from vulnerable attacks</li>   
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li><a href="#"><img src="assets/images/icons/swift.png" alt="icon" /></a></li>
            <li><a href="#"><img src="assets/images/icons/java.png" alt="icon" /></a></li>
            <li><a href="#"><img src="assets/images/icons/reactnative.png" alt="icon" /></a></li>
            <li><a href="#"><img src="assets/images/icons/flutter.png" alt="icon" /></a></li>
            <li><a href="#"><img src="assets/images/icons/ionic.png" alt="icon" /></a></li>
            <li><a href="#"><img src="assets/images/icons/kotlin.png" alt="icon" /></a></li>
            <li><a href="#"><img src="assets/images/icons/xamarin.png" alt="icon" /></a></li>
          </ul>
        </div>
      </div>
      {/*End Tech*/}
      {/*Start Service*/}
      <section className="service-block bg-gradient6 pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="common-heading ptag">
                <span>Service</span>
                <h2>Our Services</h2>
                <p className="mb30">We plan big and have our hands on all the top technology platforms to offer you a wide range of services</p>
              </div>
            </div>
          </div>
          <div className="row upset link-hover">
            <div className="col-lg-6 col-sm-6 mt30  wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock">
                <div className="s-card-icon-large"><img src="assets/images/service/mob-service-item2.png" alt="service" className="img-fluid" /></div>
                <div className="s-block-content-large">
                  <h4>iOS App Development</h4>
                  <p>To develop high quality iOS apps, our mobile app development team is equipped with latest technologies.</p>
                </div></div>
            </div>
            <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
              <div className="s-block wide-sblock">
                <div className="s-card-icon-large"><img src="assets/images/service/mob-service-item1.png" alt="service" className="img-fluid" /></div>
                <div className="s-block-content-large">
                  <h4>Android App Development</h4>
                  <p>To develop high quality Android apps, our mobile app development team is equipped with latest technologies.</p>
                </div></div>
            </div>
            <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
              <div className="s-block wide-sblock">
                <div className="s-card-icon-large"><img src="assets/images/service/mob-service-item3.png" alt="service" className="img-fluid" /></div>
                <div className="s-block-content-large">
                  <h4>Cross Platform App Development</h4>
                  <p>To develop high quality Android and iOS apps, our mobile app development team is equipped with latest technologies.</p>
                </div></div>
            </div>
            
          </div>
          <div className="-cta-btn mt70">
            <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.1s">
              <p>Hire a <span>Dedicated Developer</span></p>
              <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
            </div>
          </div>
        </div>
      </section>
      {/*End Service*/}
      {/*Start Process*/}
      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading ptag">
                <span>Process</span>
                <h2>Our App Development Process</h2>
                <p>Our design process follows a proven approach. We begin with a deep understanding of your needs and create a planning template.</p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img src="assets/images/process/process-1.jpg" alt="Process" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>Strategy</h3>
                <p>The first phase of the mobile app development process is defining the strategy for evolving your idea into a successful app. you'll include a more significant a part of this in your overall enterprise mobility strategy.</p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>Analysis and Planning</h3>
                <p>At this stage, your app idea starts taking shape and turns into an actual project. Analysis and planning begin with defining use cases and capturing detailed functional requirements. After you've got identified the wants for your app, prepare a product roadmap. This includes prioritizing the mobile app requirements and grouping them into delivery milestones</p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img src="assets/images/process/process-2.jpg" alt="Process" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img src="assets/images/process/process-3.jpg" alt="Process" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>UI / UX Design</h3>
                <p>The success of a mobile app is decided supported how well users are adopting and taking advantage of all its features. The goal for mobile app UI / UX design is creating excellent user experiences making your app interactive, intuitive, and user-friendly.</p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>4</span>
                <h3>App Development</h3>
                <p>Planning remains an integral a part of this introduce the mobile app development process. Before actual development/programming efforts start, you'll have to:define the technical architecture, pick a technology stack, and define the event milestones.</p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img src="assets/images/process/process-4.jpg" alt="Process" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Process*/}
      
      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                <p className="cta-call">Or call us now <a href="tel:+91 9176454455"><i className="fas fa-phone-alt" />+91 9176454455</a></p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
        <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
        <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
        <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
      </section>
      {/*End CTA*/}
      </div>
    )
}

export default CrossApp
