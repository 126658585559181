import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function TechConsulting() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  Business Process
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/Business Process/BP.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>
                  Business Process Services - Transform your Organization.
                </h2>
                <p>
                  Business process services can redefine business performance in
                  a world of constant change by creating sustainable value via
                  clever operations. Business executives now have new
                  opportunities because of digital technology. Automation,
                  artificial intelligence, the cloud, and other cutting-edge
                  technologies are being used to create more intelligent
                  workflows that reimagine traditional business process
                  outsourcing and operations models. Businesses must
                  continuously adapt to more sophisticated, carefully
                  coordinated processes that include consumers, workers,
                  suppliers, and business partners alike in order to stay
                  relevant and expand.
                </p>
                {/* <div className="servie-key-points">
                  <h4>
                    Let's talk about tech consulting and why it benefits
                    companies so much. We'll consider:
                  </h4>
                  <ul className="key-points mt20">
                    <li>Reduce costs</li>
                    <li>Create new revenue streams</li>
                    <li>Streamline their operations</li>
                    <li>Improve customer service</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "30px !important" }}>
            What we do
          </h2>
          <p style={{ textAlign: "center" }}>
            We help companies succeed in putting creativity, speed, and
            execution as a top priority as digital capabilities change the norms
            of interaction across sectors. We assist them in creating
            cutting-edge operational models that are backed by flexible,
            digitalized workflows, and driven by cutting-edge technology.
            Additionally, combine different, dispersed skill pools to create new
            value.
          </p>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Business Process/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>Procurement & Sourcing Services</h3>
                <p>
                  In today's era, procurement companies are in a position where
                  they need to develop their digital capabilities, their
                  capacity for future prediction, and their capacity for team
                  empowerment. We provide procurement analytics as a service in
                  addition to source to pay consulting services.
                </p>
                <div className="servie-key-points">
                  <h4>Why should you go for our Procurement service!</h4>
                  <ul className="key-points mt20">
                    <li>
                      Improve operational efficiency, reduce costs, and increase
                      resilience, reinvent the procurement operation with
                      intelligent workflows that hyper-automate, inject agility,
                      and leverage exponential technologies like AI, data
                      analytics, and automation.
                    </li>
                    <li>
                      Utilize data from the company as a whole and from the
                      network of suppliers to produce impartial insights that
                      improve the flexibility and responsiveness of the supply
                      chain and allow for immediate adjustments to negotiate the
                      best possible prices.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>Finance Management Services</h3>
                <p>
                  We assist finance firms in transitioning from increasing the
                  effectiveness of their financial processes to developing smart
                  functions with intelligent workflows that are able to locate,
                  link, and analyze data in order to provide insightful findings
                  that may guide wise decisions. Together with our clients, our
                  financial experts provide guidance and oversee end-to-end
                  operations.
                </p>
                <div className="servie-key-points">
                  <h4>Why should you go for our Finance Consulting Service!</h4>
                  <ul className="key-points mt20">
                    <li>
                      Using our approach, you may identify opportunities to
                      increase operational productivity and performance
                      management which helps you drastically reduce operating
                      cost - A decision that is so smart & efficient you
                      couldn't simply resist.
                    </li>
                    <li>
                      Improve forecast accuracy and cash flow by leveraging data
                      and technology.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Business Process/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Business Process/3.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>Talent Acquisition & Management Services</h3>
                <p>
                  Change management and HR reinvention are unavoidable as a
                  result of digital disruption. However, our expert HR team
                  combines people acquisition, development, and strategy to
                  redefine your teams' operations.
                </p>
                <div className="servie-key-points">
                  <h4>Why should you choose us!</h4>
                  <ul className="key-points mt20">
                    <li>
                      Personalize your human resources platform and incorporate
                      self-service features to increase employee engagement.
                    </li>
                    <li>
                      UDevelop your abilities with adaptive HR systems and
                      sophisticated workflows. Establish a culture of ongoing
                      learning.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>4</span>
                <h3>Supply Chain</h3>
                <p>
                  Supply chain executives are under pressure to increase value
                  for the company. Customer expectations are changing, company
                  complexity is rising, and disruption is transforming the
                  supply chain's function from that of a cost center to one of a
                  sustainable development engine.
                </p>
                <div className="servie-key-points">
                  <h4>Why our supply chain service stands out!</h4>
                  <ul className="key-points mt20">
                    <li>
                      Increase productivity and efficiency throughout the whole
                      supply chain while maximizing sustainability by leveraging
                      digital technology and human innovation.
                    </li>
                    <li>
                      Integrating sustainable and ethical principles into supply
                      chain operations to minimize risk and provide benefits to
                      the business and society at large.
                    </li>
                    <li>
                      Focus business operations on providing customers with
                      high-quality, useful products and experiences that satisfy
                      their demands, transforming the supply chain into a force
                      for economic expansion.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Business Process/4.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
     
        </div>
      </section>
      {/*End Process*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default TechConsulting;
