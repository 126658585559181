import React from 'react'
import { Link } from 'react-router-dom'

function StartAbout() {
    return (
        <div>
            {/*Start About*/}
        <section className="about-sec-app pad-tb pt60">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="common-heading text-l">
                  <h2 className="mb30"><span className="text-second text-bold">Mercy IT Corp - The right technology partner for you</span></h2>
                  <p>A dedicated team of professionals with experience in Information Technology provides our services to a broad portfolio of corporate, public, hospitality, health, education, E-commerce clients throughout various parts of the world. We are highly proficient in Mobile Applications, Chatbots, IOT, Web Apps, Blockchain and more. Our main priority is to build  revolutionary technological change.</p>
                </div>
                <div onClick={() => window.location.reload()} className="cta-card mt40">
                  <Link  to="/contact-us" className="btn-outline lnk">Request For Free Proposal<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="funfact">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                      <div className="funfct srcl1">
                        <img src="assets/images/icons/startup.svg" alt="niwax app development template" />
                        <span className="services-cuntr counter">10</span><span className="services-cuntr">+</span>
                        <p>Years Experience</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                      <div className="funfct srcl2">
                        <img src="assets/images/icons/team.svg" alt="niwax app development template" />
                        <span className="services-cuntr counter">10</span><span className="services-cuntr">+</span>
                        <p>Talented Squad</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                      <div className="funfct srcl3">
                        <img src="assets/images/icons/mobile.svg" alt="niwax app development template" />
                        <span className="services-cuntr counter">50</span><span className="services-cuntr">+</span>
                        <p>Projects Developed</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                      <div className="funfct srcl4">
                        <img src="assets/images/icons/computers.svg" alt="niwax app development template" />
                        <span className="services-cuntr counter">100</span><span className="services-cuntr">%</span>
                        <p>Projects Delivered</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                      <div className="funfct srcl5">
                        <img src="assets/images/icons/world.svg" alt="niwax app development template" />
                        <span className="services-cuntr counter">8</span><span className="services-cuntr"></span>
                        <p>Countries Served</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                      <div className="funfct srcl1">
                        <img src="assets/images/icons/deal.svg" alt="niwax app development template" />
                        <span className="services-cuntr counter">100</span><span className="services-cuntr">%</span>
                        <p>Client Satisfaction</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        </div>
    )
}

export default StartAbout
