import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function TechConsulting() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  HR & Staffing
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/HR & Staffing/HR & Staffing.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>
                  HR & Staffing / HR Operation & Talent Acquisition Services
                </h2>
                <p>
                  It's crazy to think that finding the right people will decide
                  your company's success or failure. By utilizing cutting-edge
                  technology and a people-centered approach to talent
                  management, we're here to ensure that your company has the
                  appropriate recruiters on hand with the proper skill sets to
                  motivate your team. We support organizations in their natural
                  humanization while integrating technology and artificial
                  intelligence into HR to achieve rapid outcomes. We can enhance
                  talent management by providing intentional experiences and
                  fostering a culture of continuous learning. As the quote says,
                  finding good people is a key challenge and we are here to face
                  that challenge in your stead!
                </p>
                {/* <div className="servie-key-points">
                  <h4>
                    Let's talk about tech consulting and why it benefits
                    companies so much. We'll consider:
                  </h4>
                  <ul className="key-points mt20">
                    <li>Reduce costs</li>
                    <li>Create new revenue streams</li>
                    <li>Streamline their operations</li>
                    <li>Improve customer service</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "30px !important" }}>
            What we offer
          </h2>
          <p style={{ textAlign: "center" }}>
            When selecting a candidate, you are selecting a spokesperson for
            your company, and you do not want to make the incorrect choice. When
            looking for individuals without previous and sufficient
            understanding of the local labor market, mistakes are common. Our
            staffing team is made up of specialists that understand how local
            labor markets function and how to find qualified people who not only
            represent your brand's value but also become its face. We assist
            organizations in acquiring extraordinary people who are highly
            specialized in the field they need.
          </p>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/HR & Staffing/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>Talent Acquisition</h3>
                <p>
                  Improve your hiring methods to increase your competitive
                  advantage. Integrate adaptable, data-driven methods to
                  revolutionize the hiring experience for both candidates and
                  hiring managers, and equip your teams with powerful tools.
                  Utilize an adaptive, intelligent, data-driven solution to
                  deliver value beyond the normal efficiency in talent
                  acquisition. We assist enterprises to create new processes,
                  integrate and enhance existing ones in a cloud-based manner,
                  and drive new efficiencies through job aids, value
                  propositions (VCs), and customer success strategies in order
                  to advance the hiring systems.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>Talent Development</h3>
                <p>
                  Create resiliency by making skills the focal point of your
                  workforce strategy and obtaining new skills as required to
                  swiftly adapt to shifting company demands. We can lessen
                  dependency on formal education, monitor skill development, and
                  shorten the time it takes to become competent. Personalized,
                  digital learning experiences should be provided at the point
                  of need for the employees who are already integrated into the
                  workflow to help bridge the skills gap.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/HR & Staffing/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/HR & Staffing/3.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>HR Outsourcing</h3>
                <p>
                  AI-infused data-driven procedures and contemporary HR
                  outsourcing may improve employee experiences while lowering
                  operational expenses. We help you reap all the benefits from
                  HR outsourcing using these three simple yet effective methods:
                </p>
                <div className="servie-key-points">
                  <ul className="key-points mt20">
                    <li>
                      Cost Experience - Diverting your priorities towards your
                      employees to keep them satisfied without any additional
                      expenses.
                    </li>
                    <li>
                      Reflex Response - Most inquiries can be answered right
                      away, which boosts production and saves time for staff -
                      the faster the reflex, the better!
                    </li>
                    <li>
                      Employee-Centric - Create a cutting-edge, scalable, and
                      intelligent HR operating model.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>4</span>
                <h3>HR Transformation</h3>
                <p>
                  HR is a critical aspect of any business, with the optimal HR
                  process offering its employees the best conditions to perform
                  their job to their full potential. Create a daring HR strategy
                  and transform your staff. Encourage the transition from
                  analogue to digital while reimagining tools and procedures to
                  prepare for a cognitive future. Obtain great personnel and
                  keep them on board as committed workers that prioritize your
                  clients and support your business. Embrace agility, get your
                  business ready for the future, cut expenses, and switch to a
                  dynamic operating style. Leverage the vital roles of
                  recruiter, consultant, and team manager to set your business
                  up for growth and sustain peak performance
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/HR & Staffing/4.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Process*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default TechConsulting;
