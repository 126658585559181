import React from 'react'
import Navbar from './Navbar'
import { Link } from 'react-router-dom'
import Poptin_Contact_Form from './Poptin_Contact_Form'
function ContactUs() {
    return (
        <div>
        <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-6">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 v-center">
                  <div className="bread-inner">
                    <div className="bread-menu text-center wow fadeInUp" data-wow-delay=".2s">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><a href="#">Contact</a></li>
                      </ul>
                    </div>
                    <div className="bread-title wow fadeInUp" data-wow-delay=".5s">
                      <h2 className="text-white text-center">Contact</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Breadcrumb Area*/}
        {/*Start Enquire Form*/}
        <section className="contact-page pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 contact2dv">
                <div className="info-wrapr">
                  <h3 className="mb-4">Contact us</h3>
                  <div className="dbox d-flex align-items-start">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <i className="fas fa-map-marker" />
                    </div>
                    <div className="text pl-4">
                      <p><span>Address:</span>#14, Jambulingam Street (Tank Bund Road view bulding) Nungambakkam, Chennai 600034.</p>
                    </div>
                  </div>
                  <div className="dbox d-flex align-items-start">
                    {/* <div className="icon d-flex align-items-center justify-content-center">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="text pl-4">
                      <p><span>Phone:</span> <a href="tel://+91 9176 45 4455">+91 9176 45 4455</a></p>
                   
                    </div> */}
                    <div className="icon d-flex align-items-center justify-content-center">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="text pl-4">
                      <p><span>Telephone:</span> <a href="tel://04445550245">044 4555 0245</a></p>
                   
                    </div>
                 
                  </div>
                  <div className="dbox d-flex align-items-start">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <i className="fas fa-envelope" />
                    </div>
                    <div className="text pl-4">
                      <p><span>Email:</span> <a href="mailto:mercyitcorp@gmail.com">info@mercyitcorp.com</a></p>
                    </div>
                  </div>
                  <div className="dbox d-flex align-items-start">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <i className="fa fa-globe" />
                    </div>
                    <div className="text pl-4">
                      <p><span>Website</span> <a href="javascript:void(0)">www.mercyitcorp.com</a></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={` col-lg-7 m-mt30 mr-0`}>
                  <div className="form-block">
                    <Poptin_Contact_Form /> 
                  </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Enquire Form*/}
        {/*Start Location*/}
        <div className="contact-location">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="map-div">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.6484919583954!2d80.2388383498009!3d13.058032190754078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52674dac8d6ba3%3A0x1cae087c2fd803f!2sMercy%20IT%20Corp!5e0!3m2!1sen!2sin!4v1628687052746!5m2!1sen!2sin" allowFullScreen aria-hidden="false" tabIndex={0} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Location*/}
        </div>
    )
}

export default ContactUs
