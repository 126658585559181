import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../About/Navbar'

function EmailMarketing() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
        <div>
            <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/9.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">Email Marketing That Boosts The Sales and Reach </h1>
                  <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Connect better with your clients with our Email Marketing startegies</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Start About*/}
        <section className="service  bg-gradient5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="image-block wow fadeIn">
                  <img src="assets/images/service/EmailMarketing.png" alt="image" className="img-fluid no-shadow" />
                </div>
              </div>
              <div className="col-lg-8 block-1">
                <div className="common-heading text-l pl25">
                  <span>Overview</span>
                  <h2>Email Marketing Has The Simplest Conversion Than The Other Marketing Strategy.</h2>
                  <p>Email marketing reigns supreme when it comes to marketing. And it is proven that this method has a higher ROI than the other marketing technologies. It is used to drive sales, create brand awareness, keep your customers engaged and build trust and loyalty. It is a form of direct connection with your customers as emails can be personalized and you don’t need to worry much about algorithms as in SEO. We offer the best email marketing strategies and guide you in sending emails that promote your services and products to the right audience. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        {/*Start Key points*/}
        <section className="service pad-tb light-dark">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="text-l service-desc- pr25">
                  <h3 className="mb20">Email Marketing Services Proven to Increase Leads, Sales, &amp; Revenue</h3>  
                  <p>Our team of marketing experts is here to help you find the right marketing strategy for your business and 
                    increase the number of subscribers. Right from the start, our team will guide you through the process of
                     mastering the king of marketing technology. </p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt30">Request A Quote  <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="servie-key-points">
                  <h4>Advantages of Email Marketing</h4>
                  <ul className="key-points mt20">
                    <li>It is a Symbol of Trust</li>
                    <li>Personalized Content</li>
                    <li>Gives You Control </li>
                    <li>Build Your Client Base</li>
                    <li>Scalable</li>
                    <li>Best Time Email Delivery</li>
                    <li>Keeping Your Clients Engaged</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Key points*/}
        {/*Start Service*/}
        <section className="service-block bg-gradient6 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading ptag">
                  <span>Service</span>
                  <h2>Our Services</h2>
                  <p className="mb30">We provide services that well suit your business needs.</p>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Email List.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Email List Management</h4>
                    <p>First, We Create The E-Mail List Supported Your Business Niche. We Manage The E-Mail List With 100% Accuracy. We Filter All The Duplicate Email Addresses On The E-Mail List.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Google Analytics.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Google Analytics Integration</h4>
                    <p>In our approach to email marketing, we integrate Google Analytics with email marketing campaigns.  It Helps To Trace The Entire Numbers Of Visits, Goal Completion.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Expanding.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Expanding</h4>
                    <p>Our email marketing allows you to maintain better relationships with existing clients and keep them updated and also help in bringing in potential clients.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Positive Connections.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Positive Connections</h4>
                    <p>Email Marketing Is Best Known For Its Roi. It Always Provides The Very Best Return On Your Investment Than The Other Digital Marketing Channels. Now Each Day Email Marketing Isn't Just Supported The Text.</p>
                  </div></div>
              </div>
            </div>
            <div className="-cta-btn mt70">
              <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.1s">
                <p>Hire a <span>Marketing Specialist</span></p>
                <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <Link onClick={timer} to="/contact-us" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or call us now <a href="tel:+91 9176454455"><i className="fas fa-phone-alt" />+91 9176454455</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default EmailMarketing
