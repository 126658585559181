import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";



function Blogfullcontent() {


  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section className="breadcrumb-area banner-5">
        <div className="text-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 v-center">
                <div className="bread-inner">
                  <div className="bread-menu">
                    <ul>
                     
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="bread-title">
                    <h2>Blog Details</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     

      {/*End Breadcrumb Area*/}
      {/*Start Case Study About*/}
      <section className="case-study py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block upset">
                <img
                  src="assets/images/blog-images/digital.jpg"
                  alt="case study"
                 
                  width="50%"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1 v-center">
              <div className="common-heading inner-heading text-l pl25">
                <span>About The Blog</span>
                <h2 className="uppercase">Step up your business by making it digitalized</h2>
                <p className="cont">
                There is no denying that we are living in an era of technology revolution where every little
thing that we were doing manually has been digitalized and with the continuous growth of new
technologies, many businessess have upped their game using this as an added advantage to
broaden their horizons and which in return enables higher ROI. We at Mercy IT Corp will help
you achieve those dreams. We provide solutions that are efficient and simple yet highly
effective which gives your business the right progress in the right direction.
                </p>
              </div>
              
            </div>

            <div className="col-lg-12 py-3">  

    <p>
    We as a team of experienced and highly professional individuals know the importance to
revolutionise digital way of business. The need to take your business to digital mode is
essential today because not only it makes the work easy and smooth but also it is highly cost
efficient. It improves customer experience which provides the needed boost for your growth. It
is proven statistically that 71% of companies have implemented digitalization and that has
increased their revenue directly linked with the transformation techniques.
                </p>
            </div>
            <div className="col-lg-12">  
<h6>The benefits of digitalization of business are various and the most important of them are:</h6>
<p>
1. Cost reduction: Day-to-day tasks become easier while maintaining high quality and less time consuming.<br/>
2. Advanced security: With emerging technologies like block chain there is lesser chances of data breach and hacking.<br/>
3. Solutions for complex problems: AI is the future of digital learning, with softwares that provide adept solution within limited time there are numerous advantages besides reducing the time to find the best solutions.


            </p>

<h6>It is obvious that digitalizing your business can help you way better in reaching your goals and strategise them as per your needs</h6>

        </div>
          </div>
        </div>
      </section>
     
    </div>
  );
}

export default Blogfullcontent;
