import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../About/Navbar'

function CmsWebsite() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
        <div>
        <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/6.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">CMS Website Development</h1>
                  <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Are you looking for the best CMS web development company? We are the right choice</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Start About*/}
        <section className="service pad-b  ">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 px-5 ">
                <div className="image-block upset bg-shape wow fadeIn">
                  <img src="assets/images/about/cms-website.png" alt="image" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-8 block-1">
                <div className="common-heading text-l pl25">
                  <span>Overview</span>
                  <h2>CMS Website Development Service</h2>
                  <p>Customise your content with CMS that is cost effective and will enable you to display the content the way you want on 
                    your website. Unlike HTML, this is easy to build, maintain and to upload content without the need to write a code.
                     We offer customized and a branded layout which helps you to have a website that is professional, with a touch of 
                     personal style. With CMS it has become easier than ever to update content even without the knowledge of coding. 
                     We are here to help you design the perfect website.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        {/*Start Tech*/}
        <div className="techonology-used-">
          <div className="container">
            <ul className="h-scroll tech-icons">
              <li><a href="#"><img src="assets/images/icons/stack-icon1.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon2.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon3.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon4.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon5.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon6.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon7.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/stack-icon8.png" alt="icon" /></a></li>
            </ul>
          </div>
        </div>
        {/*End Tech*/}
        {/*Start Service*/}
        <section className="service-block bg-gradient6 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading ptag">
                  <span>Service</span>
                  <h2>CMS Services</h2>
                  <p className="mb30">We provide services that well suit your business needs.</p>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
                <div className="s-block">
                  <div className="s-card-icon"><img src="assets/images/icons/logo-and-branding.svg" alt="service" className="img-fluid" /></div>
                  <h4>Wordpress development</h4>
                  <p>WordPress is an open-source platform, used to create enticing site pages and blogs and makes content management simple. </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
                <div className="s-block">
                  <div className="s-card-icon"><img src="assets/images/icons/service2.svg" alt="service" className="img-fluid" /></div>
                  <h4>WooCommerce development</h4>
                  <p> WooCommerce is a powerful open-source e-commerce plugin for WordPress that comes with a built-in content management system. </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
                <div className="s-block">
                  <div className="s-card-icon"><img src="assets/images/icons/service3.svg" alt="service" className="img-fluid" /></div>
                  <h4>Joomla</h4>
                  <p>Joomla is an open-source content management system that is mobile- ready. It has thousands of designs and features.</p>
                </div>
              </div>
            </div>
            <div className="-cta-btn mt70">
              <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.3s">
                <p>Hire a <span>Dedicated Developer</span></p>
                <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        {/*Start Process*/}
        <section className="service-block bg-gradient6 pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading ptag">
                <span>Process</span>
                <h2>Our Web Development Process</h2>
                <p>
                  Our design process follows a proven approach. We start with a
                  deep understanding of your needs and create a layout model.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset link-hover  ">
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock  s-block-height">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/research.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                  <h4>1. Analyze</h4>
                  <p>
                  We start our web development process with in-depth analysis of what the client needs.

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Planning.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>2. Plan</h4>
                  <p>
                  Based on the information gathered in step 1, we plan and look at the resources we need, wireframing, site architecture, 
                  functional requirements, sitemap, content, timeline and budget.
                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Prototype.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>3. Customization</h4>
                  <p>
                  Along with design we customise the website according to the client requirements.
                  </p>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Testing and Launch.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>4. Testing & Launch</h4>
                  <p>
                  Once the website is developed, we test it for bugs, functionality and accessibility. 
                  With approval from the client the website is launched.

                  </p>
                
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt30 wow fadeInUp" data-wow-delay=".4s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Maintenance.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>5. Post Launch Maintenance</h4>
                  <p>
                  A website needs post launch maintenance to ensure seamless experience and any updation based on end-user feedback.	
                  </p>
               
                </div>
              </div>
            </div>
           
        
          </div>
        </div>
      </section>
        {/*End Process*/}
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <Link onClick={timer} to="/contact-us" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or call us now <a href="tel:+91 9176454455"><i className="fas fa-phone-alt" />+91 9176454455</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default CmsWebsite
