import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function TechConsulting() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  Digital Transformation
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/service/dt.png"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>Digital Transformation</h2>
                <p>
                  Customer-driven and digital-first thinking is applied to every
                  area of an organization, including its business models,
                  customer experiences, procedures, and operations. To use data
                  and drive intelligent processes, quicker and wiser
                  decision-making, and real-time responsiveness to market
                  disruptions, it uses AI, automation, hybrid cloud, and other
                  digital technologies. Finally, it modifies consumer
                  expectations and generates fresh commercial prospects.
                </p>
                <div className="servie-key-points">
                  <h4>Digital Transformation Drivers</h4>
                  <p>
                    The main forces behind the digital transformation have
                    always been the demands of the consumer. It started when a
                    flurry of new technologies made novel knowledge and talents
                    available in novel contexts, including:
                  </p>
                  <ul className="key-points mt20">
                    <li>Mobile gadgets</li>
                    <li>The Internet</li>
                    <li>"Internet of Things" (IoT)</li>
                    <li>Utilizing the cloud</li>
                  </ul>
                </div>
                <div className="servie-key-points">
                  <h4>Technologies that are changing:</h4>
                  <p>
                    In an organization's digital transformation strategy,
                    practically any digital technology can be used. However, the
                    following technologies appear to be crucial both now and in
                    the near future
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Digital Transformation/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>Artificial intelligence and Automation</h3>
                <p>
                  The powers of the human mind can be imitated by a computer or
                  machine thanks to artificial intelligence (AI) technologies
                  like machine learning. AI picks up skills from examples via
                  identifying objects, making choices, and more. AI can provide
                  intelligence and real-time decision-making to any workflow
                  when paired with automation. It may power a variety of things,
                  including cutting-edge smart devices, experiences for
                  customers and users that are increasingly individualized, and
                  improved supply chain management, change management, and other
                  workflows.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>Hybrid cloud</h3>
                <p>
                  Using orchestration, administration, and application
                  portability, a hybrid cloud is a cloud computing
                  infrastructure that links on-premises IT, public cloud, and
                  private cloud resources. Hybrid cloud offers the agility,
                  scalability, and resilience needed for long-term success in
                  digital transformation by generating a single, flexible, ideal
                  cloud for running every workload and preventing an
                  organization from being locked into a single platform or
                  provider.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Digital Transformation/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Digital Transformation/3.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>Microservices</h3>
                <p>
                  In a cloud-native architecture known as microservices, several
                  applications are made up of loosely connected, independently
                  deployable components. Microservices act as a catalyst for
                  digital disruption or as a tool to thwart it, in conjunction
                  with Agile or DevOps approaches. It allows businesses to roll
                  out new software or product features every day, or
                  occasionally hundreds or thousands of times.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>4</span>
                <h3>Blockchain</h3>
                <p>
                  A distributed, permanent, and unchangeable ledger or record of
                  electronic transactions is called a blockchain. For those who
                  need it, blockchain offers complete transaction transparency;
                  for those who don't, it is unavailable. Blockchain is being
                  used by businesses as the basis for the transformation of
                  cross-border financial services and extremely resilient supply
                  chains.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Digital Transformation/4.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        
        </div>
      </section>
      {/*End Process*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default TechConsulting;
