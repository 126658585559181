import React from 'react'
import AboutUs from './AboutUs'
import BreadCrumb from './BreadCrumb'
import Navbar from './Navbar'

function About() {
    return (
        <div>
        <Navbar />
        <BreadCrumb />
        <AboutUs />
        </div>
    )
}

export default About
