import React from 'react'
import Navbar from '../About/Navbar'

function ShopDetails() {
    return (
        <div>
        <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-2" data-background="assets/images/banner/4.jpg">
          <div className="text-block">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 v-center">
                  <div className="bread-inner">
                    <div className="bread-menu">
                      <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><a href="#">Theme</a></li>
                        <li><a href="#">HTML Template</a></li>
                      </ul>
                    </div>
                    <div className="bread-title">
                      <h2>Niwax - Creative Agency &amp; Portfolio HTML Template</h2>
                      <p className="mt10">Multipurpose Landing Page HTML Template</p>
                      <div className="btn-grp mt40">
                        <a href="#" className="btn-main bg-btn lnk">Live Preview <i className="fas fa-share" /> <span className="circle" /></a>
                        <a href="#" className="btn-main bg-btn3 lnk">Buy Now <i className="fas fa-shopping-cart" /> <span className="circle" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end Breadcrumb Area*/}
        {/*shop products*/}
        <section className="shop-products-prvw pt20 pb60">
          <div className="container shop-container">
            <div className="row">
              <div className="col-lg-8">
                <div className="rpb-shop-prevw">
                  <img src="assets/images/shop/item-perview.jpg" className="w-100" alt="img" />
                </div>
                <div className="rpb-item-info">
                  <div className="tab-17 tabs-layout">
                    <ul className="nav nav-tabs" id="myTab3" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active" id="tab1a" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Item Details</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="tab2b" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Review</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="tab3c" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">Comments</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="tab4c" data-toggle="tab" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false">Support</a>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent2">
                      <div className="mt20 tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1a">
                        <h4 className="mb10">Description</h4>              
                        <p className="mb30">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. is simply dummy text of the printing and typesetting industry.</p>
                        <h4 className="mb10">24-Hour Support:</h4> 
                        <ul className="ul-list mb30">
                          <li>Quick, Dedicated &amp; Professional Support</li>
                          <li>Please send us an email to rajeshdoot@hotmail.com with your details query. Thanks!</li>                
                        </ul>
                        <h4 className="mb10">Template Features</h4> 
                        <ul className="ul-list mb30">
                          <li>Powered by Bootstrap</li>
                          <li>Well documented codes</li>
                          <li>Fully Responsive</li>
                          <li>Free Google Fonts</li>
                          <li>Owl Carousel 2</li>
                          <li>Font Awesome Icons</li>
                          <li>Magnific Popup</li>
                          <li>Working Contact Form with PHP with validation</li>
                          <li>Home Particles Effect</li>
                          <li>W3C validated HTML and CSS code</li>
                          <li>Cross Browser Compatible</li>
                          <li>Free Updates</li>
                        </ul> 
                      </div>
                      <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2b">
                        <div className="rpb-item-review">
                          <div className="reviews-card">           
                            <div className="review-text pt0 pb20">
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                            <div className="-client-details-">
                              <div className="-reviewr">
                                <img src="assets/images/client/reviewer-c.jpg" alt="Good Review" className="img-fluid" />
                              </div>
                              <div className="reviewer-text">
                                <h4><small>By: </small>Anna Sthesia</h4>
                                <p>20 Jan 2021</p>
                                <div className="star-rate">
                                  <ul>
                                    <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                                    <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                                    <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                                    <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                                    <li> <a href="javascript:void(0)"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="reviews-card">           
                            <div className="review-text pt0 pb20">
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                            <div className="-client-details-">
                              <div className="-reviewr">
                                <img src="assets/images/client/reviewer-c.jpg" alt="Good Review" className="img-fluid" />
                              </div>
                              <div className="reviewer-text">
                                <h4><small>By: </small>Anna Sthesia</h4>
                                <p>20 Jan 2021</p>
                                <div className="star-rate">
                                  <ul>
                                    <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                                    <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                                    <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                                    <li> <a href="javascript:void(0)" className="chked"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                                    <li> <a href="javascript:void(0)"><i className="fas fa-star" aria-hidden="true" /></a> </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>	
                      </div>
                      <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3c">
                        <div className="rpb-commentss comments-block">
                          <div className="media">
                            <div className="user-image"><img src="assets/images/user-thumb/user3.jpg" alt="girl" className="img-fluid" /></div>
                            <div className="media-body user-info">
                              <h5 className="mb10">Petey Cruiser <small className="badges badge-success">PURCHASED:</small>
                                <span>
                                  November 29, 2019 <a className="reply-btn" href="#"><i className="fas fa-reply" /></a>
                                </span>
                              </h5>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500.</p>
                            </div>
                          </div>
                          <div className="media replied">
                            <div className="user-image"><img src="assets/images/user-thumb/user3.jpg" alt="girl" className="img-fluid" /></div>
                            <div className="media-body user-info">
                              <h5 className="mb10">Tom Mikee <small className="badges badge-success">Author:</small>
                                <span>
                                  November 29, 2019 <a className="reply-btn" href="#"><i className="fas fa-reply" /></a>
                                </span>
                              </h5>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever.</p>
                            </div>
                          </div>
                        </div>	
                        <div className="rpb-comment-form">
                          <div className="form-block form-blog mt40">
                            <form action="#" method="post" name="#">
                              <div className="fieldsets row">
                                <div className="col-md-6"><input type="text" placeholder="Name" name="#" /></div>
                                <div className="col-md-6"><input type="email" placeholder="Email" name="#" /></div>
                              </div>                 
                              <div className="fieldsets"><textarea placeholder="Write Your Comment" name="#" defaultValue={""} /></div>
                              <div className="fieldsets mt10">
                                <button type="submit" name="#" className="btn-main bg-btn3 lnk">Post Comment <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></button>
                              </div>
                            </form>
                          </div>
                        </div>	
                      </div>
                      <div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4c">
                        <div className="rpb-itm-support-txt">
                          <h4>Contact Us</h4>
                          <p>Lorem Ipsum is simply dummy text of the printing and through email contact form.</p>
                          <h4 className="mt30 mb10">Item support includes:</h4>
                          <ul className="ul-list mb30">
                            <li>Powered by Bootstrap</li>
                            <li>Well documented codes</li>
                            <li>Fully Responsive</li>
                            <li>Free Google Fonts</li>
                          </ul>
                          <a href="#">View the item support policy</a>
                          <div className="btns">
                            <a href="#" className=" mt30 btn-main bg-btn3 lnk">Get Support <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></a>
                          </div>
                        </div>		
                      </div>
                    </div>
                  </div>
                </div>	
              </div>
              <div className="col-lg-4">
                <div className="rpb-item-infodv">
                  <ul>
                    <li className="price">
                      <strong>Price</strong>
                      <div className="nx-rt">										
                        <div className="rpb-itm-pric"> <span className="offer-prz"> $17 </span> <span className="regular-prz">$25</span> </div>
                      </div>
                    </li>
                    <li>
                      <strong>Last Update</strong>
                      <div className="nx-rt">Jan 18, 2021</div>
                    </li>
                    <li>
                      <strong>Created</strong>
                      <div className="nx-rt">Jan 10, 2021</div>
                    </li>
                    <li>
                      <strong>High Resolution</strong>
                      <div className="nx-rt">Yes</div>
                    </li>
                    <li>
                      <strong>Columns</strong>
                      <div className="nx-rt">4+</div>
                    </li>
                    <li>
                      <strong>Layout</strong>
                      <div className="nx-rt">Responsive</div>
                    </li>
                    <li>
                      <a href="#" className="btn-main bg-btn lnk w-100">Add to Cart <i className="fas fa-shopping-cart" /> <span className="circle" /> </a>
                      <a href="#" className="btn-main bg-btn3 lnk w-100 mt10">Buy Now <span className="circle" /> </a>
                    </li>
                  </ul>
                </div>
                <div className="rpb-item-infodv">
                  <h4 className="mb20">Item tags</h4>
                  <div className="tabs">
                    <a href="#">webdesign</a>
                    <a href="#">design </a>
                    <a href="#">graphicdesign </a>
                    <a href="#">website </a>
                    <a href="#">marketing </a>
                    <a href="#">branding </a>
                    <a href="#">webdevelopment </a>
                    <a href="#">webdesigner </a>
                    <a href="#">graphicdesign </a>
                    <a href="#">website </a>
                    <a href="#">marketing </a>
                    <a href="#">branding </a>
                  </div>
                </div>	
              </div>
            </div>
          </div>
        </section>
        </div>
    )
}

export default ShopDetails
