import React from 'react'

function WorkCategory() {
    return (
        <div>
            {/*Start work-category*/}
        <section className="work-category pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading ptag">
                  <span>We Have Worked Across Multiple Industries</span>
                  <h2>Our Expertise and Service</h2>
                  <p>Thirst for continuous growth encouraged us to deliver successful digital products and services to various companies that comprise the broad spectrum of industries.</p>
                </div>
              </div>
            </div>
            <div className="row mt30">
              <div className="col-lg-3 col-sm-6 col-6 wow fadeIn" data-wow-delay="0.1s"> <div className="industry-workfor hoshd"><img src="assets/images/icons/house.svg" alt="img" /> <h6>Real estate</h6> </div></div>
              <div className="col-lg-3 col-sm-6 col-6 wow fadeIn" data-wow-delay="0.3s"> <div className="industry-workfor hoshd"><img src="assets/images/icons/travel-case.svg" alt="img" /> <h6>Tour &amp; Travels</h6> </div></div>
              <div className="col-lg-3 col-sm-6 col-6 wow fadeIn" data-wow-delay="0.5s"> <div className="industry-workfor hoshd"><img src="assets/images/icons/video-tutorials.svg" alt="img" /> <h6>Education</h6> </div></div>
              <div className="col-lg-3 col-sm-6 col-6 wow fadeIn" data-wow-delay="0.7s"> <div className="industry-workfor hoshd"><img src="assets/images/icons/taxi.svg" alt="img" /> <h6>Transport</h6> </div></div>
              <div className="col-lg-3 col-sm-6 col-6 wow fadeIn" data-wow-delay="0.9s"> <div className="industry-workfor hoshd"><img src="assets/images/icons/event.svg" alt="img" /> <h6>Event</h6> </div></div>
              <div className="col-lg-3 col-sm-6 col-6 wow fadeIn" data-wow-delay="1.1s"> <div className="industry-workfor hoshd"><img src="assets/images/icons/smartphone.svg" alt="img" /> <h6>eCommerce</h6> </div></div>
              <div className="col-lg-3 col-sm-6 col-6 wow fadeIn" data-wow-delay="1.3s"> <div className="industry-workfor hoshd"><img src="assets/images/icons/joystick.svg" alt="img" /> <h6>Game</h6> </div></div>
              <div className="col-lg-3 col-sm-6 col-6 wow fadeIn" data-wow-delay="1.5s"> <div className="industry-workfor hoshd"><img src="assets/images/icons/healthcare.svg" alt="img" /> <h6>Healthcare</h6> </div></div>
              <div className="col-lg-3 col-sm-6 col-6 wow fadeIn" data-wow-delay="1.7s"> <div className="industry-workfor hoshd"><img src="assets/images/icons/money-growth.svg" alt="img" /> <h6>Finance</h6> </div></div>
              <div className="col-lg-3 col-sm-6 col-6 wow fadeIn" data-wow-delay="1.9s"> <div className="industry-workfor hoshd"><img src="assets/images/icons/baker.svg" alt="img" /> <h6>Restaurant</h6> </div></div>
              <div className="col-lg-3 col-sm-6 col-6 wow fadeIn" data-wow-delay="2.1s"> <div className="industry-workfor hoshd"><img src="assets/images/icons/mobile-app.svg" alt="img" /> <h6>On-Demand</h6> </div></div>
              <div className="col-lg-3 col-sm-6 col-6 wow fadeIn" data-wow-delay="2.3s"> <div className="industry-workfor hoshd"><img src="assets/images/icons/groceries.svg" alt="img" /> <h6>Grocery</h6> </div></div></div>
          </div>
        </section>
        {/*End  work-category*/}
        </div>
    )
}

export default WorkCategory
