import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, { Pagination, Autoplay } from "swiper/core";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

export default function SwiperCarousel() {
  return (
    <>
        <div className="weworkfor pt20 pb20">
        <div className="container">
        <Swiper
            slidesPerView={6}
            loop={true}
            freeMode={true}
            spaceBetween={10}
            autoplay={{
            delay: 3500,
            disableOnInteraction: false
            }}
            className="mySwiper"
        >
            <SwiperSlide><div className="items"><img src="assets/images/client/1.png" alt="niwax html template" className="img100w" /></div></SwiperSlide>
            <SwiperSlide><div className="items"><img src="assets/images/client/2.png" alt="niwax html template" className="img100w" /></div></SwiperSlide>
            <SwiperSlide><div className="items"><img src="assets/images/client/3.png" alt="niwax html template" className="img100w" /></div></SwiperSlide>
            <SwiperSlide><div className="items"><img src="assets/images/client/4.png" alt="niwax html template" className="img100w" /></div></SwiperSlide>
            <SwiperSlide><div className="items"><img src="assets/images/client/5.png" alt="niwax html template" className="img100w" /></div></SwiperSlide>
            <SwiperSlide><div className="items"><img src="assets/images/client/6.png" alt="niwax html template" className="img100w" /></div></SwiperSlide>
            <SwiperSlide><div className="items"><img src="assets/images/client/7.png" alt="niwax html template" className="img100w" /></div></SwiperSlide>
            <SwiperSlide><div className="items"><img src="assets/images/client/8.png" alt="niwax html template" className="img100w" /></div></SwiperSlide>
        </Swiper>
        </div>
        </div>
    </>
  );
}
