import React from 'react'
import Navbar from './Navbar'
import { Link } from 'react-router-dom'

function CaseStudy() {
    return (
        <div>
        <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-5">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 v-center">
                  <div className="bread-inner">
                    <div className="bread-menu text-center">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="#">Portfolio</Link></li>
                      </ul>
                    </div>
                    <div className="bread-title">
                      <h2 className="text-white text-center">Portfolio</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Breadcrumb Area*/}
        {/*Start Case Study*/}
        <section className="case-study pad-b pt0">
          <div className="container">
          <div className="common-heading ptag ">
                
                <h2 style={{textAlign:'left', marginTop:'50px'}}>Clients</h2><hr></hr>
                
              </div>
            <div className="row justify-content-center px-4  ">
              
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='https://lifecarepharma.com/' target="_blank"><img src="assets/images/case-study/lifecare.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><Link to="#">Lifecare Pharms</Link></h4>

                    <p>MERN Stack | Web & Mobile Application</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='https://etabx.com/' target="_blank"><img src="assets/images/case-study/etabx.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><Link to="#">Etabx</Link></h4>

                    <p>Single Vendor Ecommerce </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='https://surelifepharma.co/' target="_blank"><img src="assets/images/case-study/surelife.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><Link to="#">Surelife Healthcare</Link></h4>

                    <p>Wordpress | Web Application</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='https://pappadeals.com/' target="_blank"><img src="assets/images/case-study/pappadeals.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><Link to="#">Pappa Deals</Link></h4>

                    <p>Single Vendor Ecommerce</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='https://miaappliances.com/' target="_blank"><img src="assets/images/case-study/mia.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><Link to="#">Mia Appliances</Link></h4>

                    <p>Single Vendor Ecommerce</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='https://mytijarath.com/' target="_blank"><img src="assets/images/case-study/mytijarath.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><Link to="#">My Tijarath</Link></h4>

                    <p>Laravel | Digital Business Card & Mini Ecommerce</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='https://avon.mercyitcorp.com/' target="_blank"><img src="assets/images/case-study/avon.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><Link to="#">AVON</Link></h4>

                    <p>MERN Stack | POS</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='https://uniqueforce360.com/' target="_blank"><img src="assets/images/case-study/uniqueforce.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><Link to="#">Unique Force 360</Link></h4>

                    <p>Wordpress | Ecommerce</p>
                  </div>
                </div>
              </div>
           
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='https://www.visanook.com/' target="_blank"><img src="assets/images/case-study/visanook.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><Link to="#">Visa Nook</Link></h4>

                    <p>Wordpress | Ecommerce</p>
                  </div>
                </div>
              </div>
            
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='#' target="_blank"><img src="assets/images/case-study/sultan.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><Link to="#">Sultan Insulations</Link></h4>

                    <p>Wordpress | Web Application</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="https://foodtrack.co.in/" target="_blank" ><img src="assets/images/case-study/foodtrack.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="#">Food Track</a></h4>

                    <p>React Js | Resturant Management</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="https://www.zaeimsinfinity.com/" target="_blank" > <img src="assets/images/case-study/client2.jpg" alt="case-study" className="img-fluid" /> </a></div>
                  <div className="info-text-block">
                    <h4><a href="#">Zaeims Infinity</a></h4>

                    <p>PHP | Web Application</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="https://se-ms.co/" target="_blank" ><img src="assets/images/case-study/client3.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="https://se-ms.co/">South East Management</a></h4>

                    <p>Wordpress | Web Application</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="https://hgwc.in/" target="_blank" ><img src="assets/images/case-study/client4.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="https://hgwc.in/">HGWC</a></h4>

                    <p>Wordpress | Web Application</p>
                  </div>
                </div>
              </div>
             
               
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='#' target="_blank" ><img src="assets/images/case-study/client6.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href='#'>Pole to Pole Tours & Travels</a></h4>

                    <p>Wordpress | Travel Agency Application</p>
                  </div>
                </div>
              </div>
            
              
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="#"><img src="assets/images/case-study/client9.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="#">The Humanity Conference</a></h4>

                    <p>Digital Marketing | Graphic Design</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='#'><img src="assets/images/case-study/client10.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href='#'>Pinkdd Scarf</a></h4>

                    <p>Digital Marketing | Graphic Design</p>
                  </div>
                </div>
              </div>
              
             
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="#" target="_blank"><img src="assets/images/case-study/client15.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="#">PS Informatics</a></h4>

                    <p>Digital Marketing | Graphic Design</p>
                  </div>
                </div>
              </div>
             
              
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="https://studio-black.co.in/" target="_blank"><img src="assets/images/case-study/client16.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="https://studio-black.co.in/">Studio Black</a></h4>

                    <p>Digital Marketing | Graphic Design</p>
                  </div>
                </div>
              </div> 
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="https://meatism.in/" target="_blank" ><img src="assets/images/case-study/client5.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="https://meatism.in/">Meatism</a></h4>

                    <p>Single Vendor Ecommerce</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="https://mercyholdings.in/" target="_blank" ><img src="assets/images/case-study/mercygroups.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="https://mercyholdings.in/">Mercy Group of Comapnies</a></h4>

                    <p>Web Application</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6  ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="https://www.mercyshelters.com/" target="_blank" ><img src="assets/images/case-study/client7.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="https://www.mercyshelters.com/">Mercy Shelters</a></h4>

                    <p>PHP | Web Application & Digital Marketing</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="https://www.mercytourism.com/" target="_blank" ><img src="assets/images/case-study/client13.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="https://www.mercytourism.com/">Mercy Tourism</a></h4>

                    <p>Wordpress | Travel Agency Application </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="https://ecom.mercyitcorp.com/" target="_blank" ><img src="assets/images/case-study/mercyecom.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="https://ecom.mercyitcorp.com/">Mercy Ecom</a></h4>

                    <p>Multivendor Ecommerce</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="http://www.academy.mercyitcorp.com/" target="_blank" ><img src="assets/images/case-study/mercyacademy.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="http://www.academy.mercyitcorp.com/">Mercy Academy</a></h4>

                    <p>Wordpress | Web Application</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="https://minterios.com/" target="_blank" ><img src="assets/images/case-study/minterios.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="https://minterios.com/">Minterios</a></h4>

                    <p>PHP | Web Application & Digital Marketing</p>
                  </div>
                </div>
              </div>
            
              
              
              </div>

            
          <div className="common-heading ptag ">
                
                <h2 style={{textAlign:'left', marginTop:'50px'}}>International Clients</h2><hr></hr>
                
              </div>
              <div className="row justify-content-center px-4  ">
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='https://shift.edu.sa/' target="_blank"><img src="assets/images/case-study/shift.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="https://www.shamesalsaduq.com/">SHIFT Institute </a></h4>

                    <p>Moodle | LMS Learning Management Software</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='https://www.shamesalsaduq.com/' target="_blank"><img src="assets/images/case-study/shams.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="https://www.shamesalsaduq.com/">Shams Al Saduq Company</a></h4>

                    <p>Digital Marketing | Graphic Design</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='https://nazlaw.ca/' target="_blank"><img src="assets/images/case-study/nazlaw.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><Link to="/case-study-details">NazLaw</Link></h4>

                    <p>Wordpress | Ecommerce</p>
                  </div>
                </div>
              </div>
             
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href='#' target="_blank"><img src="assets/images/case-study/devraha.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href='#'>Devraha</a></h4>

                    <p>Wordpress | Single Vendor Ecommerce</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="#" target="_blank"><img src="assets/images/case-study/client12.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="#">Golden Bistro</a></h4>

                    <p>Digital Marketing | Graphic Design</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="full-image-card2 hover-scale">
                  <div className="image-div"><a href="#" target="_blank"><img src="assets/images/case-study/client11.jpg" alt="case-study" className="img-fluid" /></a></div>
                  <div className="info-text-block">
                    <h4><a href="#">D'Mart Travels & Tour</a></h4>

                    <p>Digital Marketing | Graphic Design</p>
                  </div>
                </div>
              </div>
             
              
              </div>
          </div>
          
        </section>
        {/*End Case Study*/}
        </div>
    )
}

export default CaseStudy
