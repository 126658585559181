import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../About/Navbar'

function SEO() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
        <div>
            <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/9.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">Professional SEO Services That Drive Results</h1>
                  <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Let your business get the attention it deserves with our SEO services.</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Start About*/}
        <section className="service  bg-gradient5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="image-block wow fadeIn">
                  <img src="assets/images/service/digitalmarketing2.png" alt="image" className="img-fluid no-shadow" />
                </div>
              </div>
              <div className="col-lg-8 block-1">
                <div className="common-heading text-l pl25">
                  <span>Overview</span>
                  <h2>SEO Services for Business Growth</h2>
                  <p>Websites strengthen your marketing, then the content of the website needs to be pitch-perfect, isn’t it? We make your content SEO ready with target-specific keywords, meta descriptions, and catchy headlines. Once you let us develop your program optimization strategy with our advanced SEO services, it allows you to specialize in your business/industry while leaving the program optimization strategies and tactics to us. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        {/*Start Key points*/}
        <section className="service pad-tb light-dark">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="text-l service-desc- pr25">
                  <h3 className="mb20">SEO Marketing Services Proven to Increase Leads, Sales, &amp; Revenue</h3>  
                  <p>SEO Marketing is the digital form of “word of mouth” marketing and the obvious known method to increase traffic to your website, to be more precise it can make or break your website. With the ever- changing trend of SEO ranking, it can be hard to use it in the best effective way and that is why we are here to provide SEO services that will improve the ranking of your website in search engines and also convert prospects into clients, thereby helping you grow your business.</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt30">Request A Quote  <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="servie-key-points">
                  <h4>Advantages of SEO in Digital Marketing</h4>
                  <ul className="key-points mt20">
                    <li>Strategize with The Valuable Data and Analytics</li>
                    <li>Content Performance and Lead Generation</li>
                    <li>Reduction in cost and raises standards</li>
                    <li>Improved Conversion Rates</li>
                    <li>More Cost Effective Than Traditional Marketing</li>
                    <li>Higher Revenues</li>
                    <li>Higher ROI from Your Campaigns</li>
                    <li>Earn People’s Trust and Build Brand Reputation</li>
                    <li>Know All About Your Competitors</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Key points*/}
        {/*Start Service*/}
        <section className="service-block bg-gradient6 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading ptag">
                  <span>Service</span>
                  <h2>Our SEO Services</h2>
                  <p className="mb30">We provide services that well suit your business needs.</p>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Keyword Research.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>1. Keyword Research</h4>
                    <p>The content with the right keywords ranks high. Our team uses targeted keywords according to your ideal customers that bring more leads to your business.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/On-Page Optimization.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>2. On-Page Optimization</h4>
                    <p>We optimize the page content with the relevant keywords, meta descriptions, and proper internal links which produces compelling content.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/ppc.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>3. Off-Page Optimization</h4>
                    <p>Watch your website traffic soar high with our expert off-page activities, which involve creating content promotion methods, organic backlinks, and more.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Lead Generation.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>4. Lead Generation</h4>
                    <p>Get organic leads with our technical SEO services and maximize your business with us.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Monthly SEO Reports.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>5. Monthly SEO Reports</h4>
                    <p>We provide insights into our progress and keep track of how we have improved which includes complete market analysis.</p>
                  </div></div>
              </div>
            </div>
            <div className="-cta-btn mt70">
              <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.1s">
                <p>Hire a <span>Marketing Specialist</span></p>
                <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <Link onClick={timer} to="/contact-us" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or call us now <a href="tel:+91 9176454455"><i className="fas fa-phone-alt" />+91 9176454455</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default SEO
