import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../Navbar'


function TeamIndividual10() {
    return (
        <div>
        <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-4">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 v-center">
                  <div className="bread-inner">
                    <div className="bread-menu">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/our-team">Team</Link></li>
                        <li><a href="#">Member Details</a></li>
                      </ul>
                    </div>
                    <div className="bread-title">
                      <h2>Member Details</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Breadcrumb Area*/}
        {/*Start Team Details*/}
        <section className="team pad-tb deep-dark">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-5">
                <div className="image-block upset bg-shape">
                  <div className="image-div"><a href="#"><img src="assets/images/team/team-single.jpg" alt="team" className="img-fluid" /></a></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="full-image-card mt0">
                  <div className="info-text-block">
                    <h4><a href="#">Janna Rother</a></h4>
                    <p>Graphic Desinger</p>
                  </div>
                  <div className="otherinfo">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum is simply dummy text of the printing and typesetting industry. is simply dummy text of the printing and typesetting industry. </p>
                    <div className="social-media-profile">
                      <a href="javascript:void(0)" target="blank"><i className="fab fa-facebook" /></a>
                      <a href="javascript:void(0)" target="blank"><i className="fab fa-twitter" /></a>
                      <a href="javascript:void(0)" target="blank"><i className="fab fa-instagram" /></a>
                      <a href="javascript:void(0)" target="blank"><i className="fab fa-linkedin" /></a>
                      <a href="javascript:void(0)" target="blank"><i className="fab fa-dribbble" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Team Details*/}
        </div>
    )
}

export default TeamIndividual10
