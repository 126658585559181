import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../About/Navbar'

function UiUxDesign() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
        <div>
            <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/4.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">UI/UX Design Services</h1>
                  <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creativity, customer focus, technological innovation at its finest with well-defined design processes at Mercy IT Corp</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/*End Hero*/}
        {/*Start About*/}
        <section className="service  bg-gradient5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                  <img src="assets/images/service/UIUXDesign.png" alt="image" className="img-fluid no-shadow" />
                </div>
              </div>
              <div className="col-lg-8 block-1 m-mt50">
                <div className="common-heading text-l pl25">
                  <span>Overview</span>
                  <h2>Professional UX & Design Services</h2>
                  <p>Great products require great designs and we know how much impact design can have on your website or application. 
                    That is why we create immense designs that are detailed and appealing to the viewer. We provide UI/UX services 
                    that include branding, responsive web design, mobile app design, user experience consulting, and promotional designs 
                    for your business using the top-grade technology tools that create the best-lasting impression.  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        {/*Start Key points*/}
        <section className="service pad-tb light-dark">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="text-l service-desc- pr25">
                  <h3 className="mb20">We use advanced UI/UX designing tools to create impressive web designs for you</h3>
                  <p>Our Designers are creatives who bring in innovation with a mix of passion and technical intelligence. 
                    Our designs impressive and increase your brand value and brand presence. Our UI/UX design team just 
                    creates pixel-perfect designs that can draw anyone’s attention.       </p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt30">Request A Quote  <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="single-image wow fadeIn">
                  <img src="assets/images/service/branding-.jpg" alt="image" className="img-fluid no-shadow" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Key points*/}
        {/*Start Service*/}
        <section className="service-block bg-gradient6 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading ptag">
                  <span>Service</span>
                  <h2>UI/UX Design services we offer</h2>
                  <p className="mb30">We provide services that well suit your business needs.</p>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay=".2s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Custom Web Design.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Custom Web Design</h4>
                    <p>We understand and your business and customize our designs which represent your organizational value in every aspect.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay=".4s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Corporate Branding.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Corporate Branding</h4>
                    <p>With expressive and creative thoughts we create a brand for your business that doesn’t need to introduce itself. We help create authentic brands.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay=".6s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Wireframing.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Wireframing</h4>
                    <p>Our information architects create functional sites that are designed using a visual guide framework to improve the overall user interface and experience.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay=".8s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Mobile App Design.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Mobile App Design</h4>
                    <p>Simplified mobile apps that are easy to use, at the same time a one that is aesthetically pleasing is how we design.</p>
                  </div></div>
              </div>
            </div>
            <div className="-cta-btn mt70">
              <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.4s">
                <p>Hire a <span>UI/UX Designer</span></p>
                <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <Link onClick={timer} to="/contact-us" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or call us now <a href="tel:+919176454455"><i className="fas fa-phone-alt" />+919176454455</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default UiUxDesign
