import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function TechConsulting() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  Robotics - The Sci-fi that brought change
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/Robotics/Robotics.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>Robotics - The Sci-fi that brought change</h2>
                <div className="servie-key-points">
                  <h4>
                    “With fully functional AI-driven robotic process automation,
                    Harness ROI more quickly.”
                  </h4>
                </div>
                <p>
                  Since the 1950s, several industrial processes have been
                  mechanized using traditional robotic systems. Recent
                  developments in artificial intelligence (AI) and computer
                  vision have facilitated a paradigm shift toward intelligent
                  robotic systems that can be integrated into operational and
                  informational systems while extending use cases. It’s safe and
                  exciting to say that robots are not fiction anymore! They are
                  alive, breathing and living among us, they are changing the
                  world as we know it into something far superior & beyond.
                </p>

                {/* <div className="servie-key-points">
                  <h4>Let's talk about tech consulting and why it benefits companies so much. We'll consider:</h4>
                  <ul className="key-points mt20">   
                    <li>Reduce costs</li>
                    <li>Create new revenue streams</li>
                    <li>Streamline their operations</li>
                    <li>Improve customer service</li>
                     
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="container py-5">
          <div className="row">
            <div className="col-lg-8 block-1">
              <div className="servie-key-points">
                <h2>How we do it</h2>
                <h4>
                  Robots as we see today are much more smart than we think. They
                  are adapting to their environments much quicker than humans
                  can! Some of their advanced abilities are
                </h4>
                <ul className="key-points mt20">
                  <li>
                    Communicating & working with humans - a mutual
                    collaboration.
                  </li>
                  <li>
                    Be able to work in dynamic, unstructured contexts -
                    flexible.
                  </li>
                  <li>
                    Analyze data to make continual progress - a step ahead.
                  </li>
                  <li>
                    Using sensors to foresee, analyze and learn from their
                    surroundings - adaptability.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/Robotics/Robotics 2.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "30px !important" }}>
            Our smart robot program consists of
          </h2>

          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Robotics/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>Devising site strategy</h3>
                <p>
                  Developing strategies that prioritize automation options that
                  can boost ROI and pre-engineering workcells to allow for
                  deployment delays. The moment has come to seize automation
                  options that boost ROI. This reduces automation time and cost.
                  Increase capacity, improve productivity, and create new
                  revenue opportunities. Once deployed, the systematic and
                  calculated use of automation becomes an organizational
                  decision that can generate new revenue streams
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>Managing and designing suitable ecosystems</h3>
                <p>
                  Create and maintain a robust ecosystem of vendor connections,
                  reduce implementation costs, meet the need for robots, and
                  create commercial frameworks. A solid vendor ecosystem aids a
                  company in lowering implementation costs and accelerating the
                  adoption of solutions and technologies. A robust vendor
                  ecosystem is of utmost importance to a company that is trying
                  to implement solutions or technologies in new verticals and
                  business processes.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Robotics/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Robotics/3.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>Smart Integration</h3>
                <p>
                  Intelligent robotic work cells that are developed through
                  simulation, prototyping, and integration into corporate
                  processes and create intelligent robotic work cells that are
                  integrated into organizational systems. Our team of
                  professionals uses simulation as a basis to understand the
                  constraints and requirements of a product for improving design
                  iterations and for later decisions about components,
                  materials, and assembly.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>4</span>
                <h3>Execution & Lifecycle</h3>
                <p>
                  Standardize workcells to widen the scope of deployments that
                  are efficient, and provide around-the-clock support for
                  operations and maintenance to ensure performance. Create
                  multiple workcells within a process and deploy them in a
                  continuous flow to:
                </p>
                <ul className="key-points mt20">
                  <li>
                  Increase process flexibility
                  </li>
                  <li>
                  Achieve higher utilization of assets
                  </li>
                  <li>
                  Reduce defects and errors in production
                  </li>
                  <li>
                  Increase product quality and reduce cost
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Robotics/4.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Process*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default TechConsulting;
