import React, { useState,} from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { BiDownArrow, BiRightArrow } from "react-icons/all";
import { Collapse } from "antd";
import Blogsmcontent from "./blogsmallcontent/blogsmcontent1";
import { useHistory } from "react-router-dom";
import Blogsmdefaultcont from "./blogsmallcontent/blogsmdefaultcont";




function Blog() {
  
  const history = useHistory();

  const { Panel } = Collapse;
  // const [newblog, setnewblog] = useState(null);
  const [date, setDate] = useState(null);
  // const [blog, setblog] = useState(null);

 const [state, setstate] = useState("default")

 

  return (
    <div>
      <Navbar />

      {/*Start blog*/}
      <section className="breadcrumb-area banner-5">
        <div className="text-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 v-center">
                <div className="bread-inner">
                  <div className="bread-menu text-center">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="#">Blog</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="bread-title">
                    <h2 className="text-white text-center">Blog</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="case-study pad-b pt0">
        <div className="container">
          <div className="row justify-content-center px-4  ">
            <div className="col-lg-8 col-md-8 col-12 ">
              <div className={"full-image-card2 "}>
              {state === "default" && <  Blogsmdefaultcont/> }
              {state === "first" && <  Blogsmcontent/> }
                 

              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 ">
              <div className={"blog-box full-image-card2 "}>
                <Collapse>
                  <Panel header="2021" key="1">
                    <Collapse defaultActiveKey="1">
                      <Panel
                        header="November"
                        key="1"
                        style={{ cursor: "pointer" }}
                      >
                        <p onClick={() => setstate("first")} >11-12-2021</p>
                        
                      </Panel>
                    </Collapse>
                  </Panel>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*End blog*/}
    </div>
  );
}

export default Blog;
