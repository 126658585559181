import React from 'react'

function CTA() {
    return (
        <div>
            {/*Start CTA*/}
        <section className="app-cta pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading text-w">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <a href="#" className="btn-main bg-btn4 lnk">GET STARTED<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></a>
                  <p className="cta-call">Or call us now <a href="tel:04445550245"><i className="fas fa-phone-alt" /> 044 4555 0245</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-c1" />
          <div className="shape shape-c2" />
          <div className="shape shape-c3" />
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default CTA
