import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function TechConsulting() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  Digital Commerce
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/Digital Commerce/Digital Commerce.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>
                  Digital Commerce - Delivering you products made easy & fun now
                  than ever; Own your dream store now!
                </h2>
                <p>
                  Businesses are eager to advance to the point where they can
                  communicate with clients virtually, and this is where digital
                  commerce also known as e-commerce comes into playBusinesses
                  now have a fantastic opportunity to create new revenue
                  streams, establish connections with new customers, and fully
                  tap into a $10 trillion market that is anticipated to almost
                  triple in size by 2027 thanks to digital commerce. A factor
                  impacting the growth of e-commerce is the growing demand for
                  digital products and services to cater to an increasingly
                  tech-savvy customer base.
                </p>

                {/* <div className="servie-key-points">
                  <h4>
                    Let's talk about tech consulting and why it benefits
                    companies so much. We'll consider:
                  </h4>
                  <ul className="key-points mt20">
                    <li>Reduce costs</li>
                    <li>Create new revenue streams</li>
                    <li>Streamline their operations</li>
                    <li>Improve customer service</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "30px !important" }}>
            How we do it
          </h2>

          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Digital Commerce/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>
                  Strategy & Consulting - Professionally analyzed plans &
                  systematic approach.
                </h3>
                <p>
                  Opportunities abound when things change. You'll need a winning
                  strategy and a strategist, though, in order to take advantage
                  of that opportunity. Mercy IT Corp has unmatched strategists
                  who take on any challenges you throw at them, designing
                  unbeatable strategies based on high-level decisions made
                  objectively, using significant industry expertise to produce
                  the best results, and helping you drive leads beyond what is
                  possible.For years, we have created solutions that work and
                  guide companies to prosperity for years to come.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>
                  Implementation - Execution that is assured to never miss and
                  deliver accurate results.
                </h3>
                <p>
                  Even the greatest of strategies are doomed to fail if not
                  implemented and realized accordingly which is necessary to
                  produce a great business performance. But We're prepared to
                  support your reinvention. Through our professional teams who
                  carefully implement strategies and precisely monitor how it
                  functions to come up with solutions that may be used to
                  prevent any upcoming threats in the far future.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Digital Commerce/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Digital Commerce/3.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>
                  Innovative Transformation - Life changing innovations that
                  expands your enterprises digital transformation.
                </h3>
                <p>
                  An effective strategy must be followed for the world to be
                  impressed by the innovation, technology, and talent of a
                  company. We help organizations create innovative,
                  technology-enabled concepts and make them a reality based on
                  their past performance and insights. To do this, we employ
                  digital technology to retool production; mobile technology to
                  boost marketing; and advanced analytics to improve
                  decision-making. Such ideas must be put to the test, put into
                  action, and ultimately convince customers to adopt them.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>4</span>
                <h3>
                  Manage & Operate - Taking every possibility into account,
                  assisting your enterprise operate & manage efficiently.
                </h3>
                <p>
                  The manageability and operability of the platform are the only
                  two aspects of our programme that are addressed here. A
                  successful online retail business depends on an operation plan
                  that can handle any pressing problems with orders, worldwide
                  fulfillment, and logistics. This will provide you with a
                  steady business and increase profitability, which will boost
                  customer satisfaction. We support your management and
                  operations, from customer service to supply chain planning.
                  Our software platform ensures you will be able to handle the
                  increasing amount of traffic generated by your business..
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Digital Commerce/4.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Process*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default TechConsulting;
