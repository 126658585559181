import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function TechConsulting() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  Automation - Away with AI
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/Automation/Automation.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>
                  Automation - AI integrated workspace reducing manual
                  interventions.
                </h2>
                <p>
                  Imagine the possibility of your company growing on its own to
                  stay up with digital disruption, reaping the greatest rewards,
                  and pushing the boundaries of what is possible with
                  cutting-edge intelligence. But deploying automation
                  effectively requires a business-wide approach. This includes
                  developing entirely new learning paradigms around AI in
                  addition to utilizing it to enhance current procedures. It's a
                  revolutionary way of working, supported by continuing
                  organizational transformation and skill development, giving
                  you access to the greatest human intellect, which will be
                  joined with self-optimizing AI to solve and anticipate your
                  most challenging problems, both now and in the future. Our
                  clients in the industrial sector use our vendor-neutral
                  Industrial Automation and Control solutions to improve
                  real-time data access and get big insights from big data. It's
                  all about being resilient!
                </p>
                {/* <div className="servie-key-points">
                  <h4>
                    Let's talk about tech consulting and why it benefits
                    companies so much. We'll consider:
                  </h4>
                  <ul className="key-points mt20">
                    <li>Reduce costs</li>
                    <li>Create new revenue streams</li>
                    <li>Streamline their operations</li>
                    <li>Improve customer service</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "30px !important" }}>
            What we offer
          </h2>
          <p style={{ textAlign: "center" }}>
            All functional and domain areas of the company are covered by our
            automation programme. Our domain solutions make it possible to
            automate business procedures that are particular to a given
            industry, including mortgage processing in banking,
            pharmacovigilance in the life sciences, claims processing in
            insurance, financial reconciliation in financial services,
            healthcare enrollment and billing in financial services, and claims
            processing in insurance. Our domain solutions make it possible to
            automate business procedures that are particular to a given
            industry.
          </p>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Automation/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>Business Process Automation</h3>
                <p>
                  Our business process automation service follows a
                  digital-first strategy with three specific aims in mind:
                  achieving agility, enabling innovation, reducing cost and
                  operational complexity, and meeting the company's requests for
                  process automation applicable to diverse sectors. In the
                  process automation industry, we guide businesses in enhancing
                  their capabilities, with a focus on increasing the engagement
                  between people and technology across all functions within
                  organizations creating a common ground between humans and AI
                  with the focus of decreasing human intervention as much as
                  possible thus increasing accurate productivity.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>Business IT Automation</h3>
                <p>
                  Well-established processes give your organization a secure
                  footing, putting you in an advantageous position to be able to
                  respond quickly and effectively. To fulfill the needs and
                  demands of IT organizations, our business IT automation
                  services apply a human-first, human-in-the-loop, AI-supported
                  strategy. Our enterprise-wide automation, which leverages
                  advancements in on-premises and cloud automation technologies,
                  as well as AI, focuses on building and executing processes
                  around the following themes:
                </p>
                <div className="servie-key-points">
                  <ul className="key-points mt20">
                    <li>
                      Agility - With a primary focus on IT application
                      buildouts, using AI-led intelligent engineering, planning,
                      pipelines, assurance, and operations.
                    </li>
                    <li>
                      Efficiency - By placing a strong emphasis on AI-led
                      integrated run operations spanning infrastructure,
                      applications, security, networks, and data center
                      automation.
                    </li>
                    <li>
                      A way to better experience - Better experiences are
                      produced through automation, which focuses on a smooth
                      user experience for chatbots, knowledge, and action
                      resolutions used for virtual support and service desk
                      automation.
                    </li>
                  </ul>
                  <p>
                    What are you waiting for? It’s time to move beyond those
                    bots and take control with the help of automation powered by
                    cutting edge AI
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Automation/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Process*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default TechConsulting;
