import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../About/Navbar";

function StaticWebsite() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/6.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  Static Website Development
                </h1>
                <p
                  className="mt20 wow fadeInUp text-white"
                  data-wow-delay=".4s"
                >
                  We build websites that are user friendly and a one that
                  represents your business.
                </p>
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Hero*/}
      {/*Start About*/}
      <section className="service pad-b ">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 px-5">
              <div className="image-block upset bg-shape wow fadeIn">
                <img
                  src="assets/images/about/static-website.png"
                  alt="image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>Professional Static Website Development</h2>
                <p>
                  {" "}
                  Do you want to drive more traffic to your website? Well, then
                  you’ve come to the right place. Professional website design
                  can convert leads into clients. Maintaining a web business can
                  be made easy if you have the right website. Static websites
                  are more secure, easy to maintain and cost-effective. We
                  design the most robust website that becomes the face of your
                  business.{" "}
                </p>
                {/* <p>A well-designed user-friendly website is that the initiative towards static website development 
                    because it is your business’s face to the planet. Static website development is extremely much in 
                    demand because they're very easy to manage and cost-effective.</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}
      {/*Start Tech*/}
      <div className="techonology-used- ">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/stack-icon1.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/stack-icon2.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/stack-icon3.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/stack-icon4.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/stack-icon5.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/stack-icon6.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/stack-icon7.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/stack-icon8.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/*End Tech*/}
      {/*Start Service*/}
      <section className="service-block bg-gradient6 pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="common-heading ptag">
                <span>Service</span>
                <h2>Our Services</h2>
                <p className="mb30">
                  We provide services that well suit your business needs.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset link-hover">
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service3.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Static Website Designing</h4>
                <p>
                  Static websites are very secure. It bears the minimal threat
                  of code injection because it doesn't have any plugins or
                  dynamic software for website hosting. Static sites are very
                  fast.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service2.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Responsive Web Designing</h4>
                <p>
                  Everybody lately uses smartphones. Due to this, most of the
                  traffic comes through mobile searches. Hence, we specialize in
                  building websites that are aware of mobile devices.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay="1s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service5.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Psd to HTML Service</h4>
                <p>
                  We develop and customize the simplest website design as per
                  your requirements from web designs. Our static website
                  development services come at a really affordable price.
                </p>
              </div>
            </div>
          </div>
          <div className="-cta-btn mt70">
            <div
              className="free-cta-title v-center wow zoomInDown"
              data-wow-delay="1.3s"
            >
              <p>
                Hire a <span>Dedicated Developer</span>
              </p>
              <Link
                onClick={timer}
                to="/contact-us"
                className="btn-main bg-btn2 lnk"
              >
                Hire Now
                <i className="fas fa-chevron-right fa-icon" />
                <span className="circle" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/*End Service*/}
      {/*Start Process*/}

      <section className="service-block bg-gradient6 pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading ptag">
                <span>Process</span>
                <h2>Our Web Development Process</h2>
                <p>
                  Our design process follows a proven approach. We start with a
                  deep understanding of your needs and create a layout model.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset link-hover  ">
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock  s-block-height">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/research.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                  <h4>1. Research</h4>
                  <p>
                    We do some research on what type of website you need and
                    gather information accordingly. In this step we take lot of
                    inputs from you to understand your demands and purpose of
                    the website.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Planning.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>2. Planning</h4>
                  <p>
                    Based on the information gathered in step 1, we plan and
                    look at the resources we need, wireframing, site
                    architecture, functional requirements, sitemap, content,
                    timeline and budget.{" "}
                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Prototype.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>3. Prototype</h4>
                  <p>
                    In this step we implement our planning that makes it more
                    comprehensible for the client and ensure that we are on the
                    same page.
                  </p>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Design.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>4. Design</h4>
                  <p>
                    The website’s visual look will have a greater impact and we
                    design it considering factors such as target audience,
                    purpose, your company’s identity. We use color psychology,
                    which is often used to capture the audience’s attention.
                  </p>
                
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt30 wow fadeInUp" data-wow-delay=".4s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Development.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>5. Development</h4>
                  <p>
                    Putting together all the steps the code is developed and the
                    functional website starts taking shape.
                  </p>
               
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".6s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Testing and Launch.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>6. Testing and Launch</h4>
                  <p>
                    Insights, progress & recommendations - key reports to prove
                    how we made the difference in your rankings.
                  </p>
                
                 
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".8s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Maintenance.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>7. Post Launch Maintenance</h4>
                  <p>
                    A website needs post launch maintenance to ensure seamless
                    experience and any updation based on end-user feedback.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Process*/}
      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default StaticWebsite;
