import { Button } from "react-bootstrap";
import React from "react";
import { GoPerson } from "react-icons/go";
import { MdOutlineDateRange } from "react-icons/md";
import { Link } from "react-router-dom";

export default function blogsmdefaultcont() {
  return (
    <div>
      <div className={"blog-content py-4 "}>
        <div className={"row"}>
          <div className={"col-lg-12"}>
            <h4>Step up your business by making it digitalized</h4>
          </div>

          <div className={"col-lg-12"}>
            <div className={"otherdetails"}>
              <GoPerson />{" "}
              <span style={{ color: "red" }}> By Sumaya Sheik </span>{" "}
              <MdOutlineDateRange />
              <span> Mon, Nov 01, 10:30AM </span>
            </div>
          </div>

          <div className={"col-lg-4  image-block "}>
            <img src="assets/images/blog-images/digital.jpg" width="100%" />
          </div>
          <div className={"col-lg-8"}>
            <h6>
              There is no denying that we are living in an era of technology
              revolution where every little thing that we were doing manually
              has been digitalized and with the continuous growth of new
              technologies, many businessess have upped their game using this as
              an added advantage to broaden their horizons and which in return
              enables higher ROI.
            </h6>
            <Link to="/blogfullcontent1">
              <Button
                className={"blog-button"}
                style={{
                  backgroundColor: "#0E0942",
                  borderColor: "none",
                }}
                size="sm"
              >
                READ MORE
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
