import React from 'react'
import Navbar from '../../About/Navbar'
import { Link } from 'react-router-dom'


function Ecommerce() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
        <div>
        <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/6.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">E-Commerce Web Application</h1>
                  <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s"> Take your business forward with an outstanding site to sell your products</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Start About*/}
        <section className="service pad-b">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 px-5 ">
                <div className="image-block upset bg-shape wow fadeIn">
                  <img src="assets/images/about/ecommerce.png" alt="image" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-8 block-1">
                <div className="common-heading text-l pl25">
                  <span>Overview</span>
                  <h2>Why choose Us to develop your E-Commerce Web Application?</h2>
                  <p>The growth of E-Commerce is evident and everyone is drawn to it as it has made buying things easier than ever. To be ahead in the competition, it is crucial to have a website that is 
                    visually attractive and intimidating in design. 
                    We are a group of highly experienced web developers who are technology geeks and we surely can develop a 
                    responsive and an attractive website for you and keep you ahead of the game.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        {/*Start Tech*/}
        <div className="techonology-used-">
          <div className="container">
            <ul className="h-scroll tech-icons">
              <li><a href="#"><img src="assets/images/icons/woocommerce.svg" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/shopify.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/magento.svg" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/laravel.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/codeigniter.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/openkart.png" alt="icon" /></a></li>
            </ul>
          </div>
        </div>
        {/*End Tech*/}
        {/*Start Service*/}
        <section className="service-block bg-gradient6 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading ptag">
                  <span>Service</span>
                  <h2>Our Services</h2>
                  <p className="mb30">We provide services that well suit your business needs.</p>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
                <div className="s-block">
                  <div className="s-card-icon"><img src="assets/images/icons/logo-and-branding.svg" alt="service" className="img-fluid" /></div>
                  <h4>WooCommerce</h4>
                  <p>WooCommerce, one of the front runner in eCommerce website design that is flexible and has several features that makes your website smooth and efficient. We develop the  best in class Woocommerce websites for you at reasonable prices. </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
                <div className="s-block">
                  <div className="s-card-icon"><img src="assets/images/icons/service2.svg" alt="service" className="img-fluid" /></div>
                  <h4>Magento</h4>
                  <p>Magento is a popular eCommerce website platform that has one of the most powerful built-in eCommerce features. With us you can peacefully build your online store.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
                <div className="s-block">
                  <div className="s-card-icon"><img src="assets/images/icons/service3.svg" alt="service" className="img-fluid" /></div>
                  <h4>Shopify</h4>
                  <p>Shopify is one of the premium eCommerce platform that is designed for businesses of all sizes.</p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
                <div className="s-block">
                  <div className="s-card-icon"><img src="assets/images/icons/service4.svg" alt="service" className="img-fluid" /></div>
                  <h4>Codeigniter</h4>
                  <p>Codeigniter is a rapid web development software, used  widely to build dynamic websites using PHP. </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay="1s">
                <div className="s-block">
                  <div className="s-card-icon"><img src="assets/images/icons/service5.svg" alt="service" className="img-fluid" /></div>
                  <h4>Laravel</h4>
                  <p>Laravel is an open-source web application framework which uses PHP and has a variety of tools and resources to develop a web app or product. </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay="1.2s">
                <div className="s-block">
                  <div className="s-card-icon"><img src="assets/images/icons/service6.svg" alt="service" className="img-fluid" /></div>
                  <h4>Opencart</h4>
                  <p> Opencart is another open-source online  marketplace management platform with lot of themes and extensions which can be used for industries of any scale.</p>
                </div>
              </div>
            </div>
            <div className="-cta-btn mt70">
              
              <div className="free-cta-title v-center wow zoomInDown  ecommerceline" data-wow-delay="1.3s">
              <h5>We use these top technology platforms to develop the best eCommerce website. </h5>

                <p>Hire a <span>Dedicated Developer</span></p>
                
                <Link to="/contact-us" className="btn-main bg-btn2 lnk ecommerceButton">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        {/*Start Process*/}
        <section className="service-block bg-gradient6 pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading ptag">
                <span>Process</span>
                <h2>Our E-Commerce Web Development Process</h2>
                <p>
                Mercy IT Corp is celebrated and known for design and development of highly customized, alluring, and responsive eCommerce Websites. 

                </p>
              </div>
            </div>
          </div>
          <div className="row upset link-hover  ">
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock  s-block-height">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Business Model.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                  <h4>1. Business Model</h4>
                  <p>
                  Every product requires different design, so we evaluate the products or services for which the website is being designed and decide the business model.

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Requirement Analysis.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>2. Requirement Analysis</h4>
                  <p>
                  To build a responsive website we analyze what functionalities client requires in the website. For example: payment options, FAQ section, review section etc. Once we gather the requirement we start designing.

                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Full Stack Development.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                {/* <h4>3. Front-end & Back-end Development</h4> */}
                <h4>3. Full Stack Development</h4>

                  <p>
                  Our front-end developers focus on creating the best UX with visually appealing to the end-users. Whereas, back-end developers manage the database and servers. Our developers work together to create high quality ecommerce websites.


                  </p>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-6  mt30 wow fadeInUp" data-wow-delay=".2s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Testing & Deployment.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>4. Testing & Deployment</h4>
                  <p>
                  When we are set to launch the website, there’s an important step, that is to review the website. 
                  To ensure it is error free, functional and works across all devices.
                  </p>
                
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt30 wow fadeInUp" data-wow-delay=".4s">
              <div className="s-block wide-sblock s-block-height ">
                <div className="s-card-icon-large">
                  <img
                    src="assets/images/service/Maintenance2.png"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <div className="s-block-content-large s-blog-content-para ">
                <h4>5. Maintenance</h4>
                  <p>
                  Address and Upgrade is what we follow in maintaining a website. End-user feedback is very crucial and it determines the success of any website, so, we address every issue and upgrade it to the best. 
                  </p>
               
                </div>
              </div>
            </div>
          
           
          </div>
        </div>
      </section>
        {/*End Process*/}
        
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <Link onClick={timer} to="/contact-us" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or call us now <a href="tel:+919176454455"><i className="fas fa-phone-alt" />+91 9176454455</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default Ecommerce
