import React from "react";
import { Link } from "react-router-dom";

function WhyChoose() {
  return (
    <div>
      {/*why choose*/}
      <section className="why-choos-lg pad-tb deep-dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-6  ">
              <h3 className="mb20">
                The reason behind <br></br>{" "}
                <span
                  className="text-second text-bold   "
                  style={{ color: "#ec9022" }}
                >
                  {" "}
                  MERCY BEING THE #1 CHOICE
                </span>{" "}
              </h3>
              <div className="common-heading  rmercy ">
                <div className="mercyreson">
                  <p className="rspan">M</p>
                  <p className="rspan2">
                  <span >eticulous</span>  - We are detail oriented and understand the need
                    to focus on the smaller parts that gives a clear vision of
                    the bigger picture and ultimately better results
                  </p>
                </div>

                <div className="mercyreson">
                  <p className="rspan">E</p>
                  <p className="rspan2">
                  <span >xcellence</span>  - We do better because we strive for excellence and work diligently to achieve it

                  </p>
                </div>

                <div className="mercyreson">
                  <p className="rspan">R</p>
                  <p className="rspan2">
                  <span>obust</span> - Our strategies are robust that involves organizational planning and management of tasks which help us achieve our objectives.
                  </p>
                </div>

                <div className="mercyreson">
                  <p className="rspan">C</p>
                  <p className="rspan2">
                  <span>ommitment</span> - We think big and work consistently to untap our potential as we are committed to growth and expansion.

                  </p>
                </div>

                <div className="mercyreson">
                  <p className="rspan">Y</p>
                  <p className="rspan2">
                  <span>ielding</span>  - Customers who invest in us yield desired results and enormous support throughout. To us, customers are our priority. 
                  </p>
                </div>

                {/* <div className="row">

                  <div className=" col-lg-6 itm-media-object mt40 tilt-3d">
                    <div className="media">
                      <div className="img-ab- base" data-tilt data-tilt-max={20} data-tilt-speed={1000}><img src="assets/images/icons/computers.svg" alt="icon" className="layer" /></div>
                      <div className="media-body my-auto">
                        <h4>Excellence</h4>
                      </div>
                    </div>
                    <div className="media mt50">
                      <div className="img-ab- base" data-tilt data-tilt-max={20} data-tilt-speed={1000}><img src="assets/images/icons/worker.svg" alt="icon" className="layer" /></div>
                      <div className="media-body my-auto">
                        <h4>Commitment</h4>
                      </div>
                    </div>
                    <div className="media mt50">
                      <div className="img-ab- base" data-tilt data-tilt-max={20} data-tilt-speed={1000}> <img src="assets/images/icons/deal.svg" alt="icon" className="layer" /></div>
                      <div className="media-body my-auto">
                        <h4>Clarity</h4>
                      </div>
                    </div>
                    <div className="media mt50">
                      <div className="img-ab- base" data-tilt data-tilt-max={20} data-tilt-speed={1000}> <img src="assets/images/icons/innovation.svg" alt="icon" className="layer" /></div>
                      <div className="media-body my-auto">
                        <h4>Innovation</h4>
                      </div>
                    </div>
                    
                  </div>

                  

                  </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div
                data-tilt
                data-tilt-max={5}
                data-tilt-speed={1000}
                className="single-image bg-shape-dez wow fadeIn"
                data-wow-duration="2s"
              >
                <img
                  src="assets/images/about/team.jpg"
                  alt="image"
                  className="img-fluid"
                />
              </div>
              <p className="text-center mt30">
                Our skilled and solution oriented team provides required results
                with exceptional quality that comes from dedication and
                diligence in the work we do.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*End why choose*/}
    </div>
  );
}

export default WhyChoose;
