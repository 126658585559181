import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";


function Comingsoon() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      
     
     <div className="container">
          <div className="row justify-content-center">
            
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <img src="assets/images/comingsoon.png" />
                {/* <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  AR & VR - A place where anything is possible.
                </h1> */}
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                {/* <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link> */}
              </div>
            </div>
          </div>
        </div>

      
    </div>
  );
}

export default Comingsoon;
