import React from 'react'
import Navbar from './Navbar'
import { Link } from 'react-router-dom'



function OurTeam() {
    return (
        <div>
        <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-4">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 v-center">
                  <div className="bread-inner">
                    <div className="bread-menu text-center">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="#">Team</Link></li>
                      </ul>
                    </div>
                    <div className="bread-title">
                      <h2 className="text-white text-center">Our Team</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Breadcrumb Area*/}
        {/*Start Team Leaders*/}
        {/* <section className="team bg-gradient99 pt20">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading ptag">
                  <span>We Are Awesome</span>
                  <h3>Our Managing Director</h3>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">

              <div className="col-lg-3 col-sm-6 px-4 ">
                <div className="full-image-card1 hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/arif.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4 ><Link style={{fontSize:'22px'}}  >Syed Arif</Link></h4>
                    <p>Chief Executive Officer & Managing Director</p>
                  </div>
                </div>
              </div>

              

            </div>
            <div className="row justify-content-center">

<div className="col-lg-3 col-sm-6 px-4 ">
  <div className="full-image-card2 hover-scale">
    <div className="image-div"><Link><img src="assets/images/team/ansari.jpg" alt="team" className="img-fluid" /></Link></div>
    <div className="info-text-block text-center">
      <h4><Link style={{fontSize:'22px'}}  >Thameem Ansari</Link></h4>
      <p>Head of Operations</p>
    </div>
  </div>
</div>



</div>
          </div>
        </section> */}
        {/*End Team Leaders*/}
        {/*Start Team Members*/}
        <section className="team pad-b pt-3 deep-dark">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                {/* <div className="common-heading ptag">
                  <span>We Are Awesome</span>
                  <h3>Our Team Members</h3>
                </div> */}
              </div>
            </div>
            <div className="row">
            {/* <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/avatarsmall.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Thameem Ansari</Link></h4>
                    <p>Head of Operations</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-3 col-sm-6">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/avatarlady.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Devaki S Prakash</Link></h4>
                    <p>Company COO</p>
                  </div>
                </div>
              </div> */}
               <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/arif.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Syed Arif</Link></h4>
                    <p className="teamname" >Chief Executive Officer & Managing Director</p>
                  </div>
                </div>
              </div>
               <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/prabakar.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Prabhakar Naidu</Link></h4>
                    <p className="teamname" >Chief Operating Officer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/abbas.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Abbas</Link></h4>
                    <p className="teamname" >Project Head</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/abdullah.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Abdullah Basha</Link></h4>
                    <p className="teamname" >HR Admin</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/yasar.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Yasar Arafath</Link></h4>
                    <p className="teamname" >Web Developer & Digital Marketing Analyst</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-sm-6">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/avatarsmall.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Baptista Selvaraj</Link></h4>
                    <p>Sales & Marketing</p>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3  ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/abdularif.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Abdul Arif</Link></h4>
                    <p className="teamname" >UI/UX Designer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/asiq2.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Mohammed Asiq</Link></h4>
                    <p className="teamname" >MERN Stack Developer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/wakir2.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Wakir Younus</Link></h4>
                    <p className="teamname" >Android/iOS Developer</p>
                  </div>
                </div>
              </div> 
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/avatarsmall.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Ayyasudeen</Link></h4>
                    <p className="teamname" >Web Developer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/avatarsmall.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Wajeeh</Link></h4>
                    <p className="teamname" >Full Stack Developer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/ijaz2.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Mohammed Ijaz</Link></h4>
                    <p className="teamname" >Python Developer</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/moiz2.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Abdul Moeez</Link></h4>
                    <p className="teamname" >Graphic Designer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/avatarlady.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Sumaya Sheik </Link></h4>
                    <p className="teamname" >Content Developer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/hamdaan2.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Hamdaan</Link></h4>
                    <p className="teamname" >Digital Marketing Executive</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/hassan2.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Hasen Ali</Link></h4>
                    <p className="teamname" >Junior Developer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/kadar2.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Kader Meeran</Link></h4>
                    <p className="teamname" >Junior Developer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/avatarsmall.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Haroon</Link></h4>
                    <p className="teamname" >Junior Developer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 px-3 ">
                <div className="full-image-card hover-scale">
                  <div className="image-div"><Link><img src="assets/images/team/avatarsmall.jpg" alt="team" className="img-fluid" /></Link></div>
                  <div className="info-text-block text-center">
                    <h4><Link>Akbar Sha</Link></h4>
                    <p className="teamname" >Junior Developer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Team Members*/}
        </div>
    )
}

export default OurTeam
