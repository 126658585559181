import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function DigitalMarketing() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  Digital Marketing
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/Digital Marketing/DM.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>Digital Marketing</h2>
                <div className="servie-key-points">
                  <h4 style={{ fontStyle: "italic" }}>
                    “It's all about reaching the right audience at the right
                    time - some call it luck and we call it carefully crafted
                    strategies”
                  </h4>
                </div>
                <p>
                  By providing personalized experiences, digital marketing
                  practices assist businesses in developing deeper relationships
                  with their customers. We help businesses accomplish this by
                  providing pertinent content, enabling insights-driven
                  decision-making, and managing campaigns with a high degree of
                  personalization.
                </p>

                {/* <div className="servie-key-points">
                  <h4>
                    Let's talk about tech consulting and why it benefits
                    companies so much. We'll consider:
                  </h4>
                  <ul className="key-points mt20">
                    <li>Reduce costs</li>
                    <li>Create new revenue streams</li>
                    <li>Streamline their operations</li>
                    <li>Improve customer service</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "30px !important" }}>
            What we offer
          </h2>
          <p style={{ textAlign: "center", fontStyle: "italic" }}>
            “Strap on and get ready to tap into our Next-Gen Digital Marketing
            Services”
          </p>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Digital Marketing/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>
                  Site Publishing & Optimization Services - Reach an audience as
                  big as this world.
                </h3>
                <p>
                  Thanks to website and digital marketing strategies, businesses
                  are currently expanding at a phenomenal rate. But what
                  precisely occurs in those digital marketing techniques that
                  take place "behind the scenes" of a website. We would be happy
                  to help you by deciphering, planning, and carrying out those
                  "Behind the Scenes" for you. Our service makes sure to help
                  you with every part of digital marketing for a website,
                  including page improvements and keyword research that will
                  propel your business past the great majority of competing
                  websites and place it first, giving you the trophy.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>
                  Social Networking Platform Optimization - Let your story be
                  heard among the crowds.
                </h3>
                <p>
                  Every enterprise has a story to tell and a voice to be heard.
                  But voices are quite hard to hear and stories are hard to tell
                  in a place filled with a mass of voices and stories. We
                  provide solutions that act as an amplifier and help your
                  brand's voice be echoed throughout the social media platforms.
                  We increase your brand's customer reach and set up custom
                  social media strategies with data-driven insights for
                  companies who are struggling to voice their presence in the
                  market. We are firm believers in saying no to half-hearted
                  solutions. Our approach is all encompassing and down to the
                  core - We assure you, your stories will be told & voices will
                  be heard!
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Digital Marketing/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Digital Marketing/3.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>
                  Brand Visibility Campaigns - Drive Sustainable Business Growth
                  with Precisely Targeted Campaigns with Max Clicks &
                  Conversions.
                </h3>
                <p>
                  The "Waiting Game" has become an outdated strategy since the
                  introduction of paid campaigns. It’s no secret that paid
                  campaigns have been demonstrated to deliver results more
                  quickly and effectively if used appropriately by targeting the
                  right audience at the right time and using the right
                  strategies. Launch campaigns at scale, analyze performance and
                  optimize continually to maximize clicks and conversion.
                </p>
              </div>
            </div>
          </div>
       
        
        </div>
      </section>
      {/*End Process*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default DigitalMarketing;
