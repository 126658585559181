import React from "react";

function AboutUs() {
  return (
    <div>
      {/*Start About*/}
      <section className="about-agency py-5 block-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 v-center">
              <div className="about-image">
                <img
                  src="assets/images/about/aboutimg.jpg"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="common-heading text-l ">
                {/* <span>About Us</span> */}
                <h2>About Us</h2>
                <p>
                  Mercy IT Corp is a unit of conglomeration of Mercy Group of
                  Companies. We have vast experience in the field of business
                  that has gained us trust and reputation from clients. Mercy
                  Group of Companies has always worked hard to keep its strides
                  high and transform businesses through the services we provide.
                  Mercy IT Corp specializes in services such as Website
                  Development, Mobile Applications, Web Applications, Digital
                  Marketing, Graphic Design, UI/UX Design, Chatbots, IoT, and
                  Blockchain. Our meritorious team and determined work focuses
                  on bringing in innovative methods that simplifies technology
                  for everyone.{" "}
                </p>
              </div>
              <div className="row in-stats small about-statistics">
                <div className="col-lg-4 col-sm-4">
                  <div className="statistics">
                    <div className="statnumb counter-number">
                      <span className="counter">25+</span>
                      <p>Happy Clients</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="statistics">
                    <div className="statnumb">
                      <span className="counter">8</span>
                      <span></span>
                      <p>Countries</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="statistics mb0">
                    <div className="statnumb counter-number">
                      <span className="counter">50+</span>
                      <p>Projects Done</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>

          <div className="row  wedo ">

          <div className="col-lg-6">
              <div className="common-heading text-l ">
                <h2>What we do?</h2>
                <p  >
                  We offer IT services to various companies scaling from
                  startups to well established companies, which includes
                  corporate, e-commerce, public, education, health, and
                  hospitality throughout the world. We have highly proficient
                  team who are skilled in developing Mobile Applications,
                  Chatbots, IoT, Web Apps, Blockchain, Web Development, Digital
                  Marketing and Graphic Design. With experience and talent we
                  strongly assure that Mercy IT Corp is the right technology
                  partner for you. We work to bring a revolutionary
                  technological change which is inevitable in the near future.
                  Better Innovations, Bigger Transformations.{" "}
                </p>
              </div>
            </div>

            <div className="col-lg-6 v-center">
              <div className="about-image">
                <img
                  src="assets/images/about/whatwedo.jpg"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>

          </div>
        </div>
      </section>
      {/*End About*/}
      {/*Start why-choose*/}
      <section className="why-choose pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="common-heading">
                <span>We Are Awesome</span>
                <h2 className="mb30">Why Choose Us</h2>
              </div>
            </div>
          </div>
          <div className="row upset">
            <div className="col-lg-3 col-sm-6 mt30">
              <div className="s-block up-hor">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/research.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Reasearch and Analysis</h4>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt30">
              <div className="s-block up-hor">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/chat.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Negotiation and power</h4>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt30">
              <div className="s-block up-hor">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/monitor.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Creative and innovative solutions</h4>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt30">
              <div className="s-block up-hor">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/trasparency.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Trasparency and ease of work</h4>
              </div>
            </div>


          </div>
        </div>
      </section>
      {/*End why-choose*/}
    </div>
  );
}

export default AboutUs;
