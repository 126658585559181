import React from 'react'
import {Link} from "react-router-dom"

function BreadCrumb() {
    return (
        <div>
             {/*Breadcrumb Area*/}
                <section className="breadcrumb-area banner-1" data-background="images/banner/6.jpg">
                    <div className="text-block">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12 v-center">
                            <div className="bread-inner">
                            <div className="bread-menu">
                                <ul className="text-center">
                                <li><Link to="/">Home</Link></li>
                                <li><a href="#">About Us</a></li>
                                </ul>
                            </div>
                            <div className="bread-title text-white">
                                <h2 className="text-white text-center">About Us</h2>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
      {/*End Breadcrumb Area*/}
        </div>
    )
}

export default BreadCrumb
