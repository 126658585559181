import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function TechConsulting() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  AR & VR - A place where anything is possible.
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/AR & VR/AR.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>AR & VR - A place where anything is possible.</h2>
                <p>
                  Along with AI and automation, virtual reality (VR) and
                  augmented reality (AR) are the technologies that are powering
                  today's technology industry. These technologies have redefined
                  content strategies and altered how businesses publish content.
                  Today, businesses are using virtual reality to improve
                  customer experience, increase efficiency, and reduce expenses
                  as well as upskill their employees! Crazy but proven to be
                  effective. With virtual reality, there are endless
                  possibilities for businesses to use. And we help you in
                  finding and utilizing the right opportunity for you and your
                  enterprise. Our services can help you and your business find
                  success with a new way of thinking and interacting with
                  technology.
                </p>
                {/*        
                <div className="servie-key-points">
                  <h4>
                    Let's talk about tech consulting and why it benefits
                    companies so much. We'll consider:
                  </h4>
                  <ul className="key-points mt20">
                    <li>Reduce costs</li>
                    <li>Create new revenue streams</li>
                    <li>Streamline their operations</li>
                    <li>Improve customer service</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "30px !important" }}>
            What we do
          </h2>
          <p style={{ textAlign: "center" }}>
            “A step beyond reality” - give life to your wildest ideas you have
            in your intriguing mind.
          </p>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/AR & VR/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>
                  Immersive learning - A learning experience that is impossible
                  to forget.
                </h3>
                <p>
                  Humans have a tendency to forget what they only learned an
                  hour ago. It has been passed down to us from our forefathers,
                  and it runs in our family. Until the development of AR and VR
                  technology, this issue went unaddressed. These technologies
                  changed reality from a place of learning to one of
                  involvement. Immersive learning, a more advanced kind of
                  active learning, employs technology to create completely
                  simulated worlds where individuals engage with the experience
                  itself. It's as close to the "real world" as you can get.
                  People often learn best by doing and experiencing things,
                  which is a lot more pleasurable and natural way to learn. AR
                  and VR technology can let you accomplish just that! A way to
                  increase learning by doing!
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>Interactive Merchandise - A fun way to shop.</h3>
                <p>
                  Determining the ideal shelf location for a product to be on
                  display in order to draw customers' attention and increase
                  sales involves a number of different considerations. But what
                  if we told you that we have a quick, low-cost strategy that
                  has been shown to drive more than just sales—driving your
                  brand? With the help of AR and VR powered stores, you can
                  gather unfiltered data on consumer behavior, such as what they
                  buy and how frequently they look at a specific part of a
                  shelf. This information can be used by businesses to develop
                  marketing strategies that are based on reliable data,
                  increasing both sales and brand recognition. Given the
                  strength of mobile shopping and its steadily growing share of
                  the overall retail market, brands are moving away from
                  in-store innovation to better position themselves through data
                  and analytics. Studies have shown that shoppers consider AR to
                  be as important as in-store signs and shelf labels, so now is
                  the time to jump into AR/VR powered stores.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/AR & VR/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/AR & VR/3.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>Virtual Adventure - “Making dreams come true”</h3>
                <p>
                  A place where dreams can come true. We present to you "Virtual
                  Adventure"! ranging from a professional agenda to individual
                  virtual hangouts. Are you planning a significant product
                  launch but unsure of your ability to make it happen? Buckle
                  up! We can help you carry out even the biggest product launch
                  the world has ever witnessed because AR and VR technology are
                  so advanced at the moment that the unknown becomes certain.
                  Imagine taking a family cruise or hosting friends for an
                  unforgettable AR and VR experience and a great virtual party.
                  "Thus, by science fiction, comes the obvious and by AR and VR,
                  the possible."
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <h2
        style={{
          textAlign: "center",
          marginBottom: "30px !important",
          fontWeight: "bolder",
        }}
      >
        “Now who’s ready for an Adventure”.
      </h2>
      {/*End Process*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default TechConsulting;
