import React from "react";
import Navbar from "../About/Navbar";
import { Link } from "react-router-dom";

function GraphicalCreations() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section
        className="breadcrumb-areav2"
        data-background="assets/images/banner/8.jpg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="bread-titlev2">
                <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">
                  Graphical Creations
                </h1>
                {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                <Link
                  onClick={timer}
                  to="/contact-us"
                  className="btn-main bg-btn2 lnk mt20 wow zoomInDown"
                  data-wow-delay=".6s"
                >
                  Get Quote <i className="fas fa-chevron-right fa-icon" />
                  <span className="circle" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start About*/}
      <section className="service pad-tb bg-gradient5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block wow fadeIn">
                <img
                  src="assets/images/process/Graphical Creations/GC.jpg"
                  alt="image"
                  className="img-fluid no-shadow"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>Graphical Creations</h2>
                <div className="servie-key-points">
                  <h4>A world run by Zero-Code</h4>
                </div>
                <p>
                  Customers' and employees' expectations are changing at a
                  faster rate than businesses can adapt. Businesses that can
                  adapt and innovate in new ways to meet their customers'
                  constantly changing needs will be successful in the future. It
                  is necessary to take a systemic approach to creating,
                  launching, and scaling new goods, services, and experiences
                  that people adore, as well as building the organizational​
                  capabilities to expand consistently and repeatedly.
                </p>
                <div className="servie-key-points">
                  <h4 style={{ fontStyle: "italic" }}>
                    “Great experiences don’t happen by chance – they are
                    deliberately and carefully designed.”
                  </h4>
                </div>
                {/*              
                <div className="servie-key-points">
                  <h4>
                    Let's talk about tech consulting and why it benefits
                    companies so much. We'll consider:
                  </h4>
                  <ul className="key-points mt20">
                    <li>Reduce costs</li>
                    <li>Create new revenue streams</li>
                    <li>Streamline their operations</li>
                    <li>Improve customer service</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}

      {/*Start Tech*/}
      <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li>
              <a href="#">
                <img src="assets/images/icons/swift.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/java.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/reactnative.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/flutter.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/ionic.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/kotlin.png" alt="icon" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="assets/images/icons/xamarin.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <section id="process" className="service-block pad-tb light-dark">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "30px !important" }}>
            What we create
          </h2>
          <p style={{ textAlign: "center" }}>
            Exceptional graphic design that communicates ideas visually to your
            audience is of vital importance to build your brand identity and let
            them connect with you.
          </p>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Graphical Creations/1.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>
                  Design Experience - Carefully crafted experiences that meet
                  current consumer needs.
                </h3>
                <p>
                  You must design and prototype services and experiences that
                  meet people where they are in order to shape services that
                  simplify the complex for customers and employees. We design
                  strategies and coordinate cross-channel interactions that pave
                  the way for ongoing innovation. In an "always-on ecosystem,"
                  where each interaction is a crucial turning point, we craft
                  consistent experiences. While building an experimentation
                  capability, we rapidly and continuously experiment to develop
                  experiences that address unmet customer needs. We design and
                  build deeply integrated platforms that create rich experiences
                  for users across devices, platforms, and digital touchpoints.
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>
                  Digital Product Design - Strategic Product Designs with Max
                  ROI
                </h3>
                <p>
                  We offer cutting-edge, scalable digital products that can
                  change to meet changing needs and add value over time. We
                  continuously enhance delivery quality and frequency in order
                  to increase return on investment. As we develop, introduce,
                  and scale digital products and solutions, we establish a
                  product mindset and operating model. innovative methods for
                  evaluating and enhancing digital products and services. For
                  new results, contemporary tools are necessary.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Graphical Creations/2.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Graphical Creations/3.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>
                  User Interface - Visually Pleasing Designs integrated with UX
                </h3>
                <p>
                  A successful strategy can get your product in front of the
                  right people, but if it doesn't look good, it won't work. Even
                  if marketing strategies were to be unsuccessful, our team of
                  highly qualified professionals has creative minds that work
                  together to create artistic pieces that draw customers. Our
                  designers will ensure that your product is seen by the target
                  audience you want to reach and, more importantly, that it
                  leaves a lasting impression.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>4</span>
                <h3>
                  Video Content Production - Fun and Effective way to deliver
                  your product & services
                </h3>
                <p>
                  Studies have shown that almost 80% of the world's traffic is
                  attracted by video based products alone. Also business
                  entities who use videos are reaping their revenue 49% faster
                  than other businesses who don’t incorporate videos in their
                  marketing platforms. What we at mercy bring to the table is, a
                  team of skilled individuals with distinguished creatives that
                  meets up to your current & future goals. Our exceptionally
                  skilled content creation teams help you in producing,
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img
                  src="assets/images/process/Graphical Creations/4.jpg"
                  alt="Process"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <h2
        style={{
          textAlign: "center",
          marginBottom: "30px !important",
          fontStyle: "italic",
        }}
      >
        “Sometimes a simple good design is all it takes”
      </h2>

      {/*End Process*/}

      {/*Start core value*/}
      <section className="service-block bg-gradient6 pad-tb light-dark">
        <div className="container">
          {/* <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="common-heading ptag">
                <span>Values</span>
                <h2>Mercy IT Corp's Core Values</h2>
                <p className="mb30">
                  We think big and have hands in all leading technology
                  platforms to provide you wide array of services.
                </p>
              </div>
            </div>
          </div> */}
          <div className="row upset link-hover">
            <div
              className="col-lg-6 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".2s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/logo-and-branding.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>2D Animations</h4>
              </div>
              <div className="colr2">
                <p>
                  With customized films that strike the ideal mix between
                  engagement and information, you can give your company the
                  competitive edge on the market. Not only are these visually
                  entertaining but also gives you a hook to reel in huge amounts
                  of perfect traffic towards your business, driving more growth
                  in all sectors.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service2.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Animated Ad’s</h4>
              </div>
              <div className="colr2">
                <p>
                  Visually pleasing and attracting video not only leads the
                  right audience towards your business but also carries your
                  story to them. If people are not looking in your direction
                  then these ads will make them stare at your enterprise and
                  what it has to say, with the help of targeted insights and
                  highly attractive visuals.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service3.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Infographic</h4>
              </div>
              <div className="colr2">
                <p>
                  There is no better educator or narrator than infographic
                  videos. Carefully crafted and designed to bring a sense of
                  clarity and interpretation to the consumer. Analyzing your
                  brand's identity & what your current goal has to say and
                  converting them into visually exciting as well as fun to watch
                  & learn videos is what our infographic team provides you
                  with-exactly delivering what you intended to.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".8s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service4.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Motion Graphic</h4>
              </div>
              <div className="colr2">
                <p>
                  Myths into reality, Dreams and Visions into live action
                  videographs. We make the impossible happen using the robust
                  features that come with motion graphics, helping your
                  enterprise elevate into cloud nine of creativity. Our motion
                  graphic design teams assist you in breathing life to your
                  brand’s value and deliver results quicker than any other
                  platform. We help you achieve the “You can’t”
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Service*/}

      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:+91 9176454455">
                    <i className="fas fa-phone-alt" />
                    +91 9176454455
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default GraphicalCreations;
