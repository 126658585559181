import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../Home/Navbar'

function OurServices() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
        <div>
        <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/6.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">Our Services</h1>
                  <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">We offer variety of quality IT services</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/*Start Service*/}
        <section className="service-block bg-gradient6 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading ptag">
                  <span>Service</span>
                  <h2>High Quality Services</h2>
                  <p className="mb30">We think big and have hands in all leading technology platforms to provide you wide array of services.</p>
                </div>
              </div>
            </div>
            <div className="row upset">
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.2s">
                <div className="service-card-app hoshd">
                  <h4>App Development</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">iPhone</a> </li>
                    <li> <a href="javascript:void(0);">Android</a> </li>
                    <li> <a href="javascript:void(0);">Cross Platform</a> </li>
                  </ul>
                  
                  <p className="mt20" >Our next-gen app development team offers solutions for simplifying business complexities.</p>
                  <Link to="/app-development" className="mt20 link-prbs collapsed" role="button" data-toggle="collapse" href="javascript:void(0);collapseExample" aria-expanded="false" aria-controls="collapseExample">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.5s">
                <div className="service-card-app hoshd">
                  <h4>Web Development</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">UI/UX</a> </li>
                    <li> <a href="javascript:void(0);">PHP</a> </li>
                    <li> <a href="javascript:void(0);">Java</a> </li>
                    <li> <a href="javascript:void(0);">WordPress</a></li>
                  </ul>
                  <p className="mt20">Top notch web development company from India that has severed a variety of businesses.</p>
                  <Link to="/static-website" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.8s">
                <div className="service-card-app hoshd">
                  <h4>eCommerce Development</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">Magento</a> </li>
                    <li> <a href="javascript:void(0);">Shopify</a> </li>
                    <li> <a href="javascript:void(0);">Woo-commerce</a></li>
                  </ul>
                  <p className="mt20">Tailored E-commerce Development Solutions for various enterprise businesses.</p>
                  <Link to="/ecommerce" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.8s">
                <div className="service-card-app hoshd">
                  <h4>Static Website</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">PHP</a> </li>
                    <li> <a href="javascript:void(0);">Javascript</a> </li>
                    <li> <a href="javascript:void(0);">Wordpress</a></li>
                  </ul>
                  <p className="mt20">User friendly website designs that keeps them hooked on your website</p>
                  <Link to="/static-website" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.8s">
                <div className="service-card-app hoshd">
                  <h4>Dynamic Website</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">React</a> </li>
                    <li> <a href="javascript:void(0);">Angular</a> </li>
                    <li> <a href="javascript:void(0);">Wordpress</a></li>
                  </ul>
                  <p className="mt20">Professional websites that are just what your business needs.</p>
                  <Link to="/dynamic-website" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.8s">
                <div className="service-card-app hoshd">
                  <h4>CMS Website</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">Netlify</a> </li>
                    <li> <a href="javascript:void(0);">Strapi</a> </li>
                    <li> <a href="javascript:void(0);">Woo-commerce</a></li>
                  </ul>
                  <p className="mt20">Making your content easily manageable with our simplified designs.</p>
                  <Link to="/cms-website" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.8s">
                <div className="service-card-app hoshd">
                  <h4>Website Revamping</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">Javascript</a> </li>
                    <li> <a href="javascript:void(0);">PHP</a> </li>
                    <li> <a href="javascript:void(0);">Wordpress</a></li>
                  </ul>
                  <p className="mt20">We redesign and redefine  your website that empowers growth to your business.</p>
                  <Link to="/website-revamp" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.8s">
                <div className="service-card-app hoshd">
                  <h4>SEO</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">Yoast SEO</a> </li>
                    <li> <a href="javascript:void(0);">Google Trends</a> </li>
                    <li> <a href="javascript:void(0);">Analytics</a></li>
                  </ul>
                  <p className="mt20">Rank higher in result pages with our SEO strategies.</p>
                  <Link to="/seo-marketing" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.8s">
                <div className="service-card-app hoshd">
                  <h4>Social Media Marketing</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">Facebook</a> </li>
                    <li> <a href="javascript:void(0);">Twitter</a> </li>
                    <li> <a href="javascript:void(0);">Instagram</a></li>
                  </ul>
                  <p className="mt20">Unlock the power of Social Media Marketing with our incredible plans.</p>
                  <Link to="/social-media" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.8s">
                <div className="service-card-app hoshd">
                  <h4>Google Ads</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">Google Trends</a> </li>
                    <li> <a href="javascript:void(0);">Google Ranking</a> </li>
                    <li> <a href="javascript:void(0);">Ads</a></li>
                  </ul>
                  <p className="mt20">Attract potenital customers with the right Ad Marketing services</p>
                  <Link to="/google-ads" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.8s">
                <div className="service-card-app hoshd">
                  <h4>Email Marketing</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">Bulk Email</a> </li>
                    <li> <a href="javascript:void(0);">Sendinblue</a> </li>
                  </ul>
                  <p className="mt20">Keep your customers engaged with customisable Email Marketing.</p>
                  <Link to="/email-marketing" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.8s">
                <div className="service-card-app hoshd">
                  <h4>UI/UX Design</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">AdobeXD</a> </li>
                    <li> <a href="javascript:void(0);">Figma</a> </li>
                    <li> <a href="javascript:void(0);">CorelDraw</a></li>
                  </ul>
                  <p className="mt20">Enticing designs that makes your website effective and unique.</p>
                  <Link to="/uiux-design" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              </div>
            <div className="-cta-btn mt70">
              <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.3s">
                <p>Let's talk about <span>Your Project</span></p>
                <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk">Contact Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        </div>
    )
}

export default OurServices
