import React from 'react'
import {swiper_slides_data} from "./SwiperCarousel"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, { Pagination, Autoplay } from "swiper/core";


// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

function Testimonials() {




  const swiper_slide_elements = swiper_slides_data.map((e, index) => {
    return <SwiperSlide key={index}>
            <div className="testimonial-card-a tcd owl-carousel  mySwiper ">
                    <div className="testimonial-card">
                      <div className="tt-text">
                        <p>{e.client_testimony}</p>
                      </div>
                      <div className="client-thumbs mt30">
                        <div className="media v-center upset">
                          <div className="user-image bdr-radius">
                            <img src={e.img_url} alt="girl" className="img-fluid rounded-circle" />
                            </div>
                          <div className="media-body user-info v-center">
                            <h5>{e.client_name}</h5>
                            <p>{e.client_role_in_company}</p>
                            <i className="fas fa-quote-right posiqut" /> 
                          </div>
                        </div>
                      </div>
                    </div>
            </div>
          </SwiperSlide>
  })
    return (

      

        <div>
            {/*Start Testinomial*/}
        <section className="testinomial-section-app bg-light-grey pad-tb">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 d-flex align-items-center">
                <div className="common-heading text-l edit stclients ">
                
                  <span>Our Clients Say It The Best</span>
                  <h2>100+ Satisfied Clients and Counting!</h2>
                 
                </div>
              </div>
              <div className="col-lg-8">
                <div className="pl50">
                   <Swiper
                    slidesPerView={1}
                    loop={true}
                    freeMode={true}
                    spaceBetween={10}

                    autoplay={{
                      delay: 3500,
                      disableOnInteraction: false
                    }}
                    className="myswiper"
style={{height:"530px",  }}
                    
                  >
                    {swiper_slide_elements}
                  </Swiper>
                </div>
              </div>
            
           
            </div>
           
          </div>
        </section>
        </div>
    )
}

export default Testimonials
