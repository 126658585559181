import React from 'react'
import { Link } from 'react-router-dom'

function Service() {
    return (
        <div>
                    {/*Start Service*/}
        <section className="service-section-app  pb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="common-heading ptag">
                  <span>Services</span>
                  <h2>Our Services</h2>
                  <p className="mb30">We provide solutions to our client’s challenges and make them enjoy and love what they do.</p>
                </div>
              </div>
            </div>
            <div className="row upset">
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.2s">
                <div className="service-card-app hoshd">
                  <h4>App Development</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">iPhone</a> </li>
                    <li> <a href="javascript:void(0);">Android</a> </li>
                    <li> <a href="javascript:void(0);">Cross Platform</a> </li>
                  </ul>
                  <div className="tec-icon mt30">
                    <ul className="servc-icon-sldr">
                      <li><a href="javascript:void(0);"><div className="img-iconbb"><img src="assets/images/icons/android.svg" alt="img" /></div> </a></li>
                      <li><a href="javascript:void(0);"><div className="img-iconbb"><img src="assets/images/icons/apple.svg" alt="img" /></div> </a></li>
                      <li><a href="javascript:void(0);"><div className="img-iconbb"><img src="assets/images/icons/tablet.svg" alt="img" /></div> </a></li>
                    </ul>
                  </div>
                  <p className="mt20" >Our meticulous team loves adventures, they develop solutions and simplify any business complexities</p>
                  <Link to="/app-development" className="mt20 link-prbs collapsed" role="button" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.5s">
                <div className="service-card-app hoshd">
                  <h4>Web Development</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">UI/UX</a> </li>
                    <li> <a href="javascript:void(0);">PHP</a> </li>
                    <li> <a href="javascript:void(0);">Java</a> </li>
                    <li> <a href="javascript:void(0);">WordPress</a></li>
                  </ul>
                  <div className="tec-icon mt30">
                    <ul className="servc-icon-sldr">
                      <li><a href="javascript:void(0);"><div className="img-iconbb"><img src="assets/images/icons/ux.svg" alt="img" /></div></a></li>
                      <li><a href="javascript:void(0);"><div className="img-iconbb"><img src="assets/images/icons/php.svg" alt="img" /></div></a></li>
                      <li><a href="javascript:void(0);"><div className="img-iconbb"><img src="assets/images/icons/java.svg" alt="img" /></div></a></li>
                      <li><a href="javascript:void(0);"><div className="img-iconbb"><img src="assets/images/icons/wordpress.svg" alt="img" /></div></a></li>
                    </ul>
                  </div>
                  <p className="mt20">Invincible leader in web development that provides solutions for a wide range of industries.</p>
                  <Link to="/static-website" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt30 wow fadeIn" data-wow-delay="0.8s">
                <div className="service-card-app hoshd">
                  <h4>eCommerce Development</h4>
                  <ul className="-service-list mt10">
                    <li> <a href="javascript:void(0);">Magento</a> </li>
                    <li> <a href="javascript:void(0);">Shopify</a> </li>
                    <li> <a href="javascript:void(0);">Woo-commerce</a></li>
                  </ul>
                  <div className="tec-icon mt30">
                    <ul className="servc-icon-sldr">
                      <li><a href="javascript:void(0);"><div className="img-iconbb"><img src="assets/images/icons/magento.svg" alt="img" /></div></a></li>
                      <li><a href="javascript:void(0);"><div className="img-iconbb"><img src="assets/images/icons/shopify.svg" alt="img" /></div></a></li>
                      <li><a href="javascript:void(0);"><div className="img-iconbb"><img src="assets/images/icons/woocommerce.svg" alt="img" /></div></a></li>
                    </ul>
                  </div>
                  <p className="mt20">Bespoke E-commerce solutions to support your business needs the way it demands.</p>
                  <Link to="/ecommerce" className="mt20 link-prbs">Read More <i className="fas fa fa-arrow-circle-right" /></Link>
                </div>
              </div>
              <Link to="our-services" className="btn-outline mt-5 mx-auto">More <i className="fas fa-chevron-right fa-icon" /></Link>
            </div>
          </div>
        </section>
        {/*End Service*/}
        </div>
    )
}

export default Service
