import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../About/Navbar'

function GoogleAds() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
        <div>
            <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/9.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">Google Ads Management Services from Mercy IT Corp</h1>
                  <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">We know your business deserves to reach a lot of people, and we are here to supply just that.</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
       
        {/*Start About*/}
        <section className="service  bg-gradient5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="image-block wow fadeIn">
                  <img src="assets/images/service/Google Ads.png" alt="image" className="img-fluid no-shadow" />
                </div>
              </div>
              <div className="col-lg-8 block-1">
                <div className="common-heading text-l pl25">
                  <span>Overview</span>
                  <h2>Google Ads Marketing To Reach Greater Audience</h2>
                  <p>If you are considering advertising your business on digital platforms then Google Ads would be the right choice. Ad marketing will make your website popular and drive traffic to your website. By paying for ads that are relevant to your business will generate more clients and the ranking of your website on the search engine results page. And the right keywords are necessary for your ad to reach the right audience, hence more strategized the keyword selection better the results will be. And we at Mercy IT Corp will help you to create the perfect ad campaign. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        {/*Start Key points*/}
        <section className="service pad-tb light-dark">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="text-l service-desc- pr25">
                  <h3 className="mb20">Google Ads Marketing Services Proven to Increase Leads, Sales, &amp; Revenue</h3>  
                  <p>If you are considering advertising your business on digital platforms then Google Ads would be the right choice. 
                    Ad marketing will make your website popular and drive traffic to your website. By paying for ads that are relevant 
                    to your business will generate more clients and the ranking of your website on the search engine results page. And 
                    the right keywords are necessary for your ad to reach the right audience, hence more strategized the keyword selection 
                    better the results will be. And we at Mercy IT Corp will help you to create the perfect ad campaign. </p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt30">Request A Quote  <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="servie-key-points">
                  <h4>Advantages of Google Ads Marketing</h4>
                  <ul className="key-points mt20">
                    <li>Pay Per Click Advertising</li>
                    <li>Campaign Management (Search, Display and re-marketing)</li>
                    <li>High Click-Through Rate (High CTR)</li>
                    <li>Relevant Traffic on Website</li>
                    <li>Better Google Ad Rankings</li>
                    <li>Impactful Ad Copies</li>
                    <li>Optimizing Quality Score</li>
                    <li>High Conversion Rate </li>
                    <li>Targeted Keywords</li>
                    <li>Free of Cost Ad Extensions</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Key points*/}
        {/*Start Service*/}
        <section className="service-block bg-gradient6 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading ptag">
                  <span>Service</span>
                  <h2>Our Services</h2>
                  <p className="mb30">We provide services that well suit your business needs.</p>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Adwords.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Adwords Campaign Management</h4>
                    <p>Advertising on Google forms the idea of each digital marketing activity. At this Agency, we attempt to find out your program marketing efforts, your audience.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Search Ads.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Search Ads</h4>
                    <p>By running search ads on Google, you'll make your website easily discoverable with the assistance of highly targeted keywords without any hassle.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Display Ads.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Display Ads</h4>
                    <p>Google knows the importance of visuals then , with its Display Network, you'll display your ads on many sites and target customers with impressive banner ads within the sort of images, audio and video, rather than plain text.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Shopping Ads.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Shopping Ads</h4>
                    <p>Every industry, especially the retail industry, has benefitted from Google Shopping Ads because it helped the industries drive quite 60% of all clicks. Shopping Ads are the simplest for e-commerce.</p>
                  </div></div>
              </div>
            </div>
            <div className="-cta-btn mt70">
              <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.1s">
                <p>Hire a <span>Marketing Specialist</span></p>
                <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <Link onClick={timer} to="/contact-us" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or call us now <a href="tel:+91 9176454455"><i className="fas fa-phone-alt" />+91 9176454455</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default GoogleAds
