import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, { Pagination, Autoplay } from "swiper/core";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

export default function SwiperCarousel() {

  const swiper_slide_elements = swiper_slides_data.map((e , index) => {
    return <SwiperSlide  >
              <a href="#" className={`hero_testimony_slide d-flex align-items-center p-2  `}>
                <img width="80" height="80" src={e.img_url} alt="Mercy mobile app development company" className="img100w mr-2" />
                <div>
                  <h5 className="mb-1">
                    {e.client_name} , {e.client_role_in_company}
                  </h5>
                  <div style={{lineHeight : "24px", fontSize:"13px"}}>
                    {e.client_testimony}
                  </div>
                </div>
              </a>
          </SwiperSlide>
  })

  return (
    <>
      <Swiper
        slidesPerView={1}
        loop={true}
        freeMode={true}
        spaceBetween={10}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false
        }}

        
        
      >
        {swiper_slide_elements}
      </Swiper>
    </>
  );
}


export const swiper_slides_data = [
  {
    client_name : "Mr. Aneez" ,
    client_role_in_company : "CEO",
    img_url : "assets/images/icons/doff.png",
    client_testimony : " The provided me with multiple options and a complete explanation on the various technology based on my requirements and I was able to select the best for my business. I'm very satisfied, they are technically strong and customer focused."
  },
  {
    client_name : "Mr. Thameez" ,
    client_role_in_company : "CEO",
    img_url : "assets/images/icons/danish.png",
    client_testimony : "The application was developed and delivered way ahead of the deadline, and they have a very good follow-up team to check for any flaws or bugs. I feel I'm with safe hands."
  },
  {
    client_name : "Mr. Khalid" ,
    client_role_in_company : "Managing Director",
    img_url : "assets/images/icons/Eterna.png",
    client_testimony : "I had my requirements listed and they strategically worked out on the flow and helped me on my business requirement. A real professional approach and dedicated service."
  },
  {
    client_name : "Iklas IAS Academy" ,
    client_role_in_company : "",
    img_url : "assets/images/icons/iklas.png",
    client_testimony : "The entire journey from initial discussion to annual renewal was very systematic, I'm their customer for many years."
  },
  {
    client_name : "Mr. Karimullah" ,
    client_role_in_company : "Managing Director",
    img_url : "assets/images/icons/33.png",
    client_testimony : "We were able to get more customers. Thanks to their efficient service."
  },
  
  {
    client_name : "Mr. Sarfraz" ,
    client_role_in_company : "Managing Director",
    img_url : "assets/images/icons/6allb.png",
    client_testimony : "Our customer service index has increased. Our stakeholders are now happy"
  },
  
]