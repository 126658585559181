import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

function WhyUs() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section className="breadcrumb-area">
        <div className="text-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 v-center">
                <div className="bread-inner">
                  <div className="bread-menu text-center">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <a href="#">Why Us</a>
                      </li>
                    </ul>
                  </div>
                  <div className="bread-title">
                    <h2 className="text-white text-center">Why Us</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Breadcrumb*/}
      {/*Start About*/}
      <section className="about-sec bg-gradient5 pad-tb">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-10">
              <div className="common-heading">
                <span>We Are Creative Agency</span>
                <h1 className="mb30">
                  Why Choose{" "}
                  <span className="text-radius text-light text-animation bg-b">
                    Mercy IT Corp
                  </span>
                </h1>
                <p>
                  The entity that is prized for its commitments, dedication and
                  service par excellence. A leading business model that empowers
                  other businesses through its services and solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End About*/}
      {/*why choose*/}
      <section className="why-choos-lg pad-tb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="common-heading text-l">
                <span>Why Choose Us</span>
                <h2 className="mb20">
                  Why Mercy IT Corp is preferred among the leading Web &amp; App
                  Development Companies?
                </h2>
                <p>
                  We believe that passion drives performance, and with our
                  experience in providing extensive services throughout the
                  years, has made us one of the best IT services company. We
                  offer wide range of services ranging from Website Development,
                  E-Commerce, Digital Marketing, UI/UX Design and Mobile App
                  Development. With more than 10 years experience, we have set
                  the standards high in serving our clients as per their
                  requirements.
                </p>
              </div>
            </div>
          </div>

          <div className="row  py-5 ">
            <h4 className="col-lg-12 paratext">
              <span>REASONS</span> That Make Us The Premier Choice
            </h4>
            <div className="col-lg-6">
              <div className="itm-media-object mt40 tilt-3d">
                <div className="media">
                  <div
                    className="img-ab- base"
                    data-tilt
                    data-tilt-max={20}
                    data-tilt-speed={1000}
                  >
                    <img
                      src="assets/images/icons/Skill-Experience.svg"
                      alt="icon"
                      className="layer"
                    />
                  </div>
                  <div className="media-body">
                    <h4>Skill & Experience</h4>
                    <p>
                      Team of skilled and experienced professionals understand
                      the nuances of work and their effort and dedication has
                      always took us to the forefront.
                    </p>
                  </div>
                </div>
                <div className="media mt40">
                  <div
                    className="img-ab- base"
                    data-tilt
                    data-tilt-max={20}
                    data-tilt-speed={1000}
                  >
                    <img
                      src="assets/images/icons/Committed-to-Quality.svg"
                      alt="icon"
                      className="layer"
                    />
                  </div>
                  <div className="media-body">
                    <h4>Committed to Quality</h4>
                    <p>
                      Quantity over quality has always worked for us. Every
                      project taken is delivered with utmost concern for quality
                      and no, we do not compromise on it, neither do our
                      clients.
                    </p>
                  </div>
                </div>
                <div className="media mt40">
                  <div
                    className="img-ab- base"
                    data-tilt
                    data-tilt-max={20}
                    data-tilt-speed={1000}
                  >
                    {" "}
                    <img
                      src="assets/images/icons/On-Time-Delivery.svg"
                      alt="icon"
                      className="layer"
                    />
                  </div>
                  <div className="media-body">
                    <h4>On Time Delivery</h4>
                    <p>
                      Timely delivery is something that we plan around before
                      the start of any project. It ensures that we meet the
                      client’s expected timeline because we value our client’s
                      time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="itm-media-object mt40 tilt-3d">
                <div className="media">
                  <div
                    className="img-ab- base"
                    data-tilt
                    data-tilt-max={20}
                    data-tilt-speed={1000}
                  >
                    <img
                      src="assets/images/icons/Client-First-Approach.svg"
                      alt="icon"
                      className="layer"
                    />
                  </div>
                  <div className="media-body">
                    <h4>Client First Approach</h4>
                    <p>
                      We are client oriented and always prioritize their needs.
                      Our goal is to strengthen and transform businesses through
                      providing simplified solutions to our clients.
                    </p>
                  </div>
                </div>
                <div className="media mt40">
                  <div
                    className="img-ab- base"
                    data-tilt
                    data-tilt-max={20}
                    data-tilt-speed={1000}
                  >
                    <img
                      src="assets/images/icons/Competitive-Pricing.svg"
                      alt="icon"
                      className="layer"
                    />
                  </div>
                  <div className="media-body">
                    <h4>Competitive Pricing</h4>
                    <p>
                      To suit our clients requirements we offer services at a
                      very competitive price that benefits both us and our
                      clients.
                    </p>
                  </div>
                </div>
                <div className="media mt40">
                  <div
                    className="img-ab- base"
                    data-tilt
                    data-tilt-max={20}
                    data-tilt-speed={1000}
                  >
                    {" "}
                    <img
                      src="assets/images/icons/deal.svg"
                      alt="icon"
                      className="layer"
                    />
                  </div>
                  <div className="media-body">
                    <h4>Track Record</h4>
                    <p>
                      Over 10 years experience in serving our clients, we
                      maintain a track record and take our services to the next
                      level. Every project completed is a new record for us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End why choose*/}
      {/*Start Badges*/}
      <section className="badges-section bg-gradient5  pad-tb">
        <div className="container">
          <div className="-cta-btn mt70">
            <div className="free-cta-title v-center">
              <p>
                We <span>Promise.</span> We <span>Deliver.</span>
              </p>
              <Link
                onClick={timer}
                to="/contact-us"
                className="btn-main bg-btn2 lnk"
              >
                Let's Work Together
                <i className="fas fa-chevron-right fa-icon" />
                <span className="circle" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/*End Badges*/}
    </div>
  );
}

export default WhyUs;
