import React from 'react';
import Navbar from '../About/Navbar';
import { Link } from 'react-router-dom'; 

function TechConsulting() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
        <div>
        <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/8.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">Tech Consulting</h1>
                  {/* <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p> */}
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Start About*/}
        <section className="service pad-tb bg-gradient5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="image-block wow fadeIn">
                  <img src="assets/images/service/app-develops2.png" alt="image" className="img-fluid no-shadow" />
                </div>
              </div>
              <div className="col-lg-8 block-1">
                <div className="common-heading text-l pl25">
                  <span>Overview</span>
                  <h2>Tech Consulting</h2>
                  <p>Technology has become essential to business and customer engagement since the Covid-19 epidemic. 
                    Business executives are looking for advice from specialists due to the impending rapid digital 
                    transformation and the vast array of available technology. As a result, many people have turned 
                    to consulting. But what is the main asset of tech consulting? In essence, it aids you in identifying 
                    the most appropriate technological solutions to advance your company.</p><br/>
                    <p>In actuality, there has never been a greater need for IT consultants.</p>
                    <div className="servie-key-points">
                  <h4>Let's talk about tech consulting and why it benefits companies so much. We'll consider:</h4>
                  <ul className="key-points mt20">   
                    <li>Reduce costs</li>
                    <li>Create new revenue streams</li>
                    <li>Streamline their operations</li>
                    <li>Improve customer service</li>
                     
                  </ul>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
       
        {/*Start Tech*/}
        {/* <div className="techonology-used-">
          <div className="container">
            <ul className="h-scroll tech-icons">
              <li><a href="#"><img src="assets/images/icons/swift.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/java.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/reactnative.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/flutter.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/ionic.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/kotlin.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/xamarin.png" alt="icon" /></a></li>
            </ul>
          </div>
        </div> */}
      
        <section id="process" className="service-block pad-tb light-dark">
          <div className="container">
          
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-5.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>1</span>
                  <h3>How we can assist</h3>
                  <p>Our multinational team of tech strategists, enterprise architects, and transformation specialists assists in reimagining every organization by leveraging technology and innovation.</p>
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>2</span>
                  <h3>CCloud strategy and consulting -  Let's talk CLOUD!</h3>
                  <p>When you learn to think and behave differently, you can get the most of your cloud investment and its advantages.</p>
                  <div className="servie-key-points">
                  <ul className="key-points mt20">   
                    <li>Security - Clouds come with advanced security measures & protocols ensuring your enterprise safety and privacy.</li>
                    <li>Cost Reduction - Switching made easy -Invest less, gain more.</li>
                    <li>Mobility - Access your data on multiple platforms from anywhere.</li>
                    <li>Flexibility - Adaptations made quick and easy allowing you to focus on your enterprise goal.</li>
                    <li>Collaboration - A fun way to work allowing multiple users to access data simultaneously.</li>
                    <li>Fail Safe - Server went out of your control? Cloud-based servers allow you to safekeep and recover data quickly without loss or damage.</li>
                     
                  </ul>
                </div>
                </div>
              </div>
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-6.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-7.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>3</span>
                  <h3>Innovation and an intelligent operating model -  Smart & Advanced ways to drive leads.</h3>
                  <p>Be nimbler to adapt to shifting market demands. Create and implement a business model that is flexible, resilient, and growth-oriented.</p>
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>4</span>
                  <h3>Resilient construction - - A way to sail your business through storms without any risks.</h3>
                  <p>Utilize current architecture technologies and methods to their full potential in order to enhance business continuity and lower operational risk.</p>
                </div>
              </div>
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-8.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-9.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>5</span>
                  <h3>Tech worth  - Evolve rapidly without any additional expenditure.</h3>
                  <p>Measure your technology spending and make the most of it to define value, reduce expenses, and allow business transformation.</p>
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>6</span>
                  <h3>Automation & analytics - Grow your business using data-driven insights with less manual intervention.</h3>
                  <p>Study shows that “By 2023, more than 40% of data will be automated”. Unlock the potential of automation, artificial intelligence, and data. Automation leads to higher productivity & expanded use of data and analytics while reducing manual intervention - Gain more by doing less!</p>
                </div>
              </div>
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-10.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
            </div>

          </div>
        </section>
        {/*End Process*/}
        
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <Link onClick={timer} to="/contact-us" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or call us now <a href="tel:+91 9176454455"><i className="fas fa-phone-alt" />+91 9176454455</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default TechConsulting;
