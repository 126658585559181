import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../About/Navbar'

function SocialMedia() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
        <div>
            <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/9.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">Social Media Marketing that reaches a lot of people</h1>
                  <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Social Media Marketing that takes your business to the right audience
Unlock the power of marketing with our incredible marketing strategies</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Start About*/}
        <section className="service  bg-gradient5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="image-block wow fadeIn">
                  <img src="assets/images/service/smm.png" alt="image" className="img-fluid no-shadow" />
                </div>
              </div>
              <div className="col-lg-8 block-1">
                <div className="common-heading text-l pl25">
                  <span>Overview</span>
                  <h2>Social Media Marketing To Reach Greater Audience</h2>
                  <p> Social Media Marketing is the strategy used to promote your brand, which strongly creates a presence 
                    amongst your competitors and attracts customers. Businesses use social media marketing to keep their 
                    customers engaged and understand their choices using content and get relevant feedback from their 
                    target audience. Marketing plays a key role in building a brand. With various sites like Facebook, 
                    Twitter, LinkedIn, Pinterest, Youtube, marketing will help you reach the target audience way faster 
                    than traditional marketing methods. It is tricky, but when done precisely will be an effective way to 
                    take businesses to a global level reaching a wider audience. </p>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        {/*Start Key points*/}
        <section className="service pad-tb light-dark">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="text-l service-desc- pr25">
                  <h3 className="mb20">With Social Media Marketing, Increase Leads, Sales,  & Revenue</h3>  
                  <p>Our experts at Mercy IT Corp will create social presence for your brand which will assist you reach your audience , showcase and push your products/services, build a robust relationship together with your audience and increase engagement levels. we offer an entire social media strategy for your brand, right from creating an enormous idea or concept to giving a rising presence and increasing brand influence.</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt30">Request A Quote  <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="servie-key-points">
                  <h4>Advantages of Social Media Marketing</h4>
                  <ul className="key-points mt20">
                    <li>Increased Brand Awareness</li>
                    <li>More Inbound Traffic</li>
                    <li>Higher Conversion Rates</li>
                    <li>Better Customer Satisfaction</li>
                    <li>Improved Brand Loyalty</li>
                    <li>A solid brand presence online</li>
                    <li>Create a singular brand tone</li>
                    <li>A steady stream of followers/ brand loyalists</li>
                    <li>One-to-one audience communication</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Key points*/}
        {/*Start Service*/}
        <section className="service-block bg-gradient6 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading ptag">
                  <span>Service</span>
                  <h2>Our Services</h2>
                  <p className="mb30">We provide services that well suit your business needs.</p>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Facebook Marketing.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Facebook Marketing</h4>
                    <p>We begin the campaign by analysing the industry, competition, and therefore the audience and make sure that we are pushing the proper content at the proper time.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Instagram Marketing.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Instagram Marketing</h4>
                    <p>We create and optimize content that's useful to your audience and build deeper engagements levels. Overall, we make sure that we are building the brand.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/WhatsApp Marketing.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>WhatsApp Marketing</h4>
                    <p>Social Media doesn't only offer you a worldwide reach but it also helps in growing visibility, broadening the brand coverage, generating traffic.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Twitter Marketing.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Twitter Marketing</h4>
                    <p>Social Media Management is all about managing online content through scheduled tweets and staying in-tuned with friends and followers.</p>
                  </div></div>
              </div>
            </div>
            <div className="-cta-btn mt70">
              <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.1s">
                <p>Hire a <span>Marketing Specialist</span></p>
                <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <Link onClick={timer} to="/contact-us" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or call us now <a href="tel:+91 9176454455"><i className="fas fa-phone-alt" />+91 9176454455</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default SocialMedia
