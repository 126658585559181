import React from 'react'
import Navbar from '../../About/Navbar'
import { Link } from 'react-router-dom'; 

function AppDevelopment() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
        <div>
        <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/8.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">Brilliant Mobile App Development Company</h1>
                  <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creating your own mobile app has now become easy with Mercy IT Corp. Create mobile apps, chatbots, web apps for any device. Apps that are built just to meet your customers’ needs with a logical user interface, and even add your own custom code.</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Start About*/}
        <section className="service pad-tb bg-gradient5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="image-block wow fadeIn">
                  <img src="assets/images/service/app-develops1.png" alt="image" className="img-fluid no-shadow" />
                </div>
              </div>
              <div className="col-lg-8 block-1">
                <div className="common-heading text-l pl25">
                  <span>Overview</span>
                  <h2>Custom Mobile App Solutions</h2>
                  <p>Mobile apps have taken the business revolution by storm and it is only growing. As apps have become prominent in our daily lives, developing a simple app will not make you stand out. We create apps that givethe user a seamless experience. They are an essential tool that helps promote your business and understand the needs of your customer better. With apps being accessible easily on various platforms it helps you to register your brand name and makes it easier to reach customers. Developing an app can be made easier with the right partner and we help you to create one.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        <section className="service pad-tb">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="text-l service-desc- pr25">
                  <h3>Get a complete strategy of mobile app development</h3>
                  <h5 className="mt10 mb20">Here Are Just a couple of Reasons Why We’re Rated the #1 Development Platform</h5>
                  <p>We always listen to your needs and customize apps that suit your requirements. Our talented team will keep you updated right from planning to deployment of the app. We are always on the lookout for the best result and achieve it with sheer talented work. </p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt30">Request A Quote  <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="servie-key-points">
                  <h4>Advantages of Mobile App Development</h4>
                  <ul className="key-points mt20">   
                    <li>Unlike Websites, They Minimise Wait Times</li>
                    <li>Reinforces Your Brand</li>
                    <li>Increase Customer Engagement</li>
                    <li>24/7 Customer Support</li>
                    <li>Feasible and Convenient</li>
                    <li>Accessible Offline</li>
                    <li>Cost Reduction</li>
                    <li>Creates Barrier to Entry for Competitors</li>   
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Start Tech*/}
        <div className="techonology-used-">
          <div className="container">
            <ul className="h-scroll tech-icons">
              <li><a href="#"><img src="assets/images/icons/swift.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/java.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/reactnative.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/flutter.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/ionic.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/kotlin.png" alt="icon" /></a></li>
              <li><a href="#"><img src="assets/images/icons/xamarin.png" alt="icon" /></a></li>
            </ul>
          </div>
        </div>
        {/*End Tech*/}
        {/*Start Service*/}
        <section className="service-block bg-gradient6 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading ptag">
                  <span>Service</span>
                  <h2>Our Services</h2>
                  <p className="mb30">We provide services that well suit your business needs.</p>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-6 col-sm-6 mt30  wow fadeInUp" data-wow-delay=".2s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/mob-service-item2.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>iOS App Development</h4>
                    <p>We specifically design iOS apps using the right programming language.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".4s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/mob-service-item1.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Android App Development</h4>
                    <p>We develop eminent android apps with evolutionary architecture using the latest technologies.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/mob-service-item3.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Cross-Platform</h4>
                    <p>Versatile app development using a single code that works across different platforms.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".6s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Hybrid App Development.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Hybrid App Development</h4>
                    <p>High functional integrated apps that work across different platforms and are cost-effective with faster build times.</p>
                  </div></div>
              </div>
              
            </div>
            <div className="-cta-btn mt70">
              <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.1s">
                <p>Hire a <span>Dedicated Developer</span></p>
                <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        {/*Start Process*/}
        <section id="process" className="service-block pad-tb light-dark">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading ptag">
                  <span>Process</span>
                  <h2>Our App Development Process</h2>
                  <p>We follow the start with the end in mind habit and hence, our process involves strategic planning and a well defined process roadmap to understand what we are working on and how we want the final outcome to be.</p>
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-1.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>1</span>
                  <h3>Strategy</h3>
                  <p>The first phase of the mobile app development process is defining the strategy for evolving your idea into a successful app. you'll include a more significant a part of this in your overall enterprise mobility strategy.</p>
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>2</span>
                  <h3>Analysis and Planning</h3>
                  <p>At this stage, your app idea starts taking shape and turns into an actual project. Analysis and planning begin with defining use cases and capturing detailed functional requirements. After you've got identified the wants for your app, prepare a product roadmap. This includes prioritizing the mobile app requirements and grouping them into delivery milestones</p>
                </div>
              </div>
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-2.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-3.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>3</span>
                  <h3>UI / UX Design</h3>
                  <p>The success of a mobile app is decided supported how well users are adopting and taking advantage of all its features. The goal for mobile app UI / UX design is creating excellent user experiences making your app interactive, intuitive, and user-friendly.</p>
                </div>
              </div>
            </div>
            <div className="row upset justify-content-center mt60">
              <div className="col-lg-7 v-center order2">
                <div className="ps-block">
                  <span>4</span>
                  <h3>App Development</h3>
                  <p>Planning remains an integral a part of this introduce the mobile app development process. Before actual development/programming efforts start, you'll have to:define the technical architecture, pick a technology stack, and define the event milestones.</p>
                </div>
              </div>
              <div className="col-lg-4 v-center order1">
                <div className="image-block1">
                  <img src="assets/images/process/process-4.jpg" alt="Process" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Process*/}
        
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <Link onClick={timer} to="/contact-us" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or call us now <a href="tel:+91 9176454455"><i className="fas fa-phone-alt" />+91 9176454455</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default AppDevelopment
