import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../About/Navbar'

function GraphicDesign() {

  const timer = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);
  }

    return (
        <div>
            <Navbar />
            {/*Breadcrumb Area*/}
        <section className="breadcrumb-areav2" data-background="assets/images/banner/4.jpg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="bread-titlev2">
                  <h1 className="wow fadeInUp text-white" data-wow-delay=".2s">We love the Graphic Design Business!</h1>
                  <p className="mt20 wow fadeInUp text-white" data-wow-delay=".4s">Creativity, customer focus, technological innovation at its finest with well-defined design processes at Mercy IT Corp</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt20 wow zoomInDown" data-wow-delay=".6s">Get Quote <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/*End Hero*/}
        {/*Start About*/}
        <section className="service  bg-gradient5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="single-image  mt0 wow fadeIn">
                  <img src="assets/images/service/Graphic Design.png" alt="image" className="img-fluid no-shadow" />
                </div>
              </div>
              <div className="col-lg-8 block-1 m-mt50">
                <div className="common-heading text-l pl25">
                  <span>Overview</span>
                  <h2>Professional Graphic Design Services</h2>
                  <p>Exceptional graphic design that communicates ideas visually to your audience is of vital importance to build your brand identity and let them connect with you. We create impressive designs for your various needs from logos, branding, digital marketing, and advertising. Graphic designs are more likely to entice customers to choose your brand. So get started with us!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        {/*Start Key points*/}
        <section className="service pad-tb light-dark">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="text-l service-desc- pr25">
                  <h3 className="mb20">Our Designs are made to Impress</h3>
                  <p>We understand what it takes to create a brand that communicates your purpose clearly to your customers. And with design, you can convey it efficiently. We use advanced graphic designing tools to make it stand out. Our graphic designers are passionate, creative, and efficient, which makes us the best design makers.</p>
                  <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk mt30">Request A Quote  <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="single-image wow fadeIn">
                  <img src="assets/images/service/Our Designs.png" alt="image" className="img-fluid no-shadow" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Key points*/}
        {/*Start Service*/}
        <section className="service-block bg-gradient6 pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading ptag">
                  <span>Service</span>
                  <h2>Graphic Design services we offer</h2>
                  <p className="mb30">We plan big and have our hands on all the top technology platforms to offer you a wide range of services</p>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay=".2s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/logo-design.jpg" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Logos and Branding</h4>
                    <p>Your logo is the symbolic representation of your brand and that is why you need the best logo that connects you with your prospects. We are here to do just that.</p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay=".4s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/Infographics Design.png" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Infographics Design</h4>
                    <p>We simplify complex data and use color, space, and creativity in designing the best infographics that convey messages in an easy-to-digest format. </p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay=".6s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/brochure-.jpg" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Print Design</h4>
                    <p>Our team ensures to convey your message to your customers using brilliant print designs that are just a treat to the eyes.  </p>
                  </div></div>
              </div>
              <div className="col-lg-6 col-md-12 mt30 wow fadeInUp" data-wow-delay=".8s">
                <div className="s-block wide-sblock">
                  <div className="s-card-icon-large"><img src="assets/images/service/flyer-.jpg" alt="service" className="img-fluid" /></div>
                  <div className="s-block-content-large">
                    <h4>Advertising</h4>
                    <p>The right advertising takes you in the right direction. We use advertising strategies that are eclectic and one that is tailored for your business. </p>
                  </div></div>
              </div>
            </div>
            <div className="-cta-btn mt70">
              <div className="free-cta-title v-center wow zoomInDown" data-wow-delay="1.4s">
                <p>Hire a <span>Graphic Designer</span></p>
                <Link onClick={timer} to="/contact-us" className="btn-main bg-btn2 lnk">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <Link onClick={timer} to="/contact-us" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or call us now <a href="tel:+919176454455"><i className="fas fa-phone-alt" />+919176454455</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="assets/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="assets/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="assets/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="assets/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        </div>
    )
}

export default GraphicDesign
