import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

function MissionVision() {
  const timer = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  return (
    <div>
      <Navbar />
      {/*Breadcrumb Area*/}
      <section className="breadcrumb-area banner-2">
        <div className="text-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 v-center">
                <div className="bread-inner">
                  <div className="bread-menu text-center">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <a href="#">Mission and Vision</a>
                      </li>
                    </ul>
                  </div>
                  <div className="bread-title">
                    <h2 className="text-white text-center">
                      Mission and Vision
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Breadcrumb*/}
      <section className="service py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block upset bg-shape">
                <img
                  src="assets/images/about/mission2.jpg"
                  alt="image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                {/* <span>Mission</span> */}
                <h2>Our Mission</h2>
                <p>
                  To partner with businesses that are change-makers and make
                  technological advances that are benefitting. To repeat
                  business with our clients and build strong client relations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service pad-tb">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pr25">
                {/* <span>Vision</span> */}
                <h2>Our Vision</h2>
                <p>
                  To become a global business leader that is known for
                  excellence, innovation and progress and be a trustable brand
                  that is totally customer centric, socially responsible, and an
                  equal opportunity employer known for its values, culture and
                  work ethics.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="image-block upset bg-shape m-mt30 mb0">
                <img
                  src="assets/images/about/vision2.jpg"
                  alt="image"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Start core value*/}
      <section className="service-block bg-gradient6 pad-tb light-dark">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="common-heading ptag">
                <span>Values</span>
                <h2>Mercy IT Corp's Core Values</h2>
                <p className="mb30">
                  We think big and have hands in all leading technology
                  platforms to provide you wide array of services.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset link-hover">
          
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".2s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/logo-and-branding.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Innovation</h4>
              </div>
              <div className="colr2">

              <p  >Clients’ needs will change over time and innovative thinking is what that’s needed to meet their expectations.</p>

              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service2.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Professionalism</h4>
              </div>
              <div className="colr2">

              <p  >Clients’ needs will change over time and innovative thinking is what that’s needed to meet their expectations.</p>

              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".6s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service3.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Teamwork</h4>
              </div>
              <div className="colr2">

              <p  >Clients’ needs will change over time and innovative thinking is what that’s needed to meet their expectations.</p>

              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".8s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service4.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Customer Satisfaction</h4>
              </div>
              <div className="colr2">

              <p  >Clients’ needs will change over time and innovative thinking is what that’s needed to meet their expectations.</p>

              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay="1s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service5.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Creativity</h4>
              </div>
              <div className="colr2">

              <p  >Clients’ needs will change over time and innovative thinking is what that’s needed to meet their expectations.</p>

              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay="1.2s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service6.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Quality Excellence</h4>
              </div>
              <div className="colr2">

              <p  >Clients’ needs will change over time and innovative thinking is what that’s needed to meet their expectations.</p>

              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay="1.2s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service6.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Accountability</h4>
              </div>
              <div className="colr2">

              <p  >Clients’ needs will change over time and innovative thinking is what that’s needed to meet their expectations.</p>

              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay="1.2s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service6.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Integrity</h4>
              </div>
              <div className="colr2">

              <p  >Clients’ needs will change over time and innovative thinking is what that’s needed to meet their expectations.</p>

              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay="1.2s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="assets/images/icons/service6.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Ethical Practices</h4>
              </div>
              <div className="colr2">

              <p  >Clients’ needs will change over time and innovative thinking is what that’s needed to meet their expectations.</p>

              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Service*/}
      {/*Start CTA*/}
      <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link onClick={timer} to="/contact-us" className="btn-outline">
                  Estimate Project{" "}
                  <i className="fas fa-chevron-right fa-icon" />
                </Link>
                <p className="cta-call">
                  Or call us now{" "}
                  <a href="tel:04445550245">
                    <i className="fas fa-phone-alt" /> 044 4555 0245
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1">
          <img src="assets/images/shape/shape-3.svg" alt="shape" />
        </div>
        <div className="shape shape-a2">
          <img src="assets/images/shape/shape-4.svg" alt="shape" />
        </div>
        <div className="shape shape-a3">
          <img src="assets/images/shape/shape-13.svg" alt="shape" />
        </div>
        <div className="shape shape-a4">
          <img src="assets/images/shape/shape-11.svg" alt="shape" />
        </div>
      </section>
      {/*End CTA*/}
    </div>
  );
}

export default MissionVision;
